<template>
  <div class="gift-card" @click="navigateToGiftCardPage">
    <div class="gift-card-image">
      <img :src="cardImageSrc" alt="Gift Card Image" />
    </div>
    <div class="gift-card-content">
      <div class="gift-card-header">
        <h3 class="deal-name">{{ dealTitle }}</h3>
        <div class="star-rating">
          <span v-for="index in 5" :key="index" class="star-wrapper">
            <i class="star" :class="getStarClass(index)">★</i>
          </span>
        </div>
      </div>
      <div class="deal-details">
        <div class="price-section">
          <span class="original-price">${{ originalPrice }}</span>
          <span class="current-price">${{ calculateDiscountedPrice(originalPrice, discountPercentage) }}</span>
          <span class="discount">{{ discountPercentage }}% OFF</span>
        </div>
      </div>
      <div class="gift-card-actions" @click.stop>
        <button class="action-button" @click="saveGiftCard" title="Add to Cart">
          <img src="https://img.icons8.com/ios-glyphs/30/1A3819/shopping-bag.png" alt="Save Gift Card" class="icon" />
        </button>
      </div>
      <div v-if="message" class="message" :class="{ fade: fading }">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import defaultImage from '@/assets/default-image.png';

export default {
  name: 'GiftCard',
  props: {
    giftCardId: {
      type: String,
      required: true
    },
    dealTitle: {
      type: String,
      required: true
    },
    rating: {
      type: Number,
      required: true
    },
    cardImage: {
      type: String,
      default: ''
    },
    originalPrice: {
      type: Number,
      required: true
    },
    discountPercentage: {
      type: Number,
      required: true
    },
    businessId: { // Add this prop
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: '',
      fading: false,
      isLoggedIn: false,
      token: localStorage.getItem('token'),
    };
  },
  computed: {
    cardImageSrc() {
      return this.cardImage || defaultImage;
    }
  },
  mounted() {
    this.isLoggedIn = !!localStorage.getItem('user');
  },
  methods: {
    navigateToGiftCardPage() {
      this.$router.push(`/gift-card/${this.giftCardId}`).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    },
    calculateDiscountedPrice(originalPrice, discountPercentage) {
      return (originalPrice * (1 - discountPercentage / 100)).toFixed(2);
    },
    saveGiftCard(event) {
      event.stopPropagation();
      let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      const exists = checkoutCartItems.some(item => item.giftCardId === this.giftCardId);
      if (exists) {
        this.showMessage('Gift Card already in cart');
        return;
      }
      
      const newItem = {
        giftCardId: this.giftCardId,
        dealTitle: this.dealTitle,
        originalPrice: this.originalPrice,
        discount: this.discountPercentage,
        quantity: 1,
        businessId: this.businessId, // Add the business ID to the item
        image_link: this.cardImageSrc
      };

      if (this.isLoggedIn) {
        const userId = JSON.parse(localStorage.getItem('user')).id;
        axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/saveCart/${userId}`, newItem, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
          .then(() => {
            checkoutCartItems.push(newItem);
            localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
            window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
            this.showMessage('Gift Card Added to Cart');
          })
          .catch((error) => {
            console.error('Error saving gift card:', error);
          });
      } else {
        checkoutCartItems.push(newItem);
        localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
        window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
        this.showMessage('Gift Card Added to Cart');
      }
    },
    showMessage(text) {
      this.message = text;
      this.fading = false;
      setTimeout(() => {
        this.fading = true;
        setTimeout(() => {
          this.message = '';
          this.fading = false;
        }, 1000); // Duration of the fade-out effect
      }, 2000); // Duration the message stays visible before fading out
    },
    getStarClass(index) {
      if (this.rating === 0) {
        return 'blank';
      } else if (index <= Math.floor(this.rating)) {
        return 'filled';
      } else if (index === Math.ceil(this.rating) && this.rating % 1 !== 0) {
        return 'half-filled';
      } else {
        return 'blank';
      }
    }
  }
}
</script>

<style scoped>
.gift-card {
  border-radius: 8px;
  max-width: 300px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 0 calc(25% - 10px);
  margin: 5px;
  font-family: 'Arial', sans-serif;
  margin-bottom: 50px;
  position: relative; /* Added for absolute positioning within the card */
  cursor: pointer; /* Added to show that the card is clickable */
}

.gift-card-image img {
  width: 100%;
  display: block;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.gift-card-content {
  background-color: #fff;
  padding: 1rem;
  text-align: left;
}

.gift-card-header {
  display: flex;
  flex-direction: column;
}

.deal-name {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.star-rating {
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem 0;
}

.star-wrapper {
  position: relative;
}

.star {
  color: #ddd;
  font-style: normal;
  font-size: 1.5rem;
}

.star.filled {
  color: #ffc107;
}

.star.half-filled {
  position: relative;
  display: inline-block;
}

.star.half-filled::before {
  content: '★';
  position: absolute;
  left: 0;
  width:60%;
  overflow: hidden;
  color: #ffc107;
}

.deal-details {
  margin-top: 10px;
}

.price-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.current-price {
  color: #4caf50;
  font-weight: bold;
}

.discount {
  background-color: #d4edda;
  color: #155724;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.gift-card-actions {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.action-button {
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #eff1f3;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon {
  width: 25px;
  height: 25px;
}

/* Tooltip Styles */
.action-button:hover::after {
  content: attr(title);
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-40%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.8rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.action-button:hover::after {
  opacity: 1;
}

.message {
  position: absolute;
  top: 10px; /* Adjust to position at the top of the card */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.8rem;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.message.fade {
  opacity: 0;
}

@media (max-width: 768px) {
  .gift-card {
    max-width: none;
    width: 90%;
    min-height: 400px;
    max-height: 600px;
  }

  .gift-card-image img {
    height: auto;
  }

  .gift-card-content {
    padding: 1rem;
  }

}
</style>
