<template>
    <div class="personal-data-section">
      <h2>Personal Data</h2>
      <form @submit.prevent="handleUpdatePersonalData">
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text" id="firstName" v-model="billingDetails.firstName" required />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" id="lastName" v-model="billingDetails.lastName" required />
        </div>
        <div class="form-group">
          <label for="phone">Phone Number</label>
          <input type="tel" id="phone" v-model="billingDetails.phone" />
        </div>
        <div class="form-group">
          <label for="address">Address</label>
          <input type="text" id="address" v-model="billingDetails.address" />
        </div>
        <div class="form-group">
          <label for="city">City</label>
          <input type="text" id="city" v-model="billingDetails.city" />
        </div>
        <div class="form-group">
          <label for="zipCode">Zip Code</label>
          <input type="text" id="zipCode" v-model="billingDetails.zipCode" />
        </div>
        <div class="form-group">
          <label for="country">Country</label>
          <input type="text" id="country" v-model="billingDetails.country" />
        </div>
        <button type="submit" class="update-button">Update Personal Data</button>
      </form>
      <div v-if="message" class="message">{{ message }}</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'PersonalDataPage',
    data() {
      return {
        user: JSON.parse(localStorage.getItem('user')) || {
          id: '',
        },
        billingDetails: JSON.parse(localStorage.getItem('billingDetails')) || {
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          zipCode: '',
          country: '',
          phone: ''
        },
        message: '',
        token: localStorage.getItem('token'),
      };
    },
    methods: {
      async handleUpdatePersonalData() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/update-personal-data`, {
            userId: this.user.id,
            billingDetails: this.billingDetails
          }, {
            headers: {
              'Authorization': `Bearer ${this.token}`,
            }
          });
          localStorage.setItem('billingDetails', JSON.stringify(this.billingDetails));
          if (response.data.success) {
            this.message = 'Personal data updated successfully.';
          }
        } catch (error) {
          console.error('Error updating personal data:', error);
          this.message = 'Failed to update personal data.';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .personal-data-section {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    max-width: 500px;
  }
  
  .personal-data-section h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .form-group input:focus {
    outline: none;
    border-color: #6E9F49;
  }
  
  .update-button {
    padding: 10px 20px;
    background-color: #4C6B30;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
  }
  
  .update-button:hover {
    background-color: #6E9F49;
  }
  
  .message {
    margin-top: 20px;
    color: #6E9F49;
  }
  </style>
  