<template>
  <div class="dashboard-container">
    <h1>Payout Dashboard</h1>
    <div class="date-range">
      Payouts for: {{ dateRange }}
    </div>
    <table>
      <thead>
        <tr>
          <th>Business Name</th>
          <th>Payout Amount (Current Month)</th>
          <th>Payout Requested</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="business in displayedBusinesses" :key="business.business_name">
          <td>{{ business.business_name }}</td>
          <td>{{ business.current_month_payout.toFixed(2) }}</td>
          <td>{{ business.requested_payout ? 'Yes' : 'No' }}</td>
        </tr>
      </tbody>
    </table>
    <div class="load-more-container" v-if="hasMoreBusinesses">
      <button @click="loadMore" class="load-more-button">Load More</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DashboardAdminPayout',
  data() {
    return {
      businesses: [],
      dateRange: '',
      currentPage: 1,
      businessesPerPage: 10
    };
  },
  computed: {
    displayedBusinesses() {
      return this.businesses.slice(0, this.currentPage * this.businessesPerPage);
    },
    hasMoreBusinesses() {
      return this.displayedBusinesses.length < this.businesses.length;
    }
  },
  mounted() {
    this.fetchPayoutData();
    this.calculateDateRange();
  },
  methods: {
    async fetchPayoutData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/business-sales/payouts`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.businesses = response.data;
      } catch (error) {
        console.error('Error fetching payout data:', error);
      }
    },
    calculateDateRange() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
      this.dateRange = `${firstDay} - ${lastDay}`;
    },
    loadMore() {
      this.currentPage++;
    }
  },
};
</script>

<style scoped>
.dashboard-container {
  margin-left: 300px; /* Adjust for the sidebar */
  padding: 20px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dashboard-container {
    margin-left: 0; /* Remove margin for sidebar */
    padding: 15px; /* Adjust padding for medium screens */
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 10px; /* Adjust padding for small screens */
  }
}

@media (max-width: 480px) {
  .dashboard-container {
    padding: 5px; /* Adjust padding for extra small screens */
  }
}

.date-range {
  margin-bottom: 20px;
  font-size: 1.2em;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.load-more-container {
  text-align: center;
  margin-top: 20px;
}

.load-more-button {
  padding: 10px 20px;
  background-color: #4C6B30; /* Dark Green */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #6E9F49; /* Accent Green */
}
</style>