<template>
    <div class="order-container">
      <div class="order-header">
        Total orders this month
      </div>
      <div class="order-body">
        <div class="order-amount">{{ totalOrders }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrdersCard',
    props: {
     totalOrders: String,
    }
  };
  </script>
  
  <style scoped>
  .order-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem; /* Use rem for padding */
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%; /* Ensure it doesn't overflow */
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    text-align: center; /* Center text */
  }
  
  .order-header {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;
    font-size: 1.1rem; /* Use rem for font size */
    margin-bottom: 0.5rem; /* Use rem for margin */
    width: 100%; /* Ensure it takes full width */
    text-align: center; /* Center text */
  }
  
  .icon-wrapper {
    background-color: #9C27B0; /* Purple background */
    color: #ffffff; /* White color for the icon */
    border-radius: 50%;
    padding: 0.5rem; /* Use rem for padding */
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .order-body {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center content horizontally */
    width: 100%; /* Ensure it takes full width */
    text-align: center; /* Center text */
  }
  
  .order-amount {
    font-size: 2rem; /* Use rem for font size */
    font-weight: bold;
  }
  
  .order-change {
    font-size: 1rem; /* Use rem for font size */
    color: #4CAF50; /* Green color for positive change */
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .order-header {
      font-size: 1rem; /* Adjust font size for smaller screens */
    }
  
    .order-amount {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
  
    .order-change {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .order-container {
      padding: 0.5rem; /* Adjust padding for very small screens */
      padding: 50px 0;
    }
  
    .order-header {
      font-size: 0.9rem; /* Adjust font size for very small screens */
    }
  
    .order-amount {
      font-size: 1.2rem; /* Adjust font size for very small screens */
    }
  
    .order-change {
      font-size: 0.8rem; /* Adjust font size for very small screens */
    }
  }
  </style>
  