<template>
  <div class="food-menu">
    <div class="published-menu">
      <div v-for="(category, categoryIndex) in menu" :key="'pub-' + categoryIndex" class="category">
        <h5>{{ category.name }}</h5>
        <div class="published-items">
          <div v-for="(item, itemIndex) in category.items" :key="'pub-item-' + itemIndex" class="published-item-card">
            <div class="item-image">
              <img :src="getItemImage(item.image)" alt="Item Image" />
            </div>
            <div class="item-details">
              <p class="item-name">{{ item.name }}</p>
              <p class="item-description">{{ item.description }}</p>
              <p class="item-price">${{ item.price }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FoodMenu',
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  methods: {
    getItemImage(image) {
      console.log('Image URL:', image);
      return image;
    },
  },
};
</script>

<style scoped>
.food-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Arial, sans-serif;
}

.category {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  background-color: #f9f9f9;
}

.published-menu h5 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.published-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.published-item-card {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  background-color: white;
  width: calc(50% - 20px); /* Ensure two items per row with a gap */
  box-sizing: border-box;
  overflow: hidden; /* Prevent overflow */
  word-wrap: break-word; /* Ensure text wraps */
}

.published-item-card .item-image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
}

.published-item-card .item-details {
  flex-grow: 1;
}

.published-item-card .item-details p {
  overflow: hidden; /* Prevent overflow */
  word-wrap: break-word; /* Ensure text wraps */
  text-overflow: ellipsis; /* Optional: Add ellipsis for overflow text */
  margin: 0; /* Remove default margins */
}

.published-item-card .item-details .item-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.published-item-card .item-details .item-description,
.published-item-card .item-details .item-price {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .category {
    padding: 10px; /* Reduce padding for smaller screens */
    margin-top: 15px; /* Reduce margin top for smaller screens */
  }

  .published-menu h5 {
    font-size: 20px; /* Reduce font size for smaller screens */
    margin-bottom: 15px; /* Adjust margin bottom for smaller screens */
  }

  .published-items {
    justify-content: center; /* Center items on smaller screens */
  }
}

@media (max-width: 480px) {
  .published-items {
    flex-direction: column; /* Stack items vertically on very small screens */
  }

  .published-item-card{
    width: 100%; /* Full width for very small screens */
  }
}
</style>
