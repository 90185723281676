<template>
  <div class="sales-post">
    <div class="posts">
      <div v-for="(post, index) in salesPosts" :key="post.timestamp" class="post">
        <div v-if="post.image">
          <img :src="post.image" alt="Post Image" class="post-image" />
        </div>
        <div v-html="post.content" class="post-content"></div>
        <hr v-if="index < salesPosts.length - 1" class="post-separator" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesPost',
  props: {
    salesPosts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.posts {
  margin-top: 20px;
}

.post {
  margin-bottom: 20px;
  position: relative;
}

.post-image {
  width: auto;
  height: auto;
  max-height: 600px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.post-content {
  font-size: 16px;
  word-wrap: break-word;
}

.post-separator {
  margin-top: 20px;
  border: none;
  border-top: 1px solid #ddd;
}

@media (max-width: 768px) {
  .post-image {
    max-height: 400px; /* Adjust max-height for smaller devices */
  }

  .post-content {
    font-size: 14px; /* Smaller font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .post {
    margin-bottom: 15px; /* Reduce margin for very small screens */
  }

  .post-image {
    max-height: 300px; /* Further reduce max-height for very small screens */
  }

  .post-content {
    font-size: 12px; /* Even smaller font size for very small screens */
  }
}
</style>
