<template>
    <div>
      <MetaManager
        title="View All Gift Cards "
        description="Discover a wide selection of gift cards on Stark Shopper. Browse by category or explore all options to find the perfect gift for any occasion."
      />
      <div class="browse-all-deals">
        <CategoryFilter :categories="categories" @category-selected="filterGiftCards" :default-category="'all'" />
      </div>
      <div class="gift-card-list">
        <GiftCard
          v-for="giftCard in displayedGiftCards"
          :key="giftCard._id"
          :giftCardId="giftCard._id"
          :dealTitle="giftCard.dealtitle"
          :rating="giftCard.rating"
          :cardImage="giftCard.image_links[0]"
          :originalPrice="giftCard.currentprice"
          :discountPercentage="giftCard.discount"
        />
      </div>
      <div class="load-more-container" v-if="!allGiftCardsLoaded">
        <button @click="loadMoreGiftCards">Load More Gift Cards</button>
      </div>
    </div>
  </template>
  
  <script>
  import GiftCard from '@/components/GiftCard Components/GiftCard.vue';
  import CategoryFilter from '@/components/Frontend Nav Components/CategoryFilterBar.vue';
  import axios from 'axios';
  import MetaManager from '../../components/MetaManager.vue';
  
  export default {
    components: {
      CategoryFilter,
      GiftCard,
      MetaManager,
    },
    data() {
      return {
        categories: [
          { id: 'all', name: 'All' },
          { id: 'Restaurants', name: 'Restaurants' },
          { id: 'Beauty-spa', name: 'Beauty & Spas' },
          { id: 'Things-to-do', name: 'Things to do' },
          { id: 'Health-fitness', name: 'Health & Fitness' },
          { id: 'Automotive', name: 'Automotive' },
          { id: 'Home-services', name: 'Home Services' },
          { id: 'Retail', name: 'Retail' },
          { id: 'Technology', name: 'Technology' },
          { id: 'Other', name: 'Other' }
        ],
        giftCards: [],
        displayedGiftCards: [],
        selectedCategory: 'all',
        offset: 0,
        limit: 10,
        allGiftCardsLoaded: false,
        selectedLocation: localStorage.getItem('selectedLocation') || 'Stark County, Ohio',
      };
    },
    methods: {
      async loadGiftCards(reset = false) {
        try {
          if (reset) {
            this.giftCards = [];
            this.offset = 0;
            this.allGiftCardsLoaded = false;
          }
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/get-gift-cards`, {
            params: {
              limit: this.limit,
              offset: this.offset,
              category: this.selectedCategory === 'all' ? '' : this.selectedCategory,
              location: this.selectedLocation,
            }
          });
          const newGiftCards = response.data;
          if (reset) {
            this.giftCards = newGiftCards;
          } else {
            this.giftCards = [...this.giftCards, ...newGiftCards];
          }
          this.allGiftCardsLoaded = newGiftCards.length < this.limit;
          if (newGiftCards.length > 0) {
            this.offset += this.limit; // Move the offset forward only if there are new gift cards
          }
          this.updateDisplayedGiftCards();
        } catch (error) {
          console.error('Error fetching gift cards:', error);
        }
      },
      loadMoreGiftCards() {
        this.loadGiftCards();
      },
      filterGiftCards(category) {
        this.selectedCategory = category.id;
        this.loadGiftCards(true);
      },
      updateDisplayedGiftCards() {
        this.displayedGiftCards = this.giftCards;
      },
      processDiscount(giftCard) {
        const { discount, currentprice, regularprice, discounttype } = giftCard;
        const isRegularPriceValid = regularprice && regularprice[0] !== 0;
        const isCurrentPriceValid = currentprice && currentprice[0] !== 0;
  
        return {
          discount: discount[0],
          regularprice: isRegularPriceValid ? regularprice : null,
          currentprice: isCurrentPriceValid ? currentprice : null,
          discounttype: discounttype[0]
        };
      },
      async addToShoppingCart(giftCardId) {
        const userId = this.getUserId();
        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/${userId}`, { giftCardId });
          this.$refs.giftCardCards.forEach(card => {
            if (card.giftCardId === giftCardId) {
              card.showMessage('Gift Card added to saved items');
            }
          });
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.$refs.giftCardCards.forEach(card => {
              if (card.giftCardId === giftCardId) {
                card.showMessage('Gift Card already saved');
              }
            });
          } else {
            this.$refs.giftCardCards.forEach(card => {
              if (card.giftCardId === giftCardId) {
                card.showMessage('Error adding gift card to shopping cart');
              }
            });
          }
        }
      },
      getUserId() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.id : null;
      }
    },
  };
  </script>
  
  <style scoped>
.gift-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.browse-all-deals {
  text-align: left;
  margin-left: 7%;
  margin-top: 50px;
  padding-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

button {
  padding: 15px 25px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  margin-bottom: 50px;
}

button:hover {
  background-color: #6E9F49;
}

.gift-card-list :deep(.gift-card) {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gift-card-list :deep(.gift-card:hover) {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .browse-all-deals {
    margin-left: 5%;
    margin-top: 10px;
    padding-bottom: 10px;  }

  button {
    padding: 8px 16px; /* Slightly reduce button size */
    font-size: 14px; /* Adjust font size for buttons */
  }

  .gift-card-list .gift-card-item {
    flex-direction: column;
    align-items: center;
  }

  .gift-card-list :deep(.gift-card:hover) {
    transform: translateY(-3px);
  }
}

@media (max-width: 480px) {
  .gift-card-list {
    flex-direction: column;
    align-items: center;
  }

  .browse-all-deals {
    margin-left: 3%;
    margin-top: 5px;
    padding-bottom: 5px;
  }

  .load-more-container {
    margin-bottom: 10px; /* Reduce bottom margin */
  }

  button {
    padding: 6px 12px; /* Further reduce button size */
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .gift-card-list .gift-card-item {
    flex-basis: calc(50% - 20px); /* Adjust for larger screens, two items per row */
  }

  .gift-card-list :deep(.gift-card:hover) {
    transform: translateY(-2px);
  }
}
</style>
  