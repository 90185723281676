<template>
    <div>
<!-- This component doesn't render anything -->
    </div>
</template>

<script>
export default {
  name: 'MetaManager',
  props: {
    title: String,
    description: String
  },
  watch: {
    title: {
      immediate: true,
      handler(newTitle) {
        document.title = newTitle || 'Default Title';
      }
    },
    description: {
      immediate: true,
      handler(newDescription) {
        let meta = document.querySelector('meta[name="description"]');
        if (!meta) {
          meta = document.createElement('meta');
          meta.name = 'description';
          document.head.appendChild(meta);
        }
        meta.content = newDescription || 'Default description';
      }
    }
  }
}
</script>