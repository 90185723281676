<template>
  <div class="dashboard-content">
    <div class="form-container">
      <h1>Update Business</h1>
      <div v-if="!business">
        <input
          type="text"
          v-model="searchName"
          placeholder="Enter business name"
          @input="searchBusiness"
          class="input-field"
        />
        <div v-if="businessSuggestions.length > 0" class="suggestions">
          <ul>
            <li
              v-for="business in businessSuggestions"
              :key="business._id"
              @click="selectBusiness(business)"
            >
              {{ business.business_name }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="business">
        <form @submit.prevent="updateBusiness">
          <div class="form-group-inline">
            <label>ID:</label>
            <span class="form-value">{{ business._id }}</span>
          </div>
          <div class="form-group">
            <label>Business Name:</label>
            <input type="text" v-model="business.business_name" class="input-field" />
          </div>
          <div class="form-group">
            <label>Email:</label>
            <input type="email" v-model="business_info.email" class="input-field" required />
          </div>
          <div class="form-group">
            <label>Phone:</label>
            <input type="text" v-model="business_info.phone" class="input-field" required />
          </div>
          <div class="form-group">
            <label>Address:</label>
            <input type="text" v-model="business_info.address" class="input-field" required />
          </div>
          <div class="form-group">
            <label>Business Logo:</label>
            <div v-if="business.business_logo && !showFileInput" class="logo-container">
              <img :src="business.business_logo" alt="Business Logo" class="logo-thumbnail" />
              <button type="button" @click="showFileInput = true" class="change-logo-button">Change Logo</button>
            </div>
            <div v-else>
              <input type="file" @change="onFileChange" class="input-field" ref="logoInput" />
            </div>
          </div>
          <div class="form-buttons">
            <button type="submit" class="submit-button">Update Business</button>
            <button type="button" @click="confirmDelete" class="delete-button">Delete Business</button>
            <button type="button" @click="clearForm" class="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
      <div v-if="showDeleteConfirmation" class="confirmation-dialog">
        <p>Are you sure you want to delete this business?</p>
        <button @click="deleteBusiness" class="confirm-button">Yes</button>
        <button @click="cancelDelete" class="cancel-button">No</button>
      </div>
      <div v-if="isLoading" class="loading-overlay">
        <div class="loader"></div>
        <p>Updating the business, please wait...</p>
      </div>
      <div v-if="isUpdated" class="updated-overlay">
        <p>Business Updated Successfully</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DashboardUpdateBusiness',
  data() {
    return {
      searchName: '',
      business: null,
      business_info: {
        email: '',
        phone: '',
        address: '',
        links: ['']
      },
      businessSuggestions: [],
      business_logo: null,
      showDeleteConfirmation: false,
      showFileInput: false,
      isLoading: false, // State to control the loading spinner visibility
      isUpdated: false, // State to control the "Business Updated" message visibility
    };
  },
  methods: {
    async searchBusiness() {
      if (this.searchName.length > 2) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/search-business`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            params: {
              name: this.searchName,
            },
          });
          this.businessSuggestions = response.data;
        } catch (error) {
          console.error('Error fetching business:', error);
        }
      } else {
        this.businessSuggestions = [];
      }
    },
    async selectBusiness(business) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/get-business/${business._id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.business = response.data;
        this.business_info = typeof this.business.business_info === 'string' 
          ? JSON.parse(this.business.business_info) 
          : this.business.business_info;
        this.searchName = '';
        this.businessSuggestions = [];
        this.showFileInput = false;
      } catch (error) {
        console.error('Error fetching business details:', error);
      }
    },
    onFileChange(e) {
      this.business_logo = e.target.files[0];
    },
    async updateBusiness() {
      this.isLoading = true; // Show the loading spinner

      try {
        const formData = new FormData();
        formData.append('id', this.business._id);
        formData.append('business_name', this.business.business_name);
        formData.append('business_info', JSON.stringify(this.business_info));
        formData.append('business_logo', this.business_logo);

        await axios.post(`${process.env.VUE_APP_API_URL}/api/updates/update-business`, formData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        // Simulate delay for loading spinner visibility
        setTimeout(() => {
          this.isLoading = false; // Hide the spinner
          this.isUpdated = true; // Show the "Business Updated" message

          // Hide the "Business Updated" message after a few seconds
          setTimeout(() => {
            this.isUpdated = false;
            location.reload();
          }, 3000); // Adjust this value to control how long the message is shown
        }, 2000); // Adjust this delay as needed
      } catch (error) {
        console.error('Error updating business:', error);
        this.isLoading = false; // Ensure spinner is hidden in case of error
      }
    },
    confirmDelete() {
      this.showDeleteConfirmation = true;
    },
    async deleteBusiness() {
      try {
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/updates/delete-business/${this.business._id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        alert('Business deleted successfully');
        this.clearForm();
      } catch (error) {
        console.error('Error deleting business:', error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },
    cancelDelete() {
      this.showDeleteConfirmation = false;
    },
    clearForm() {
      this.searchName = '';
      this.business = null;
      this.business_info = {
        email: '',
        phone: '',
        address: '',
        links: ['']
      };
      this.business_logo = null;
      if (this.$refs.logoInput) {
        this.$refs.logoInput.value = '';
      }
      this.businessSuggestions = [];
      this.showFileInput = false;
    }
  }
};
</script>

<style scoped>
.dashboard-content {
  padding: 20px;
  margin-left: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 40px);
  box-sizing: border-box;
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.form-group,
.form-group-inline {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}

.form-group-inline {
  flex-direction: row;
  align-items: center;
}

.form-group-inline label {
  width: 100px;
  margin-bottom: 0;
}

.form-group-inline .form-value {
  margin-left: 10px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 97%;
}

.link-input {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.link-input input {
  flex: 1;
  margin-right: 10px;
}

.remove-button,
.add-link-button,
.change-logo-button {
  padding: 5px 10px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-button {
  background-color: #dc3545;
}

.remove-button:hover {
  background-color: #c82333;
}

.add-link-button {
  background-color: #4C6B30;
  margin-top: 5px;
}

.add-link-button:hover {
  background-color: #6E9F49;
}

.change-logo-button {
  background-color: #007bff;
  margin-top: 10px;
}

.change-logo-button:hover {
  background-color: #0056b3;
}

.logo-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}

.submit-button,
.delete-button,
.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
}

.submit-button {
  background-color: #4C6B30;
  color: white;
}

.submit-button:hover {
  background-color: #6E9F49;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.confirm-button {
  background-color: #dc3545;
  color: white;
}

.confirm-button:hover {
  background-color: #c82333;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

ul {
  background-color: #fff;
  border: 1px solid #ddd;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

li {
  padding: 10px;
  cursor: pointer;
}

li:hover {
  background-color: #f2f2f2;
}

.suggestions {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  z-index: 1000;
}

.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  width: 90%;
  max-width: 300px;
}

.logo-thumbnail {
  max-width: 150px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 4px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4C6B30;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

.updated-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.updated-overlay p {
  font-size: 18px;
  color: #333;
}

p {
  font-size: 18px;
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .form-container {
    padding: 15px;
    width: 95%;
  }

  h1 {
    font-size: 20px;
  }

  .form-group-inline {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group-inline label {
    width: 100%;
    margin-bottom: 5px;
  }

  .form-group-inline .form-value {
    margin-left: 0;
  }

  .submit-button,
  .delete-button,
  .confirm-button,
  .cancel-button {
    width: 100%;
  }

  .input-field{
    width: 95%;
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    padding: 10px;
  }

  .form-container {
    padding: 10px;
  }

  h1 {
    font-size: 18px;
  }

  .input-field,
  .remove-button,
  .add-link-button,
  .change-logo-button {
    font-size: 14px;
  }

  .logo-thumbnail {
    max-width: 100px;
  }
}
</style>
