import { reactive } from 'vue';

export const authState = reactive({
  user: JSON.parse(localStorage.getItem('user')) || null,
  isAdmin: JSON.parse(localStorage.getItem('isAdmin')) || false,
  isBusiness: JSON.parse(localStorage.getItem('isBusiness')) || false,
});

export function setUser(user) {
  authState.user = user;
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
    if (user.isAdmin) {
      authState.isAdmin = true;
      localStorage.setItem('isAdmin', true);
    } else {
      authState.isAdmin = false;
      localStorage.removeItem('isAdmin');
    }
    if (user.isBusiness) {
      authState.isBusiness = true;
      localStorage.setItem('isBusiness', true);
    } else {
      authState.isBusiness = false;
      localStorage.removeItem('isBusiness');
    }
  } else {
    localStorage.removeItem('user');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isBusiness');
    localStorage.removeItem('token');
    localStorage.removeItem('shoppingBagItems');
    localStorage.removeItem('checkoutCartItems');
    localStorage.removeItem('billingDetails')
    authState.isAdmin = false;
    authState.isBusiness = false;
  }
}
