<template>
    <div class="dashboard-page">
      <div class="dashboard-content">
        <div class="profile-grid">
          <div class="left-column">
            <!-- Profile Header Card -->
            <div class="profile-card profile-header-card">
              <div class="profile-header">
                <img :src="businessLogo" alt="Profile Picture" class="profile-image" />
                <div class="profile-details">
                  <h2 class="profile-name">{{ businessName }}</h2>
                  <p class="profile-location">{{ businessLocation }}</p>
                  <div class="profile-actions">
                    <button class="btn-follow">Become a Fan</button>
<!--                     <button class="btn-message">Message</button>
 -->                  </div>
                </div>
              </div>
            </div>
            <!-- Profile Info Card -->
            <div class="profile-card profile-info-card">
              <div class="info-header">
                <h3>Info</h3>
              </div>
              <div class="info-item">
                <strong>Address:</strong>
                <p>{{ info.address }}</p>
                <iframe
                  v-if="info.address"
                  :src="googleMapsEmbedUrl"
                  width="450"
                  height="250"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
              <div class="info-item">
                <strong>Email address:</strong>
                <p>{{ info.email }}</p>
              </div>
              <div class="info-item">
                <strong>Phone:</strong>
                <p>{{ info.phone }}</p>
              </div>
              <div class="info-item">
                <strong>Links:</strong>
                <p v-for="link in info.links" :key="link"><a :href="link">{{ link }}</a></p>
              </div>
            </div>
            <!-- Published Flyer -->
            <div v-if="publishedFlyer" class="profile-card published-flyer-card">
              <h3>Flyer</h3>
              <img :src="publishedFlyer" alt="Flyer" class="published-flyer" />
            </div>
          </div>
          <div class="right-column">
            <!-- Event Calendar -->
            <div v-if="showEventCalendar" class="profile-card calendar-card">
              <h3>Event Calendar</h3>
              <vue-cal
                :events="events"
                hide-view-selector
                active-view="month"
                :disable-views="['week', 'day']"
                events-on-month-view
                twelveHour="true"
                @event-click="onEventClick"
                style="height: 400px;"
                class="custom-vue-cal"
              />
            </div>
            <!-- Posts -->
            <div v-if="salesPosts.length" class="profile-card wider-card">
              <h3>Posts</h3>
              <SalesPost :salesPosts="salesPosts" />
            </div>            
            <!-- Food Menu -->
            <div v-if="showFoodMenu" class="profile-card wider-card">
              <h3>Food Menu</h3>
              <FoodMenu :menu="menu" />
            </div>
            <!-- Gift Cards -->
            <div class="profile-card wider-card">
              <h3>Gift Cards</h3>
              <div class="gift-card-list">
                <GiftCard
                  v-for="giftCard in displayedGiftCards"
                  :key="giftCard._id"
                  :giftCardId="giftCard._id"
                  :dealTitle="giftCard.dealtitle"
                  :rating="giftCard.rating"
                  :cardImage="giftCard.image_links"
                  :originalPrice="giftCard.currentprice"
                  :discountPercentage="giftCard.discount"
                />
              </div>
              <div class="load-more-container" v-if="!allGiftCardsLoaded">
                <button @click="loadMoreGiftCards">Load More Gift Cards</button>
              </div>
            </div>
            <!-- Coupons -->
            <div class="profile-card wider-card">
              <h3>Coupons</h3>
              <div class="coupon-list">
                <CouponCard
                  v-for="coupon in displayedCoupons"
                  :key="coupon._id"
                  :couponId="coupon._id"
                  :dealTitle="coupon.dealtitle"
                  :couponCode="coupon.couponcode[0]"
                  :couponImage="coupon.image_links[0]"
                  :rating="coupon.coupon_rating"
                  :dealDetails="processDiscount(coupon)"
                  :dealtype="coupon.discounttype[0]"
                  @save-coupon="addToShoppingCart"
                  ref="couponCards"
                />
              </div>
              <div class="load-more-container" v-if="!allCouponsLoaded">
                <button @click="loadMoreCoupons">Load More Coupons</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDialog" class="event-dialog">
          <div class="event-dialog-content">
            <h3>{{ selectedEvent.title }}</h3>
            <p>Event starts at: {{ formatTime(selectedEvent.start) }}</p>
            <p>Event ends at: {{ formatTime(selectedEvent.end) }}</p>
            <button @click="closeDialog">Close</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import GiftCard from '@/components/GiftCard Components/GiftCard.vue';
  import CouponCard from '@/components/Coupon Components/CouponCard.vue';
  import FoodMenu from '@/components/Business Profile/FoodMenu.vue';
  import SalesPost from '@/components/Business Profile/SalesPost.vue';
  import VueCal from 'vue-cal';
  import 'vue-cal/dist/vuecal.css';
  import axios from 'axios';
  
  export default {
    name: 'BusinessProfile',
    components: {
      GiftCard,
      CouponCard,
      FoodMenu,
      SalesPost,
      VueCal
    },
    data() {
      return {
        businessName: '',
        businessLogo: '',
        businessLocation: '',
        info: {
          address: '',
          email: '',
          phone: '',
          links: []
        },
        publishedFlyer: null,
        displayedGiftCards: [],
        allGiftCardsLoaded: false,
        displayedCoupons: [],
        allCouponsLoaded: false,
        isLoading: false,
        showEventCalendar: false,
        events: [],
        showFoodMenu: false,
        menu: [],
        salesPosts: [],
        showDialog: false,
        selectedEvent: null,
        couponOffset: 0,
        giftCardOffset: 0,
        limit: 12
      };
    },
    computed: {
      googleMapsEmbedUrl() {
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCE60afA6sO7P_2Dsy9j6o9ymsb8t6Z57s
        &q=${encodeURIComponent(this.info.address)}`;
      }
    },
    mounted() {
      this.loadBusinessProfile();
      this.loadInitialData();
    },
    methods: {
      async loadBusinessProfile() {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/business-profile/${this.$route.params.id}/profile`);
          const profileData = response.data;
          this.businessName = profileData.business_name;
          this.businessLogo = profileData.business_logo;
          this.businessLocation = profileData.business_info.city;
          this.info.address = profileData.business_info.address;
          this.info.email = profileData.business_info.email;
          this.info.phone = profileData.business_info.phone;
          this.info.links = profileData.business_info.links;
          this.publishedFlyer = profileData.published_flyer;
          this.showEventCalendar = profileData.show_event_calendar;
          this.events = profileData.events.map(event => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end)
          }));
          this.showFoodMenu = profileData.show_food_menu;
          this.menu = profileData.menu;
          this.salesPosts = profileData.sales_posts;
        } catch (error) {
          console.error('Error fetching business profile:', error);
        }
      },
      async loadInitialData() {
        await this.loadCoupons();
        await this.loadGiftCards();
      },
      async loadCoupons(reset = false) {
        if (this.isLoading) return;
        this.isLoading = true;
        try {
          if (reset) {
            this.displayedCoupons = [];
            this.couponOffset = 0;
            this.allCouponsLoaded = false;
          }
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/view-coupons/coupon-search/${this.$route.params.id}`, {
            params: {
              offset: this.couponOffset,
              limit: this.limit
            }
          });
          const newCoupons = response.data;
          this.displayedCoupons = reset ? newCoupons : [...this.displayedCoupons, ...newCoupons];
          this.allCouponsLoaded = newCoupons.length < this.limit;
          this.couponOffset += this.limit;
        } catch (error) {
          console.error('Error fetching coupons:', error);
        } finally {
          this.isLoading = false;
        }
      },
      async loadGiftCards(reset = false) {
        if (reset) {
          this.displayedGiftCards = [];
          this.giftCardOffset = 0;
          this.allGiftCardsLoaded = false;
        }
        if (this.isLoading) return;
        this.isLoading = true;
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/view-gift-cards/gift-card-search/${this.$route.params.id}`, {
            params: {
              offset: this.giftCardOffset,
              limit: this.limit
            }
          });
          const newGiftCards = response.data;
          this.displayedGiftCards = reset ? newGiftCards : [...this.displayedGiftCards, ...newGiftCards];
          this.allGiftCardsLoaded = newGiftCards.length < this.limit;
          this.giftCardOffset += this.limit;
        } catch (error) {
          console.error('Error fetching gift cards:', error);
        } finally {
          this.isLoading = false;
        }
      },
      processDiscount(coupon) {
        const { discount, regularprice, spendamount, discounttype } = coupon;
        const isRegularPriceValid = regularprice[0] !== 0;
        const isSpendAmountValid = spendamount[0] !== 0;
  
        return {
          discount: discount[0],
          regularprice: isRegularPriceValid ? regularprice : null,
          spendamount: isSpendAmountValid ? spendamount : null,
          discounttype: discounttype[0]
        };
      },
      async addToShoppingCart(couponId) {
        const userId = this.getUserId();
        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/${userId}`, { couponId });
          this.$refs.couponCards.forEach(card => {
            if (card.couponId === couponId) {
              card.showMessage('Coupon added to saved coupons');
            }
          });
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.$refs.couponCards.forEach(card => {
              if (card.couponId === couponId) {
                card.showMessage('Coupon already saved');
              }
            });
          } else {
            this.$refs.couponCards.forEach(card => {
              if (card.couponId === couponId) {
                card.showMessage('Error adding coupon to shopping cart');
              }
            });
          }
        }
      },
      getUserId() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.id : null;
      },
      onEventClick(event) {
        this.selectedEvent = event;
        this.showDialog = true;
      },
      closeDialog() {
        this.showDialog = false;
        this.selectedEvent = null;
      },
      formatTime(date) {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return `${hours}:${minutes} ${ampm}`;
      },
      loadMoreCoupons() {
        this.loadCoupons();
      },
      loadMoreGiftCards() {
        this.loadGiftCards();
      }
    }
  };
  </script>
  
  <style scoped>
  .dashboard-content {
    padding: 20px;
    overflow-y: auto; /* To ensure the content scrolls if it's too long */
    box-sizing: border-box; /* To include padding in the element's total width and height */
    display: flex;
    flex-direction: column;
  }
  
  .profile-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
  }
  
  .left-column,
  .right-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .left-column {
    max-width: 500px;
  }
  
  .right-column {
    max-width: 1000px;
  }
  
  .profile-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%; /* Make the card take full width */
  }
  
  .wider-card {
    max-width: 1000px; /* Make the gift card and coupon cards wider */
  }
  
  .profile-header-card {
    display: flex;
    align-items: center;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
  }
  
  .profile-image {
    width: 65%; /* Smaller image */
    height: 65%; /* Smaller image */
    border-radius: 0%;
  }
  
  .profile-details {
    flex-grow: 1;
  }
  
  .profile-name {
    margin: 0;
    font-size: 20px; /* Smaller font size */
    font-weight: bold;
  }
  
  .profile-location {
    color: #777;
    margin-bottom: 10px;
  }
  
  .profile-actions {
    display: flex;
    gap: 10px;
  }
  
  .btn-follow,
  .btn-message {
    padding: 5px 10px; /* Smaller button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-follow {
    background-color: #4C6B30;
    color: white;
  }
.btn-follow:hover{
  background-color: #6E9F49;
}
  
  .btn-message {
    background-color: #007bff;
    color: white;
  }
  
  .profile-info {
    margin-top: 0; /* Ensure no top margin */
  }
  
  .info-item {
    margin-bottom: 10px;
  }
  
  #map {
    height: 200px;
    width: 100%;
    margin-top: 10px;
  }
  
  .action-card {
    width: 100%;
  }
  
  .btn-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .btn-action {
    flex: 1;
    padding: 10px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #4C6B30;
    color: white;
  }
  
  .btn-action[disabled] {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .btn-action:hover:not([disabled]) {
    background-color: #6E9F49;
  }
  
  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .edit-btn {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
  }
  
  .edit-btn:hover {
    text-decoration: underline;
  }
  
  .link-input {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .link-input input {
    flex: 1;
  }
  
  .gift-card-list,
  .coupon-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .load-more-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .load-more-container button {
    padding: 20px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #4C6B30;
    color: white;
    font-size: 15px;
  }
  
  .load-more-container button:hover {
    background-color: #6E9F49;
  }
  
  .event-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .calendar-card {
    max-width: 1000px;
    height: 500px;
    max-height: 600px;
    overflow: hidden; /* Ensure the calendar doesn't overflow */
  }
  
  .custom-vue-cal .vuecal__event {
    padding: 2px 5px;
    background-color: #007bff;
    color: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .custom-vue-cal .vuecal__event-title {
    font-weight: bold;
  }
  
  .custom-vue-cal .vuecal__event-time {
    font-size: 0.8em;
  }
  
  .event-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .event-dialog-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .remove-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .remove-btn:hover {
    background-color: #c82333;
  }
  
  button {
    cursor: pointer;
  }
  
  .published-flyer {
    max-width: 450px; /* Adjust width to match info card */
    max-height: 650px;
    overflow: hidden;
  }
  
  .top-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .btn-edit {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
  }
  
  .btn-publish:disabled {
    cursor: not-allowed;
  }


  @media (max-width: 768px) {
  .profile-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .left-column,
  .right-column {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .profile-card {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .gift-card-list,
  .coupon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column; /* Re-added to ensure correct display */
  }

  .gift-card-list .gift-card,
  .coupon-list .coupon-card {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  .profile-image {
    width: 100%;
    height: 100%;
  }

  .profile-header {
    flex-direction: column;
    align-items: center;
  }

  .profile-details {
    text-align: center;
  }

  .profile-actions {
    justify-content: center;
  }

  .load-more-container button {
    width: 100%;
    padding: 10px;
  }

  .calendar-card {
    width: 100%;
    height: 500px;
    max-height: 600px;
    padding: 10px;
    box-sizing: border-box;
  }

  .custom-vue-cal {
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .info-item iframe {
    width: 100%;
    height: 200px;
    box-sizing: border-box;
  }

  .profile-info-card {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .gift-card-list .gift-card,
  .coupon-list .coupon-card {
    width: 100%;
  }

  .info-item iframe {
    width: 100%;
    height: 150px;
    box-sizing: border-box;
  }

  .profile-info-card {
    margin-bottom: 20px;
  }
}
  </style>
  