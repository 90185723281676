<template>
  <div class="step-options">
    <h2>Step 2: Options</h2>
    <div class="form-container">
      <div class="option-form">
        <h3>Option {{ currentOptionIndex + 1 }}</h3>
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="dealName">Option Name</label>
            <input type="text" id="dealName" v-model="currentOption.dealName" maxlength="120" @input="validateForm" required />
            <small>{{ 120 - currentOption.dealName.length }} Characters remaining</small>
          </div>
          <div class="form-group">
            <label for="couponCode">Coupon Code</label>
            <div class="coupon-code-container">
              <input type="text" id="couponCode" v-model="currentOption.couponCode" maxlength="15" @input="validateForm" />
              <button type="button" @click="generateCouponCode">Generate</button>
            </div>
          </div>
          <div class="form-group">
            <label for="discountType">Discount Type</label>
            <select id="discountType" v-model="currentOption.discountType" @change="validateForm" required>
              <option value="">Select Discount Type</option>
              <option value="percentageAmount">Percentage of $ Amount</option>
              <option value="amountOff">Dollar Amount Off</option>
              <option value="buyOneGetOnePercentage">Buy 1 Get 1 Off %</option>
              <option value="buyOneGetOneAmount">Buy 1 Get 1 Off $ Amount</option>
              <option value="buyOneGetOneFree">Buy One Get One Free</option>
            </select>
          </div>

          <!-- Conditional Fields based on Discount Type -->
          <div v-if="currentOption.discountType === 'percentageAmount'" class="form-group">
            <label for="spendAmount">Spend Amount ($)</label>
            <input type="number" id="spendAmount" v-model.number="currentOption.spendAmount" min="0" step="0.01" @input="validateForm" required />
            <label for="discountPercentage">Discount Percentage</label>
            <input type="number" id="discountPercentage" v-model.number="currentOption.discount" min="0" max="100" step="1" @input="validateForm" required />
          </div>
          <div v-if="currentOption.discountType === 'amountOff'" class="form-group">
            <label for="regularPrice">Regular Price ($)</label>
            <input type="number" id="regularPrice" v-model.number="currentOption.regularPrice" min="0" step="0.01" @input="validateForm" required />
            <label for="discountAmount">Discount Amount ($)</label>
            <input type="number" id="discountAmount" v-model.number="currentOption.discount" min="0" step="0.01" @input="validateForm" required />
          </div>
          <div v-if="currentOption.discountType === 'buyOneGetOnePercentage'" class="form-group">
            <label for="bogoDiscountPercentage">Buy 1 Get 1 Off Percentage</label>
            <input type="number" id="bogoDiscountPercentage" v-model.number="currentOption.discount" min="0" max="100" step="1" @input="validateForm" required />
          </div>
          <div v-if="currentOption.discountType === 'buyOneGetOneAmount'" class="form-group">
            <label for="bogoDiscountAmount">Buy 1 Get 1 Off Amount ($)</label>
            <input type="number" id="bogoDiscountAmount" v-model.number="currentOption.discount" min="0" step="0.01" @input="validateForm" required />
          </div>
          <!-- No additional fields needed for Buy One Get One Free -->

          <div class="button-group">
            <button type="submit">{{ isEditing ? 'Update Option' : 'Add Option' }}</button>
          </div>
        </form>
      </div>
      <div class="options-list">
        <h3>Added Options</h3>
        <div v-for="(option, index) in options" :key="index" class="option-summary">
          <span>Option {{ index + 1 }}</span>
          <div class="option-buttons">
            <button @click="editOption(index)">Edit</button>
            <button @click="confirmDelete(index)">Delete</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDeleteConfirm" class="delete-confirmation">
      <p>Are you sure you want to delete this option?</p>
      <button @click="deleteOption">Yes</button>
      <button @click="cancelDelete">No</button>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
  name: 'StepOptions',
  props: {
    currentStep: Number,
  },
  setup(props, { emit }) {
    const options = ref([]);
    const currentOption = ref({
      dealName: '',
      maxPerMonth: 0,
      discountType: '',
      discount: 0,
      couponCode: '',
      spendAmount: null,
      regularPrice: null,
    });
    const currentOptionIndex = ref(options.value.length);
    const isEditing = ref(false);
    const isFormValid = ref(false);
    const showDeleteConfirm = ref(false);
    const deleteIndex = ref(null);
    const hasTextInForm = ref(false);

    const validateForm = () => {
      const option = currentOption.value;
      let isValid = false;

      switch (option.discountType) {
        case 'percentageAmount':
          isValid = option.dealName && option.maxPerMonth > 0 && option.spendAmount >= 0 && option.discount >= 0 && option.discount <= 100;
          break;
        case 'amountOff':
          isValid = option.dealName && option.maxPerMonth > 0 && option.regularPrice >= 0 && option.discount >= 0;
          break;
        case 'buyOneGetOnePercentage':
          isValid = option.dealName && option.maxPerMonth > 0 && option.discount >= 0 && option.discount <= 100;
          break;
        case 'buyOneGetOneAmount':
          isValid = option.dealName && option.maxPerMonth > 0 && option.discount >= 0;
          break;
        case 'buyOneGetOneFree':
          isValid = option.dealName && option.maxPerMonth > 0;
          break;
        default:
          isValid = false;
      }

      isFormValid.value = isValid || (options.value.length > 0 && !hasTextInForm.value);
      emit('formValidation', isFormValid.value);
    };

    const addOption = () => {
      options.value.push({ ...currentOption.value });
      localStorage.setItem('options', JSON.stringify(options.value));
      clearCurrentOption();
    };

    const updateOption = () => {
      options.value[currentOptionIndex.value] = { ...currentOption.value };
      localStorage.setItem('options', JSON.stringify(options.value));
      clearCurrentOption();
    };

    const clearCurrentOption = () => {
      currentOption.value = {
        dealName: '',
        maxPerMonth: 0,
        discountType: '',
        discount: 0,
        couponCode: '',
        spendAmount: null,
        regularPrice: null,
      };
      currentOptionIndex.value = options.value.length;
      isEditing.value = false;
      hasTextInForm.value = false;
      validateForm();
    };

    const cancelEdit = () => {
      clearCurrentOption();
      emit('formValidation', options.value.length > 0);
    };

    const editOption = (index) => {
      currentOption.value = { ...options.value[index] };
      currentOptionIndex.value = index;
      isEditing.value = true;
      validateForm();
    };

    const confirmDelete = (index) => {
      showDeleteConfirm.value = true;
      deleteIndex.value = index;
    };

    const deleteOption = () => {
      if (deleteIndex.value !== null) {
        options.value.splice(deleteIndex.value, 1);
        localStorage.setItem('options', JSON.stringify(options.value));
        clearCurrentOption();
        showDeleteConfirm.value = false;
        deleteIndex.value = null;
      }
    };

    const cancelDelete = () => {
      showDeleteConfirm.value = false;
      deleteIndex.value = null;
    };

    const handleSubmit = () => {
      if (isEditing.value) {
        updateOption();
      } else {
        addOption();
      }
    };

    const handleNextStep = () => {
      if (isEditing.value) {
        updateOption();
      } else if (currentOption.value.dealName || currentOption.value.maxPerMonth || currentOption.value.discount) {
        addOption();
      }
      emit('nextStep');
    };

    const resetOnBack = () => {
      clearCurrentOption();
      emit('formValidation', options.value.length > 0);
    };

    const generateCouponCode = () => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let couponCode = '';
      for (let i = 0; i < 12; i++) {
        if (i > 0 && i % 3 === 0) {
          couponCode += '-';
        }
        couponCode += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      currentOption.value.couponCode = couponCode;
      validateForm();
    };

    onMounted(() => {
      const savedOptions = localStorage.getItem('options');
      if (savedOptions) {
        options.value = JSON.parse(savedOptions);
      }
      resetOnBack();
    });

    watch(() => props.currentStep, (newStep) => {
      if (newStep === 1) {
        resetOnBack();
      }
    });

    watch(currentOption, () => {
      hasTextInForm.value = !!currentOption.value.dealName || !!currentOption.value.maxPerMonth || !!currentOption.value.discount || !!currentOption.value.couponCode;
      validateForm();
    }, { deep: true });

    return {
      options,
      currentOption,
      currentOptionIndex,
      isEditing,
      isFormValid,
      showDeleteConfirm,
      deleteIndex,
      hasTextInForm,
      addOption,
      updateOption,
      clearCurrentOption,
      editOption,
      confirmDelete,
      deleteOption,
      cancelDelete,
      handleSubmit,
      handleNextStep,
      cancelEdit,
      resetOnBack,
      generateCouponCode,
    };
  },
};
</script>

<style scoped>
.step-options {
  width: 100%;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.option-form {
  width: 60%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.form-group {
  margin-bottom: 10px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-row .form-group {
  width: 48%;
}

.coupon-code-container {
  display: flex;
  gap: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

small {
  display: block;
  margin-top: 5px;
  color: #888;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #4C6B30;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 107, 48, 0.5);
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.options-list {
  width: 35%;
  padding-left: 20px;
  border-left: 1px solid #ccc;
}

.option-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.option-buttons {
  display: flex;
  gap: 10px;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-confirmation p {
  margin-bottom: 10px;
}

.delete-confirmation button {
  margin-right: 10px;
  margin-bottom: 15px;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .option-form {
    width: 100%;
  }

  .options-list {
    width: 100%;
    padding-left: 0;
    border-left: none;
    margin-top: 20px;
  }

  .form-row .form-group {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form-container {
    flex-direction: column;
  }

  .option-form {
    width: 100%;
  }

  .options-list {
    width: 100%;
    padding-left: 0;
    border-left: none;
    margin-top: 20px;
  }

  .form-row .form-group {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .form-container {
    flex-direction: column;
  }

  .option-form {
    width: 100%;
  }

  .options-list {
    width: 100%;
    padding-left: 0;
    border-left: none;
    margin-top: 20px;
  }

  .form-row .form-group {
    width: 100%;
  }

  button {
    width: 100%;
  
    padding: 10px;
  }

  .button-group {
    flex-direction: column;
    gap: 5px;
  }

  .option-buttons {
    flex-direction: column;
    gap: 5px;
  }
}
</style>