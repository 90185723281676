<template>
  <div class="reviews-container">
    <h1 class="title">Reviews</h1>

    <div v-if="reviews.length === 0" class="no-reviews">
      You have no reviews.
    </div>

    <div v-else class="reviews-list">
      <div v-for="review in reviews" :key="review.id" class="review-card" @click="navigateToDetails(review)">
        <div class="review-content">
          <div class="review-icon">
            <i class="fas fa-bell"></i>
          </div>
          <div class="review-info">
            <h2 class="review-title">{{ review.couponTitle || review.giftCardTitle }}</h2>
            <div class="star-rating">
              <span v-for="n in 5" :key="n" class="star-wrapper">
                <span class="star" :class="{ filled: n <= review.rating }">★</span>
              </span>
            </div>
            <p class="review-body">{{ review.comment }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ReviewsPage',
  data() {
    return {
      reviews: [],
    };
  },
  methods: {
    async fetchReviews() {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      if (user && user.id && token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/reviews/user-reviews`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userId: user.id,
            },
          });
          this.reviews = response.data.reviews;
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
      }
    },
    navigateToDetails(review) {
      if (review.couponId) {
        this.$router.push(`/coupon/${review.couponId}`);
      } else if (review.giftCardId) {
        this.$router.push(`/gift-card/${review.giftCardId}`);
      }
    },
  },
  mounted() {
    this.fetchReviews();
  },
};
</script>

<style scoped>
.reviews-container {
  max-width: 900px;
  margin: 0 0 0 20px; /* Left-aligned reviews */
  padding: 20px;
}

h1 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.review-card {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd; /* Line separator */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.review-card:last-child {
  border-bottom: none; /* Remove the border for the last review */
}

.review-card:hover {
  background-color: #f9f9f9; /* Hover effect background */
  transform: translateY(-3px); /* Slight movement on hover */
}

.review-content {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.review-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(110, 159, 73, 0.19); /* #6E9F49 with 19% transparency */
  margin-right: 20px;
}

.review-icon i {
  font-size: 20px;
  color: #6e9f49; /* Icon color */
}

.review-info {
  flex-grow: 1;
}

.review-title {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.review-body {
  font-size: 14px;
  color: #666;
}

.star-rating {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
}

.star-wrapper {
  position: relative;
}

.star {
  color: #ddd;
  font-size: 1.5rem;
}

.star.filled {
  color: #ffc107;
}

.no-reviews {
  text-align: center;
  color: #777;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .reviews-container {
    padding: 15px;
  }

  .review-title,
  .review-body {
    font-size: 0.95em;
  }

  .star {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .reviews-container {
    padding: 10px;
  }

  .review-body {
    word-wrap: break-word;
  }

  .review-card {
    padding: 10px;
    max-width: 330px;
  }

  .review-title,
  .review-body {
    font-size: 0.9em;
  }

  .star {
    font-size: 1rem;
  }
}
</style>
