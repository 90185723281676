<template>
  <div class="customer-container">
    <div class="customer-header">
      Payouts this month
    </div>
    <div class="customer-body">
      <div class="customer-amount">${{ payouts }}</div>
      <div class="payout-date">Payout Date: {{ payoutDate }}</div>
      <button 
        @click="requestPayout" 
        class="request-payout-button" 
        :disabled="requestedPayout"
      >
        Request Payout
      </button>
      <div v-if="payoutRequestSent" class="confirmation-message">
        Request has been sent. Someone will contact you soon.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CustomerCard',
  props: {
    payouts: String,
  },
  data() {
    return {
      payoutDate: this.getPayoutDate(),
      requestedPayout: false,
      payoutRequestSent: false,
      businessId: null,
    };
  },
  mounted() {
    this.loadBusinessId();
    this.checkPayoutStatus();
  },
  methods: {
    getPayoutDate() {
      const date = new Date();
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return lastDay.toLocaleDateString();
    },
    loadBusinessId() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.business_id) {
        this.businessId = user.business_id;
      } else {
        console.error('Business ID not found in localStorage');
      }
    },
    async checkPayoutStatus() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/business-sales/salesAmount/${this.businessId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.requestedPayout = response.data[0].requested_payout;
      } catch (error) {
        console.error('Error checking payout status:', error);
      }
    },
    async requestPayout() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/business-sales/request-payout/${this.businessId}`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.requestedPayout = true;
        this.payoutRequestSent = true;
      } catch (error) {
        console.error('Error requesting payout:', error);
        alert('Error requesting payout');
      }
    },
  },
};
</script>

<style scoped>
.customer-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem; /* Use rem for padding */
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%; /* Ensure it doesn't overflow */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center text */
}

.customer-header {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  font-size: 1.1rem; /* Use rem for font size */
  margin-bottom: 0.5rem; /* Use rem for margin */
  width: 100%; /* Ensure it takes full width */
  text-align: center; /* Center text */
}

.icon-wrapper {
  background-color: #9C27B0; /* Purple background */
  color: #ffffff; /* White color for the icon */
  border-radius: 50%;
  padding: 0.5rem; /* Use rem for padding */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.customer-body {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center content horizontally */
  width: 100%; /* Ensure it takes full width */
  text-align: center; /* Center text */
}

.customer-amount {
  font-size: 2rem; /* Use rem for font size */
  font-weight: bold;
}

.payout-date {
  font-size: 1rem; /* Use rem for font size */
  color: #666;
  margin-top: 1rem; /* Use rem for margin */
}

.request-payout-button {
  margin-top: 1rem; /* Use rem for margin */
  padding: 0.5rem 1rem; /* Use rem for padding */
  background-color: #4C6B30;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Use rem for font size */
}

.request-payout-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.request-payout-button:hover:enabled {
  background-color: #6E9F49;
}

.confirmation-message {
  margin-top: 1rem; /* Use rem for margin */
  color: #4C6B30;
  font-size: 1rem; /* Use rem for font size */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .customer-header {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .customer-amount {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .payout-date {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  .request-payout-button {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    padding: 0.5rem 0.8rem; /* Adjust padding for smaller screens */
  }

  .confirmation-message {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .customer-container {
    padding: 50px 0; /* Adjust padding for very small screens */
  }

  .customer-header {
    font-size: 0.9rem; /* Adjust font size for very small screens */
  }

  .customer-amount {
    font-size: 1.2rem; /* Adjust font size for very small screens */
  }

  .payout-date {
    font-size: 0.8rem; /* Adjust font size for very small screens */
  }

  .request-payout-button {
    font-size: 0.8rem; /* Adjust font size for very small screens */
    padding: 0.4rem 0.6rem; /* Adjust padding for very small screens */
  }

  .confirmation-message {
    font-size: 0.8rem; /* Adjust font size for very small screens */
  }
}
</style>
