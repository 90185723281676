import { ref } from 'vue';

export function useRecaptcha() {
  const recaptchaToken = ref('');

  const executeRecaptcha = () => {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute('6LeFSyYqAAAAAFR9--5MvTEvGw0HaPWXiOSMlHsL', { action: 'submit' }).then(token => {
            recaptchaToken.value = token;
            resolve(token);
          }).catch(error => {
            reject(error);
          });
        });
      } else {
        reject(new Error('reCAPTCHA not loaded'));
      }
    });
  };

  return {
    recaptchaToken,
    executeRecaptcha,
  };
}
