<template>
  <div class="step-preview">
    <MetaManager
      :title="metaTitle"
      :description="metaDescription"
    />
    <!-- Deal Title and Business Info -->
    <div class="deal-header">
      <h2 class="deal-title">{{ giftCardDetails.dealtitle }}</h2>
      <div class="business-info">
        <h3 class="business-title">{{ giftCardDetails.businessName }} | <i class="fas fa-star" style="color: #FFD705;"></i> <strong style="color: black;">{{ averageRating }}</strong> <strong style="color: black;">(</strong>{{ reviews.length }} reviews<strong style="color: black;">)</strong></h3>
      </div>
    </div>
    <div class="content-container">
      <!-- Left Side -->
      <div class="left-side">
        <!-- Image Viewer -->
        <div class="image-viewer">
          <button class="main-arrow left" v-if="images.length > 0" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
          <div class="main-image">
            <img v-if="images.length > 0" :src="images[selectedImageIndex]" alt="Main Image" />
            <img v-else src="@/assets/default-image.png" alt="Default Image" />
          </div>
          <button class="main-arrow right" v-if="images.length > 0" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
        </div>

        <!-- Thumbnails -->
        <div class="thumbnail-slider-container" v-if="images.length > 0">
          <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
            <span>&#9664;</span>
          </button>
          <div class="thumbnail-slider">
            <div class="thumbnail-container">
              <div class="image-preview" v-for="(image, index) in visibleThumbnails" :key="index" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
                <img :src="image" :alt="'Image ' + (index + 1)" />
              </div>
            </div>
          </div>
          <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
            <span>&#9654;</span>
          </button>
        </div>

        <!-- Mobile Options (for mobile) -->
        <div class="mobile-options-list" v-if="option">
          <div class="option-item">
            <div class="option-content">
              <input type="radio" id="mobile-option" name="mobile-option" checked />
              <label for="mobile-option" class="option-label">
                <strong>{{ option.dealname }}</strong>
              </label>
            </div>
            <div class="option-pricing">
              <span class="original-price">${{ option.currentprice }}</span>
              <span class="new-price">${{ calculateCustomerPays(option) }}</span>
              <span class="discount">{{ option.discount }}% Off</span>
            </div>
            <div class="option-stock">
              <p>In Stock: {{ option.numberinstock }}</p>
              <p>Max Purchase Amount: {{ option.maxperpurchase }}</p>
            </div>
            <div class="option-quantity">
              <input type="number" min="1" :max="maxPurchaseAmount" v-model="purchaseQuantity" />
            </div>
            <div class="button-container">
              <button class="buy-now" :disabled="isMaxExceeded" @click="buyNow">Buy Now</button>
            </div>
            <div class="button-container">
              <button class="become-fan" @click="subscribeToBusiness">
                {{ isFan ? 'Update Preferences' : 'Become a Fan' }}
              </button>
            </div>
            <div class="share-section">
              <h3>Share This Deal</h3>
              <div class="social-icons">
                <a href="#" class="social-icon" @click.prevent="shareViaEmail"><i class="fas fa-envelope"></i></a>
                <a href="#" class="social-icon" @click.prevent="shareOnFacebook"><i class="fab fa-facebook"></i></a>
                <a href="#" class="social-icon" @click.prevent="shareOnTwitter"><i class="fab fa-twitter"></i></a>
                <a href="#" class="social-icon" @click.prevent="shareOnPinterest"><i class="fab fa-pinterest"></i></a>
              </div>
            </div>
          </div>
        </div>

        <!-- Nav Links for About, Fine Print, Reviews -->
        <div class="section-nav">
          <a href="#about">About</a>
          <a href="#fine-print">Fine Print</a>
          <a href="#reviews">Reviews</a>
        </div>
        <hr class="text-separator" />

        <!-- About Deal -->
        <div id="about" class="about-deal" v-if="giftCardDetails.dealdetails">
          <div v-html="giftCardDetails.dealdetails"></div>
        </div>

        <!-- Fine Print -->
        <div id="fine-print" class="fine-print" v-if="giftCardDetails.dealfineprint">
          <h2>Fine Print</h2>
          <hr class="text-separator" />
          <div v-html="giftCardDetails.dealfineprint"></div>
        </div>

        <!-- Customer Reviews -->
        <CustomerReviews
          :reviews="reviews"
          :averageRating="averageRating"
          :giftCardId="giftCardDetails.id"
          @review-added="addReview"
        />
      </div>

      <!-- Right Side -->
      <div class="right-side">
        <!-- Options -->
        <div class="options-list" v-if="option">
          <div class="option-item">
            <div class="option-content">
              <input type="radio" id="option" name="option" checked />
              <label for="option" class="option-label">
                <strong>{{ option.dealname }}</strong>
              </label>
            </div>
            <div class="option-pricing">
              <span class="original-price">${{ option.currentprice }}</span>
              <span class="new-price">${{ calculateCustomerPays(option) }}</span>
              <span class="discount">{{ option.discount }}% Off</span>
            </div>
            <div class="option-stock">
              <p>In Stock: {{ option.numberinstock }}</p>
              <p>Max Purchase Amount: {{ option.maxperpurchase }}</p>
            </div>
            <div class="option-quantity">
              <input type="number" min="1" :max="maxPurchaseAmount" v-model="purchaseQuantity" />
            </div>
            <div class="button-container">
              <button class="buy-now" :disabled="isMaxExceeded" @click="buyNow">Buy Now</button>
            </div>
            <div class="button-container">
              <button class="become-fan" @click="subscribeToBusiness">
                {{ isFan ? 'Update Preferences' : 'Become a Fan' }}
              </button>
            </div>
            <div class="share-section">
              <h3>Share This Deal</h3>
              <div class="social-icons">
                <a href="#" class="social-icon" @click.prevent="shareViaEmail"><i class="fas fa-envelope"></i></a>
                <a href="#" class="social-icon" @click.prevent="shareOnFacebook"><i class="fab fa-facebook"></i></a>
                <a href="#" class="social-icon" @click.prevent="shareOnTwitter"><i class="fab fa-twitter"></i></a>
                <a href="#" class="social-icon" @click.prevent="shareOnPinterest"><i class="fab fa-pinterest"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!-- Explore More Section -->
    <div class="explore-more-section">
      <h2>Explore More</h2>
      <div class="deals-grid-horizontal">
        <GiftCard
          v-for="(giftCard, index) in exploreMoreDeals"
            :key="index"
            :giftCardId="giftCard._id"
            :dealTitle="giftCard.dealtitle"
            :rating="giftCard.rating"
            :cardImage="getGiftCardImage(giftCard)"
            :originalPrice="giftCard.currentprice"
            :discountPercentage="giftCard.discount"
            :businessId="giftCard.business_id"
        />
      </div>
    </div>

    <!-- Sponsored Deals Section -->
    <div v-if="sponsoredDeals.length > 0" class="sponsored-deals-section">
  <h2>Sponsored Deals</h2>
  <div class="deals-grid-horizontal">
    <GiftCard
      v-for="(giftCard, index) in sponsoredDeals"
      :key="index"
      :giftCardId="giftCard._id"
      :dealTitle="giftCard.dealtitle"
      :rating="giftCard.rating"
      :cardImage="getGiftCardImage(giftCard)"
      :originalPrice="giftCard.currentprice"
      :discountPercentage="giftCard.discount"
      :businessId="giftCard.business_id"
    />
  </div>
</div>
    <!-- Notification Modal -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal" @click.stop>
        <h3>Subscribe to Notifications</h3>
        <p>Become a fan and receive notifications when this business posts new coupons, gift cards, or updates</p>
        <form @submit.prevent="submitSubscription">
          <div class="checkbox-group">
            <div>
              <input type="checkbox" id="email" v-model="subscription.email" />
              <label for="email">Email</label>
            </div>
            <div>
              <input type="checkbox" id="text" v-model="subscription.text" />
              <label for="text">Text</label>
            </div>
            <div>
              <input type="checkbox" id="notifications" v-model="subscription.notifications" />
              <label for="notifications">Notifications</label>
            </div>
          </div>
          <div class="modal-buttons">
            <button type="submit" class="submit-button">Submit</button>
            <button v-if="isFan" type="button" class="unsubscribe-button" @click="unsubscribe">Unsubscribe</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CustomerReviews from '@/components/Reviews/GiftCardReviews.vue'; 
import GiftCard from '@/components/GiftCard Components/GiftCard.vue'; 
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'GiftCardPreview',
  components: {
    CustomerReviews,
    GiftCard,
    MetaManager
  },
  data() {
    return {
      giftCardDetails: {
        dealtitle: '',
        category: '',
        dealdetails: '',
        dealfineprint: '',
        business_id: null,
      },
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      thumbnailsPerPage: 6,
      option: null,
      purchaseQuantity: 1,
      isLoggedIn: false,
      message: '',
      fading: false,
      showModal: false,
      subscription: {
        email: true,
        text: true,
        notifications: true
      },
      isFan: false,
      reviews: [],
      averageRating: 0,
      relatedItems: [],
      relatedStartIndex: 0,
      relatedEndIndex: 1, // Initially show two items
      displayedAds: [],
      isInCart: false,
      token: localStorage.getItem('token'),
      metaTitle: '',
      metaDescription: '',
      sponsoredDeals: [],
    };
  },
  computed: {
    visibleThumbnails() {
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
    maxPurchaseAmount() {
      if (this.option) {
        return Math.min(this.option.maxperpurchase, this.option.numberinstock);
      }
      return 1;
    },
    isMaxExceeded() {
      return this.purchaseQuantity > this.maxPurchaseAmount;
    },
    visibleRelatedItems() {
      return this.relatedItems.slice(this.relatedStartIndex, this.relatedEndIndex + 1);
    },
    cartButtonText() {
      return this.isInCart ? 'Remove from Cart' : 'Add to Cart';
    }
  },
  watch: {
    option: {
      handler() {
        this.$nextTick(() => {
          this.checkIfInCart();
        });
      },
      deep: true
    },
    giftCardDetails: {
      handler() {
        this.$nextTick(() => {
          this.checkIfInCart();
        });
      },
      deep: true
    }
  },
  async created() {
    await this.fetchAds();
  },
  methods: {
    processDiscount(coupon) {
        const { discount, regularprice, spendamount, discounttype } = coupon;
        const isRegularPriceValid = regularprice[0] !== 0;
        const isSpendAmountValid = spendamount[0] !== 0;
  
        return {
          discount: discount[0],
          regularprice: isRegularPriceValid ? regularprice : null,
          spendamount: isSpendAmountValid ? spendamount : null,
          discounttype: discounttype[0]
        };
      },
      getGiftCardImage(deal) {
      // Check if image_links exists and is an array with at least one item
      if (Array.isArray(deal.image_links) && deal.image_links.length > 0) {
        return deal.image_links[0]; // Return the first image
      }
      // If image_links is a string, return it directly
      if (typeof deal.image_links === 'string') {
        return deal.image_links;
      }
      // If no valid image, return an empty string
      return '';
    },
    shareOnFacebook() {
      try {
        const currentUrl = window.location.href;
        const encodedUrl = encodeURIComponent(currentUrl);
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        console.log('Encoded URL:', encodedUrl);  // Log the encoded URL
        console.log('Facebook Share URL:', facebookUrl);  // Log the Facebook share URL
        window.open(facebookUrl, '_blank', 'width=600,height=400,scrollbars=no,resizable=no,toolbar=no,menubar=no,location=no,directories=no,status=no');
      } catch (error) {
        console.error('Error sharing on Facebook:', error);
      }
    },

    shareViaEmail() {
      const subject = encodeURIComponent(this.giftCardDetails.dealtitle);
      const body = encodeURIComponent(`Check out this deal: ${this.giftCardDetails.dealtitle}\n\n${window.location.href}`);
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    },

    shareOnPinterest() {
      const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}&media=${encodeURIComponent(this.images[0])}&description=${encodeURIComponent(this.giftCardDetails.dealtitle)}`;
      window.open(url, '_blank');
    },

    shareOnTwitter() {
      const text = encodeURIComponent(`Check out this deal: ${this.giftCardDetails.dealtitle}`);
      const url = `https://twitter.com/intent/tweet?text=${text}&url=${encodeURIComponent(window.location.href)}`;
      window.open(url, '_blank');
    },
    async fetchAds() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/advertisements/product`);
        const ads = response.data;

        // Ensure there are always 3 ads
        while (ads.length < 1) {
          ads.push({ image_url: require('@/assets/advertising-promo-product.png'), link_url: '/contact' });
        }

        this.displayedAds = ads;
      } catch (error) {
        console.error('Error fetching ads:', error);
        // Fill with default ads in case of an error
        this.displayedAds = Array(3).fill({ image_url: require('@/assets/advertising-promo-product.png'), link_url: '/contact' });
      }
    },
    async fetchGiftCardData() {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user ? user.id : null;

        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/get-gift-cards/data/${this.$route.params.id}`, {
          params: { userId }
        });
        const data = response.data;

        this.giftCardDetails = {
          dealtitle: data.dealtitle,
          category: data.category,
          dealdetails: data.dealdetails,
          dealfineprint: data.dealfineprint,
          business_id: data.business_id,
          businessName: data.business_name,
        };

        // Set meta title and description
        this.metaTitle = this.giftCardDetails.dealtitle;
        this.metaDescription = this.giftCardDetails.dealdetails 
          ? this.stripHtmlTags(this.giftCardDetails.dealdetails).substring(0, 160)
          : 'Check out this amazing gift card deal!';

        this.images = data.image_links || [];
        this.option = {
          dealname: data.dealname,
          currentprice: data.currentprice,
          discount: data.discount,
          maxperpurchase: data.maxperpurchase,
          numberinstock: data.numberinstock,
        };
        this.reviews = data.reviews || [];
        this.updateAverageRating();
        this.isLoggedIn = !!localStorage.getItem('user');
        this.checkNotificationPreferences();
        this.checkIfInCart();
      } catch (error) {
        console.error('Error fetching gift card data:', error);
      }
    },
    stripHtmlTags(html) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    },
    async fetchRelatedItems() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/related/giftcard/${this.$route.params.id}`);
        const { exploreMoreDeals, sponsoredDeals } = response.data;

        // Set Explore More and Sponsored Deals
        this.exploreMoreDeals = exploreMoreDeals;
        this.sponsoredDeals = sponsoredDeals;
      } catch (error) {
        console.error('Error fetching related gift cards:', error);
        // Set empty arrays if there's an error
        this.exploreMoreDeals = [];
        this.sponsoredDeals = [];
      }
    },
    async fetchSponsoredDeals() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/related/sponsored-giftcards/${this.$route.params.id}`);
        this.sponsoredDeals = response.data.sponsoredDeals;
      } catch (error) {
        console.error('Error fetching sponsored gift cards:', error);
        this.sponsoredDeals = [];
      }
    },
    prevRelatedItem() {
      if (this.relatedStartIndex > 0) {
        this.relatedStartIndex--;
        this.relatedEndIndex--;
      }
    },
    nextRelatedItem() {
      if (this.relatedEndIndex < this.relatedItems.length - 1) {
        this.relatedStartIndex++;
        this.relatedEndIndex++;
      }
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    calculateCustomerPays(option) {
      return (option.currentprice * (1 - option.discount / 100)).toFixed(2);
    },
    saveGiftCard() {
      let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      const exists = checkoutCartItems.some(item => item.giftCardId === this.$route.params.id);
      if (exists) {
        this.showMessage('Gift Card already in cart');
        return;
      }

      const newItem = {
        giftCardId: this.$route.params.id,
        dealTitle: this.giftCardDetails.dealtitle,
        originalPrice: this.option.currentprice,
        discount: this.option.discount,
        quantity: this.purchaseQuantity,
        businessId: this.giftCardDetails.business_id
      }; 

      if (!newItem.giftCardId || !newItem.dealTitle || !newItem.originalPrice || !newItem.discount || !newItem.quantity) {
        console.error('Invalid newItem:', newItem);
        return;
      } 

      if (this.isLoggedIn) {
        const userId = JSON.parse(localStorage.getItem('user')).id;
        const token = localStorage.getItem('token');
        axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/saveCart/${userId}`, newItem, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then((response) => {
            console.log('Response from server:', response.data);
            checkoutCartItems.push(newItem);
            localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
            window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
            this.showMessage('Gift Card Saved');
            this.checkIfInCart();
          })
          .catch((error) => {
            console.error('Error saving gift card:', error);
          });
      } else {
        checkoutCartItems.push(newItem);
        localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
        window.dispatchEvent(new CustomEvent('cart-updated', { detail: checkoutCartItems }));
        this.showMessage('Gift Card Added to Cart');
      }
    },
    checkIfInCart() {
      if (!this.giftCardDetails || !this.$route.params.id) {
        return;
      }
      const checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      this.isInCart = checkoutCartItems.some(item => item.giftCardId === this.$route.params.id);
      console.log('Is in cart:', this.isInCart);
    },
    addToCart() {
      this.saveGiftCard();
      this.checkIfInCart();
    },
    async buyNow() {
      await this.saveGiftCard();
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (!this.isLoggedIn) {
        this.$router.push('/login');
        return;
      }
      this.$router.push('/checkout')
    },
    showMessage(text) {
      this.message = text;
      this.fading = false;
      setTimeout(() => {
        this.fading = true;
        setTimeout(() => {
          this.message = '';
          this.fading = false;
        }, 1000); // Duration of the fade-out effect
      }, 2000); // Duration the message stays visible before fading out
    },
    subscribeToBusiness() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async submitSubscription() {
      this.showModal = false;

      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user || !user.id) {
        this.showMessage('Please log in to update your subscription preferences.');
        return;
      }

      const userId = user.id;
      const giftCardId = this.$route.params.id;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/update-notifications`, {
          giftCardId,
          userId,
          notifications: {
            email: this.subscription.email,
            notifications: this.subscription.notifications,
            text: this.subscription.text
          },
          isFan: true
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log("Subscription updated: ", response.data);

        // Update localStorage with new preferences
        const businessId = this.giftCardDetails.business_id;

        // Ensure notification_preferences exists in the user object
        if (!user.notification_preferences) {
          user.notification_preferences = {};
        }

        // Update the notification preferences for the specific business
        user.notification_preferences[businessId] = {
          email: this.subscription.email,
          text: this.subscription.text,
          notifications: this.subscription.notifications,
          isFan: true
        };

        // Update the user object in localStorage
        localStorage.setItem('user', JSON.stringify(user));
        this.isFan = true;

      } catch (error) {
        console.error('Error updating subscription preferences:', error);
      }
    },
    async unsubscribe() {
      this.showModal = false;

      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user || !user.id) {
        this.showMessage('Please log in to update your subscription preferences.');
        return;
      }

      const userId = user.id;
      const giftCardId = this.$route.params.id;

      try {
        const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/update-notifications/remove-preferences`, {
          giftCardId,
          userId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log("Unsubscribed: ", response.data);

        // Update localStorage to remove the business preferences
        const businessId = this.giftCardDetails.business_id;

        if (user.notification_preferences && user.notification_preferences[businessId]) {
          delete user.notification_preferences[businessId];
        }

        localStorage.setItem('user', JSON.stringify(user));
        this.isFan = false;

      } catch (error) {
        console.error('Error unsubscribing:', error);
      }
    },
    updateAverageRating() {
      if (this.reviews.length === 0) {
        this.averageRating = 0;
      } else {
        const total = this.reviews.reduce((sum, review) => sum + parseFloat(review.rating), 0);
        this.averageRating = (total / this.reviews.length).toFixed(1);
      }
    },
    addReview(newReview) {
      this.reviews.push(newReview);
      this.updateAverageRating();
    },
    checkNotificationPreferences() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.notification_preferences) {
        this.isFan = false;
        return;
      }

      const notificationPreferences = user.notification_preferences;
      const businessId = this.giftCardDetails.business_id;

      const userPreference = notificationPreferences[businessId];

      if (userPreference) {
        this.isFan = true;
        this.subscription.email = userPreference.email || false;
        this.subscription.text = userPreference.text || false;
        this.subscription.notifications = userPreference.notifications || false;
      } else {
        this.isFan = false;
      }
    },
    async removeFromCart() {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user ? user.id : null;

      if (userId) {
        try {
          await axios.put(`${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${userId}`, {
            giftCardId: this.$route.params.id,
          }, {
            headers: { 'Authorization': `Bearer ${this.token}` }
          });
        } catch (error) {
          console.error('Error removing from cart on server:', error);
        }
      }

      let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      checkoutCartItems = checkoutCartItems.filter(item => item.giftCardId !== this.$route.params.id);
      localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
      window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
      this.showMessage('Gift Card Removed from Cart');
      this.checkIfInCart();
    },
    async toggleCartItem() {
      if (this.isInCart) {
        await this.removeFromCart();
      } else {
        await this.addToCart();
      }
    },
  },
  async mounted() {
    await this.fetchGiftCardData();
    await this.fetchRelatedItems();
    await this.fetchSponsoredDeals();
    window.addEventListener('update-checkout-cart', this.checkIfInCart);
  },
  beforeUnmount() {
    window.removeEventListener('update-checkout-cart', this.checkIfInCart);
  },
};
</script>

<style scoped>
.step-preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  box-sizing: border-box;
}

.content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.deal-header{
  margin-left: 5%;
}

.deal-title {
  text-align: left;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.left-side,
.right-side {
  width: 50%;
  box-sizing: border-box;
}

.left-side {
  margin-right: 10%;
  margin-left: 5%
}

.right-side {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  align-self: flex-start;
}

.explore-more-section,
.sponsored-deals-section {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 50px;
}

.explore-more-section h2,
.sponsored-deals-section h2 {
  text-align: left;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.business-title{
  color: #757575;
}

.separator {
  width: 60%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
}

.text-separator{
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
}

.deals-grid-horizontal {
  display: flex;
  justify-content: flex-start;
  gap: 10px; /* Reduced gap */
  width: 100%;
  overflow: visible; /* Allow overflow to be visible */
}

.deals-grid-horizontal :deep(.gift-card) {
  flex: 0 0 calc(25% - 10px);
  max-width: calc(20% - 15px);
}



.section-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;  /* This creates space between the items */
}

.section-nav span {
  display: inline-block;
}

.section-nav a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.section-nav a:hover,
.section-nav a:focus {
  color: #000;
}

/* Ensure no default list styling is interfering */
.section-nav ul,
.section-nav li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.image-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 30px);
  box-sizing: border-box;
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
}

.image-preview {
  width: 109px;
  height: 109px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
}

.thumbnail-arrow {
  width: 10px;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 0px;
}

.fine-print {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 50px;
  box-sizing: border-box;
}

.about-deal {
  width: 100%;
  margin-top: 50px;
  box-sizing: border-box;
}

.option-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.option-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.option-label {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.option-pricing {
  display: flex;
  align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
}

.original-price {
  color: gray;
  margin-right: 10px;
  text-decoration: line-through;
  box-sizing: border-box;
}

.new-price {
  font-size: 18px;
  font-weight: bold;
  color: green;
  box-sizing: border-box;
}

.discount {
  background-color: #d4edda;
  color: green;
  padding: 3px 5px;
  border-radius: 4px;
  margin-left: 5px;
  box-sizing: border-box;
}

.option-stock {
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.option-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.option-quantity input[type="number"] {
  width: 50px;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.buy-now,
.add-to-cart,
.become-fan {
  width: 58%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.buy-now {
  background-color: #4C6B30;
  color: white;
}

.buy-now:hover {
  background-color: #6E9F49;
  cursor: pointer;
}

.add-to-cart {
  background-color: #6E9F49;
  color:white;
}

.add-to-cart:hover {
  background-color: #A3BF8F;
  cursor: pointer;
}

.become-fan {
  background-color: #8DB36D;
  color: white;
}

.become-fan:hover {
  background-color: #A3BF8F;
  cursor: pointer;
}

.share-section {
  margin-top: 20px;
  text-align: left;
  box-sizing: border-box;
}

.share-section h3 {
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.social-icons {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
}

.social-icon {
  font-size: 24px;
  color: #4C6B30;
  text-decoration: none;
  box-sizing: border-box;
}

.social-icon:hover {
  color: #6E9F49;
  box-sizing: border-box;
}

.ads-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 18%;
  box-sizing: border-box;
}

.ads-section h3 {
  margin-top: 20px;
  text-align: left;
  margin-left: 3.5%;
  box-sizing: border-box;
}

.ad-item {
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ad-item img {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.checkbox-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.checkbox-group div {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.submit-button,
.unsubscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
}

.submit-button {
  background-color: #4C6B30;
  color: white;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #6E9F49;
  box-sizing: border-box;
}

.unsubscribe-button {
  background-color: #f44336;
  color: white;
  box-sizing: border-box;
}

.unsubscribe-button:hover {
  background-color: #d32f2f;
  box-sizing: border-box;
}

.related-items {
  margin-top: 50px;
  box-sizing: border-box;
}

.related-slider {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.related-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.related-arrow.left {
  margin-right: 10px;
  box-sizing: border-box;
}

.related-arrow.right {
  margin-left: 10px;
  box-sizing: border-box;
}

.related-item-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.gift-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.gift-card img {
  max-width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
}

.gift-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.gift-card p {
  font-size: 1em;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.gift-card .button {
  background-color: #4C6B30;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1em;
  box-sizing: border-box;
}

.gift-card .button:hover {
  background-color: #6E9F49;
  box-sizing: border-box;
}

.mobile-options-list {
  display: none; /* Hide the mobile options list by default */
}

.ads-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 21%;
  box-sizing: border-box;
}

.ads-section h3 {
  margin-top: 20px;
  text-align: left;
  margin-left: 3.5%;
  box-sizing: border-box;
}

.ad-item {
  margin-bottom: 10px;
  box-sizing: border-box;
}

.deals-grid-horizontal :deep(.gift-card) {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.deals-grid-horizontal :deep(.gift-card:hover) {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1500px) {
  .left-side,
  .right-side {
    width: 48%;
    box-sizing: border-box;
  }
}

@media (max-width: 1200px) {
  .step-preview {
    padding: 0 20px;
    box-sizing: border-box;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  .left-side,
  .right-side {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .right-side {
    display: none;
    box-sizing: border-box;
  }

  .mobile-options-list {
    display: block; /* Show the mobile options list */
    padding: 50px 0;
    box-sizing: border-box;
    text-align: center;
  }

  .option-item{
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .button-container{
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .deal-title {
    margin-left: 0;
    text-align: center;
    box-sizing: border-box;
  }

  .about-deal, .fine-print{
    text-align:center;
  }

  .ads-section {
    margin-left: 0;
    box-sizing: border-box;
  }

  .options-list {
    display: none; /* Hide the desktop options list */
    box-sizing: border-box;
  }

  .related-item-cards {
    grid-template-columns: repeat(1, 1fr); /* Change to single column on mobile */
    box-sizing: border-box;
  }

  .customer-reviews {
    text-align: center;
    margin-top: 50px;
  }

  .deals-grid-horizontal {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .deals-grid-horizontal :deep(.gift-card) {
    flex: 0 0 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
}

@media (max-width: 750px) {
  .step-preview {
    padding: 0 10px;
    box-sizing: border-box;
  }

  .image-viewer {
    height: 300px;
    box-sizing: border-box;
  }

  .deals-grid-horizontal {
    flex-direction: column;
    align-items: center;
  }

  .deals-grid-horizontal :deep(.gift-card) {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(80% - 15px);
  }

  .image-preview {
    width: 80px;
    height: 80px;
    box-sizing: border-box;
  }

  .ad-item img {
    max-width: 50%;
    box-sizing: border-box;
  }

  .modal {
    width: 90%;
    padding: 15px;
    box-sizing: border-box;
  }

  .options-list {
    order: -1; /* Move options list above the content on mobile */
    box-sizing: border-box;
  }

  .about-deal {
    margin-top: 20px;
    box-sizing: border-box;
  }

  .related-item-cards {
    grid-template-columns: 1fr; /* Ensure single column on smaller screens */
    box-sizing: border-box;
  }

  .separator {
    width: 75%;
    box-sizing: border-box;
  }

  .buy-now,
  .add-to-cart,
  .become-fan {
    width: 75%;
    box-sizing: border-box;
  }

  .modal {
    max-width: 300px;
    box-sizing: border-box;
  }

  .section-nav {
    justify-content: center; /* Centers the nav items on mobile */
    text-align: center;      /* Ensures text is centered */
    gap: 15px;               /* Reduce the gap for better mobile fit */
  }

  .section-nav a {
    font-size: 16px;         /* Adjust font size for mobile */
  }
}
</style>

