<template>
  <div class="container">
    <div class="form-container">
      <h2 class="title">Forgot Password</h2>
      <form @submit.prevent="sendResetLink">
        <div class="input-group">
          <label for="email">E-mail Address</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <button type="submit" class="reset-button">Send Reset Link</button>
      </form>
      <div v-if="message" class="message">{{ message }}</div>
      <div class="login-redirect">
        <button @click="redirectToLogin" class="login-button">Back to Login</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      message: '' // Add a new data property for the message
    };
  },
  methods: {
    async sendResetLink() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/forgot-password`, {
          email: this.email,
        });
        this.message = 'Password reset link has been sent to your email.'; // Update the message
      } catch (error) {
        console.error('Error sending reset link', error);
        this.message = 'Error sending reset link. Please try again.'; // Update the message
      }
    },
    redirectToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin-top: 50px;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.reset-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #4C6B30;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #6E9F49;
}

.message {
  margin-top: 1rem;
  color: #4C6B30; /* Dark Green for success message */
  font-size: 1rem;
  text-align: center;
}

.login-redirect {
  margin-top: 1rem;
}

.login-button {
  background: none;
  border: none;
  color: #4C6B30;
  cursor: pointer;
  font-size: 1rem;
}

.login-button:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .form-container {
    padding: 1.5rem; /* Reduce padding inside the form container */
  }

  .title {
    font-size: 1.25rem; /* Adjust font size for better readability on small screens */
  }

  .input-group input {
    padding: 0.4rem; /* Adjust padding for input fields */
  }

  .reset-button {
    padding: 0.6rem; /* Adjust padding for the reset button */
    font-size: 0.9rem; /* Adjust font size for the reset button */
  }

  .login-button {
    font-size: 0.9rem; /* Adjust font size for the login button */
  }
}

@media (max-width: 480px) {
  .container {
    padding: 5px; /* Further reduce padding for very small screens */
  }

  .form-container {
    padding: 1rem; /* Further reduce padding inside the form container */
    width: 300px;
  }

  .title {
    font-size: 1rem; /* Further adjust font size for better readability on very small screens */
  }

  .input-group input {
    padding: 0.3rem; /* Further adjust padding for input fields */
  }

  .reset-button {
    padding: 0.5rem; /* Further adjust padding for the reset button */
    font-size: 0.8rem; /* Further adjust font size for the reset button */
  }

  .login-button {
    font-size: 0.8rem; /* Further adjust font size for the login button */
  }
}
</style>
