<template>
  <div class="dashboard-page">
    <div class="dashboard-content" :class="{ 'loading': isLoading }">
      <h1>Edit Gift Card</h1>
      <div class="steps-container">
        <StepIndicator :currentStep="currentStep" />
        <component
          ref="currentStepComponent"
          :is="currentStepComponent"
          @nextStep="nextStep"
          @formValidation="updateFormValidation"
          :currentStep="currentStep"
        />
        <div class="navigation-buttons">
          <button @click="prevStep" :disabled="currentStep === 0">Previous</button>
          <button @click="cancel">Cancel</button>
          <button @click="handleNextStep" :disabled="!isFormValid">{{ currentStep === steps.length - 1 ? 'Update' : 'Next' }}</button>
        </div>
      </div>
      <div v-if="showPublishConfirm" class="modal">
        <div class="modal-content">
          <h2>Confirm Update</h2>
          <p>Are you sure you want to save?</p>
          <div class="modal-actions">
            <button @click="save" :disabled="isLoading">Yes</button>
            <button @click="cancelSave" :disabled="isLoading">No</button>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="loading-overlay">
        <div class="loader"></div>
        <p>Saving, do not exit or refresh this screen...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import StepIndicator from '../../components/Dashboard/edit giftcard/StepIndicator.vue';
import StepDetails from '../../components/Dashboard/edit giftcard/StepDetails.vue';
import StepOptions from '../../components/Dashboard/edit giftcard/StepOptions.vue';
import StepImages from '../../components/Dashboard/edit giftcard/StepImages.vue';
import StepPreview from '../../components/Dashboard/edit giftcard/StepPreview.vue';

export default {
  name: 'EditGiftCard',
  components: {
    StepIndicator,
    StepDetails,
    StepOptions,
    StepImages,
    StepPreview,
  },
  data() {
    return {
      currentStep: 0,
      steps: ['StepDetails', 'StepOptions', 'StepImages', 'StepPreview'],
      isFormValid: false,
      showPublishConfirm: false,
      giftCardId: null,
      token: localStorage.getItem('token'),
      isLoading: false,
    };
  },
  computed: {
    currentStepComponent() {
      return this.steps[this.currentStep];
    },
  },
  methods: {
    updateFormValidation(isValid) {
      this.isFormValid = isValid;
    },
    async handleNextStep() {
      const currentComponent = this.$refs.currentStepComponent;

      if (this.isFormValid) {
        if (this.currentStep === 0 && currentComponent.validateAndSave) {
          currentComponent.validateAndSave();
        } else if (this.currentStep === 1 && currentComponent.handleNextStep) {
          currentComponent.handleNextStep();
        } else if (this.currentStep === 2 && currentComponent.saveImages) {
          currentComponent.saveImages();
          this.nextStep();
        } else if (this.currentStep === this.steps.length - 1) {
          this.showPublishConfirm = true;
        } else {
          this.nextStep();
        }
      }
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        localStorage.setItem('currentStep', this.currentStep);
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        localStorage.setItem('currentStep', this.currentStep);
      }
    },
    async save() {
      this.isLoading = true;
      this.showPublishConfirm = false;

      const dealDetails = JSON.parse(localStorage.getItem('giftCardDetails')) || {};
      const uploadedImages = JSON.parse(localStorage.getItem('uploadedImagesGC')) || [];
      const options = JSON.parse(localStorage.getItem('option')) || {};
      const codes = JSON.parse(localStorage.getItem('generatedCodes')) || [];
      const currentBusiness = JSON.parse(localStorage.getItem('currentBusiness'));
      const giftCardId = JSON.parse(localStorage.getItem('giftCardId'));

      const {
        dealName,
        currentPrice,
        discount,
        maxPerPurchase,
        exactDate,
        numberInStock,
        releaseAmount,
        releaseFrequency,
      } = options;

      const formData = new FormData();
      formData.append('category', dealDetails.category || 'None');
      formData.append('dealDetails', dealDetails.dealDetails || 'None');
      formData.append('dealTitle', dealDetails.dealTitle || 'None');
      formData.append('currentPrice', currentPrice || 0);
      formData.append('dealName', dealName || 'None');
      formData.append('discount', discount || 0);
      formData.append('exactDate', exactDate || '1970-01-01');
      formData.append('maxPerPurchase', maxPerPurchase || 0);
      formData.append('dealFinePrint', dealDetails.dealFinePrint || 'None');
      formData.append('numberInStock', numberInStock || 0);
      formData.append('releaseAmount', releaseAmount || 0);
      formData.append('releaseFrequency', releaseFrequency || '');
      formData.append('istrending', 0);
      formData.append('ispublished', 1);
      formData.append('business_id', currentBusiness);
      formData.append('giftCardCodes', JSON.stringify(codes));

      const existingImages = [];
      uploadedImages.forEach((image) => {
        if (typeof image.url === 'string' && image.url.startsWith('https')) {
          existingImages.push(image.url);
        } else if (image.url) {
          const blob = this.dataURLtoBlob(image.url);
          formData.append('giftCard_image', blob, image.name);
        }
      });

      formData.append('existing_images', JSON.stringify(existingImages));

      try {
        const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/create-giftCard/update/${giftCardId}`, formData, {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('API response:', response.data);

        localStorage.removeItem('editDetails');
        localStorage.removeItem('giftCardDetails');
        localStorage.removeItem('uploadedImagesGC');
        localStorage.removeItem('option');
        localStorage.removeItem('generatedCodes');
        localStorage.removeItem('currentBusiness');
        localStorage.removeItem('dataFetched');
        localStorage.removeItem('giftCardId');
        localStorage.removeItem('currentStep');

        // Reload the page after saving
        this.$router.push('/dashboard/view-gift-cards');
      } catch (error) {
        console.error('Error saving gift card:', error);
      } finally {
        this.isLoading = false;
      }
    },
    cancelSave() {
      this.showPublishConfirm = false;
    },
    dataURLtoBlob(dataURL) {
      if (!dataURL) return null;
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    cancel() {
      localStorage.removeItem('editDetails');
      localStorage.removeItem('uploadedImagesGC');
      localStorage.removeItem('giftCardDetails');
      localStorage.removeItem('option');
      localStorage.removeItem('generatedCodes');
      localStorage.setItem('currentStep', 0);
      this.currentStep = 0;
      this.$router.push('/dashboard/view-giftcards');
    },
    async fetchGiftCard() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/giftcards/${this.giftCardId}`);
        const data = response.data;
        localStorage.setItem('editDetails', JSON.stringify(data));
        localStorage.setItem('uploadedImagesGC', JSON.stringify(data.image_links));
        localStorage.setItem('giftCardDetails', JSON.stringify(data.details));
        localStorage.setItem('option', JSON.stringify(data.options));
        localStorage.setItem('generatedCodes', JSON.stringify(data.codes));
        localStorage.setItem('giftCardId', this.giftCardId);
        if (localStorage.getItem('dataFetched')) {
          localStorage.setItem('dataFetched', 'false');
          location.reload();
        }
      } catch (error) {
        console.error('Error fetching gift card:', error);
      }
    },
  },
  mounted() {
    const savedStep = localStorage.getItem('currentStep');
    if (savedStep !== null) {
      this.currentStep = parseInt(savedStep, 10);
    }
    this.giftCardId = this.$route.params.id;

    // Check if data has already been fetched
    const dataFetched = localStorage.getItem('dataFetched');
    if (!dataFetched) {
      // If not, fetch the data and set the flag
      this.fetchGiftCard();
      localStorage.setItem('dataFetched', 'true');
    }
  },
};
</script>

<style scoped>
.dashboard-content {
  margin-left: 300px;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
}

.dashboard-content h1 {
  margin-top: 0;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 500px;
  max-width: 90%;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4C6B30;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dashboard-content.loading::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 250px;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 200px;
    padding: 15px;
    margin-top: 50px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    margin-left: 0;
    padding: 10px;
  }

  .modal-content {
    width: 90%;
  }
}
</style>