<template>
  <div class="header-container">
    <!-- Middle Section with Logo, Search, Cart, and Notification Bell -->
    <div class="middle-section">
      <button class="hamburger" @click="toggleMobileMenu">☰</button>
      <img src="@/assets/logo.png" alt="Logo" class="navbar-logo" />
      <div :class="['navbar', { 'mobile-menu': mobileMenuOpen }]">
      <button class="close-menu" @click="toggleMobileMenu">&times;</button>
      <router-link to="/" exact class="nav-link" exact-active-class="active" @click="closeMobileMenu">Home</router-link>
      <router-link to="/coupons" class="nav-link" active-class="active" @click="closeMobileMenu">Coupons</router-link>
      <router-link to="/gift-cards" class="nav-link" active-class="active" @click="closeMobileMenu">Gift Cards</router-link>
      <router-link v-if="user && user.id" :to="`/account/${user.id}/shopping-bag`" class="nav-link" active-class="active" @click="closeMobileMenu">Coupon Bag</router-link>
      <router-link to="/contact" class="nav-link" active-class="active" @click="closeMobileMenu">Contact Us</router-link>
      <router-link v-if="authState.isBusiness || authState.isAdmin" to="/dashboard" class="nav-link" active-class="active" @click="closeMobileMenu">Dashboard</router-link>
        
        <!-- Auth links for mobile -->
        <div class="auth-links mobile-auth">
        <router-link v-if="!authState.user" to="/login" class="nav-link" exact-active-class="active" @click="closeMobileMenu">Login</router-link>
        <router-link v-if="!authState.user" to="/register" class="nav-link" exact-active-class="active" @click="closeMobileMenu">Register</router-link>
        <div v-if="authState.user" class="user-info">
          <router-link :to="`/account/${authState.user.id}/orders`" class="nav-link" exact-active-class="active" @click="closeMobileMenu">Account</router-link>
          <button @click="logoutAndCloseMobileMenu" class="logout-button">Log Out</button>
          </div>
        </div>
      </div>
      <div class="search-and-cart">
        <!-- Auth Links -->
        <div class="auth-links desktop-auth">
          <router-link v-if="!authState.user" to="/login" class="nav-link" exact-active-class="active">Login</router-link>
          <router-link v-if="!authState.user" to="/register" class="nav-link" exact-active-class="active">Register</router-link>
          <div v-if="authState.user" class="user-info">
            <span class="welcome-message">Hi, {{ authState.user.fullName }}</span>
            <router-link :to="`/account/${authState.user.id}/orders`" class="nav-link" exact-active-class="active">Account</router-link>
            <button @click="logout" class="logout-button">Log Out</button>
          </div>
        </div>
        <div class="cart-container">
          <div class="cart-icon" @mouseover="showDropdown('notifications')" @mouseleave="hideDropdown('notifications')">
            <img src="https://img.icons8.com/ios-glyphs/30/1A3819/filled-appointment-reminders.png" alt="Notifications"/>
            <span class="item-count">{{ notifications.length }}</span>
            <div class="notification-dropdown" v-if="dropdownVisible.notifications">
              <div v-if="notifications.length > 0">
                <div class="cart-item" v-for="(notification, index) in notifications.slice(0, 10)" :key="index">
                  <div class="cart-item-details">
                    <span class="cart-item-name">{{ notification.title }}</span>
                    <span class="cart-item-deal">{{ stripHtmlTags(notification.messageBody) }}</span>
                    <button class="remove-button" @click="removeNotification(notification.id)">×</button>
                  </div>
                </div>
                <button class="view-notifications-button" @click="viewNotifications">View All Notifications</button>
              </div>
              <div v-else class="empty-cart">You have no notifications.</div>
            </div>
          </div>
          <div class="cart-icon" @mouseover="showDropdown('shoppingBag')" @mouseleave="hideDropdown('shoppingBag')">
            <img src="https://img.icons8.com/ios-glyphs/30/1A3819/shopping-bag.png" alt="Shopping Bag"/>
            <span class="item-count">{{ shoppingBagItems.length }}</span>
            <div class="coupon-bag-dropdown" v-if="dropdownVisible.shoppingBag">
              <div v-if="shoppingBagItems.length > 0">
                <div class="cart-item" v-for="(item, index) in shoppingBagItems.slice(0, 10)" :key="index">
                  <button class="remove-button" @click="removeFromShoppingBag(item.couponId, item.name)">×</button>
                  <div class="cart-item-details" @click="navigateToCoupon(item.couponId)">
                    <span class="cart-item-name">{{ item.name }}</span>
                    <span class="cart-item-deal">
                      <div v-if="item.dealDetails.discounttype === 'percentageAmount'">
                        {{ item.dealDetails.discount }}% off if you spend ${{ item.dealDetails.spendamount }}
                      </div>
                      <div v-else-if="item.dealDetails.discounttype === 'amountOff'">
                        ${{ item.dealDetails.regularprice }} off
                      </div>
                      <div v-else-if="item.dealDetails.discounttype === 'buyOneGetOnePercentage'">
                        {{ item.dealDetails.discount }}% Off
                      </div>
                      <div v-else-if="item.dealDetails.discounttype === 'buyOneGetOneAmount'">
                        ${{ item.dealDetails.discount }} Off
                      </div>
                      <div v-else-if="item.dealDetails.discounttype === 'buyOneGetOneFree'">
                        Buy 1 Get 1 Free
                      </div>
                    </span>
                    <div class="cart-item-code">
                      {{ item.couponCode }}
                      <button class="copy-button" @click.stop="copyCode(item.couponCode)">Copy</button>
                    </div>
                  </div>
                </div>
                <button class="view-shopping-bag-button" @click="viewShoppingBag">View Coupon Bag</button>
              </div>
              <div v-else class="empty-cart">Your coupon bag is empty.</div>
            </div>
          </div>
          <div class="cart-icon" @mouseover="showDropdown('checkoutCart')" @mouseleave="hideDropdown('checkoutCart')">
            <img src="https://img.icons8.com/ios-glyphs/30/1A3819/shopping-cart.png" alt="Cart"/>
            <span class="item-count">{{ checkoutCartItems.length }}</span>
            <div class="cart-dropdown" v-if="dropdownVisible.checkoutCart">
              <div v-if="checkoutCartItems.length > 0">
                <div class="cart-item" v-for="(item, index) in checkoutCartItems.slice(0, 10)" :key="index">
                  <button class="remove-button" @click="removeFromCheckoutCart(item.giftCardId)">×</button>
                  <div class="cart-item-details" @click="navigateToCard(item.giftCardId)">
                    <span class="cart-item-name">{{ item.dealTitle }}</span>
                    <div class="cart-item-price-details">
                      <span class="original-price">${{ item.originalPrice }}</span>
                      <span class="discounted-price">${{ calculateDiscountedPrice(item.originalPrice, item.discount) }}</span>
                      <span class="discount">{{ item.discount }}% OFF</span>
                    </div>
                  </div>
                </div>
                <div class="cart-total">
                  <span>Total:</span>
                  <span>${{ totalCost }}</span>
                </div>
                <button class="view-cart-button" @click="viewCart">View Cart</button>
              </div>
              <div v-else class="empty-cart">Your cart is empty.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-menu-overlay" :class="{ active: mobileMenuOpen }" @click="toggleMobileMenu"></div>
  </div>
</template>

<script>
import axios from 'axios';
import { reactive, onMounted, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import { authState, setUser } from '../../services/auth';

export default {
  name: 'NavBar',
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
    };
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      shoppingBagItems: JSON.parse(localStorage.getItem('shoppingBagItems')) || [],
      checkoutCartItems: JSON.parse(localStorage.getItem('checkoutCartItems')) || [],
      notifications: [], // State for notifications
      dropdownVisible: {
        shoppingBag: false,
        checkoutCart: false,
        notifications: false, // State for notification dropdown visibility
      },
      hideTimeouts: {
        shoppingBag: null,
        checkoutCart: null,
        notifications: null, // Timeout for hiding notifications dropdown
      },
      mobileMenuOpen: false,
    });

    const logout = () => {
      setUser(null);
      window.location.href = `/`;
    };

    const showDropdown = (cartType) => {
      clearTimeout(state.hideTimeouts[cartType]);
      hideAllDropdowns();
      state.dropdownVisible[cartType] = true;
    };

    const hideDropdown = (cartType) => {
      state.hideTimeouts[cartType] = setTimeout(() => {
        state.dropdownVisible[cartType] = false;
      }, 300);
    };

    const hideAllDropdowns = () => {
      state.dropdownVisible.shoppingBag = false;
      state.dropdownVisible.checkoutCart = false;
      state.dropdownVisible.notifications = false; // Hide notifications dropdown
    };

    const updateShoppingBag = (items) => {
      state.shoppingBagItems = items;
      localStorage.setItem('shoppingBagItems', JSON.stringify(items));
    };

    const updateCheckoutCart = (items) => {
      state.checkoutCartItems = items;
      localStorage.setItem('checkoutCartItems', JSON.stringify(items));
    };

    const copyCode = (code) => {
      navigator.clipboard.writeText(code).then(() => {
        alert('Coupon code copied!');
      });
    };

    const removeFromShoppingBag = (couponId, name) => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) return;
      const token = localStorage.getItem('token');
        axios.put(
          `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-shopping-bag/${user.id}`,
          { couponId, name },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        )
        .then(() => {
          let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
          shoppingBagItems = shoppingBagItems.filter(item => item.couponId !== couponId || item.name !== name);
          updateShoppingBag(shoppingBagItems);

          const event = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
          window.dispatchEvent(event);
        })
        .catch((error) => {
          console.error('Error removing from shopping bag:', error);
        });
    };

    const removeFromCheckoutCart = (giftCardId) => {
      const user = JSON.parse(localStorage.getItem('user'));
      
      // If user is not logged in, remove the item from localStorage directly
      if (!user || !user.id) {
        let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
        checkoutCartItems = checkoutCartItems.filter(item => item.giftCardId !== giftCardId);
        updateCheckoutCart(checkoutCartItems);

        const event = new CustomEvent('update-checkout-cart', { detail: checkoutCartItems });
        window.dispatchEvent(event);
        return;
      }

      // If user is logged in, send a request to the API to remove the item
      const token = localStorage.getItem('token');
      axios.put(
        `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${user.id}`,
        { giftCardId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      )
      .then(() => {
        let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
        checkoutCartItems = checkoutCartItems.filter(item => item.giftCardId !== giftCardId);
        updateCheckoutCart(checkoutCartItems);

        const event = new CustomEvent('update-checkout-cart', { detail: checkoutCartItems });
        window.dispatchEvent(event);
      })
      .catch((error) => {
        console.error('Error removing from checkout cart:', error);
      });
    };

    const calculateDiscountedPrice = (originalPrice, discount) => {
      return (originalPrice * (1 - discount / 100)).toFixed(2);
    };

    const totalCost = computed(() => {
      return state.checkoutCartItems.reduce((total, item) => total + parseFloat(calculateDiscountedPrice(item.originalPrice, item.discount)), 0).toFixed(2);
    });

    const navigateToCoupon = (couponId) => {
      router.push(`/coupon/${couponId}`);
    };

    const navigateToCard = (giftCardId) => {
      router.push(`/gift-card/${giftCardId}`);
    };

    const viewShoppingBag = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      router.push(`/account/${user.id}/shopping-bag`);
    };

    const viewCart = () => {
      router.push('/cart');
    };

    const viewNotifications = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      router.push(`/account/${user.id}/notifications`);
    };

    const fetchNotifications = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      if (user && user.id && token) {
        axios.get(`${process.env.VUE_APP_API_URL}/api/notifications/user-notifications`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            userId: user.id,
          },
        })
        .then((response) => {
          state.notifications = response.data.notifications;
        })
        .catch((error) => {
          console.error('Error fetching notifications:', error);
        });
      }
    };

    const removeNotification = async (notificationId) => {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      if (user && user.id && token) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/notifications/remove-notification`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            params: {
              userId: user.id,
              notificationId: notificationId,
            },
          });
          state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
          fetchNotifications(); // Refresh the notifications
        } catch (error) {
          console.error('Error removing notification:', error);
        }
      }
    };

    const stripHtmlTags = (html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    };

    onMounted(() => {
      window.addEventListener('update-shopping-bag', (event) => {
        updateShoppingBag(event.detail);
      });
      window.addEventListener('update-checkout-cart', (event) => {
        updateCheckoutCart(event.detail);
      });

      fetchNotifications();

      window.addEventListener('notification-removed', (event) => {
        const notificationId = event.detail;
        state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
      });
    });

    const toggleMobileMenu = () => {
      state.mobileMenuOpen = !state.mobileMenuOpen;
      document.body.style.overflow = state.mobileMenuOpen ? 'hidden' : '';
    };

    const closeMobileMenu = () => {
      if (window.innerWidth <= 768) {
        state.mobileMenuOpen = false;
        document.body.style.overflow = '';
      }
    };

    const logoutAndCloseMobileMenu = () => {
      logout();
      closeMobileMenu();
    };

    return {
      authState: reactive(authState),
      ...toRefs(state),
      logout,
      showDropdown,
      hideDropdown,
      hideAllDropdowns,
      copyCode,
      removeFromShoppingBag,
      removeFromCheckoutCart,
      calculateDiscountedPrice,
      logoutAndCloseMobileMenu,
      closeMobileMenu,
      totalCost,
      navigateToCoupon,
      navigateToCard,
      viewShoppingBag,
      viewCart,
      viewNotifications,
      toggleMobileMenu,
      stripHtmlTags,
      removeNotification,
    };
  },
};
</script>

<style scoped>
.header-container {
  font-family: 'Open Sans', sans-serif;
}

.auth-links {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.auth-link {
  color: #333;
  margin: 0 1rem;
  text-decoration: none;
}

.user-info {
  display: flex;
  align-items: center;
}

.welcome-message {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.logout-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1rem;
  text-decoration: underline;
}

.middle-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  flex-wrap: wrap;
}

.navbar-logo {
  height: 200px;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 0 2rem;
  flex-wrap: wrap;
}

.nav-link {
  color: #333;
  margin: 0 1rem;
  text-decoration: none;
  font-weight: bold;
}

.nav-link.active,
.nav-link:hover {
  color: #A3BF8F;
}

.search-and-cart {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.cart-container {
  display: flex;
  align-items: center;
}

.cart-icon {
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.item-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #1A3819;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.cart-dropdown,
.notification-dropdown,
.coupon-bag-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: left;
  margin-left: 10px;
}

.cart-item-name {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 5px;
}

.cart-item-price-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.discounted-price {
  color: #4caf50;
  font-weight: bold;
}

.discount {
  background-color: #d4edda;
  color: #155724;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.cart-item-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: #333;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-weight: bold;
  font-size: 16px;
  border-top: 1px solid #eee;
  margin-top: 10px;
}

.empty-cart {
  text-align: center;
  color: #999;
}

.view-shopping-bag-button,
.view-cart-button,
.view-notifications-button {
  width: 100%;
  padding: 10px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.copy-button {
  padding: 2px 5px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 5px;
}

.remove-button {
  width: 15px;
  height: 15px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
}

.hamburger {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-menu {
  display: none;
  flex-direction: column;
  width: 100%;
}

.mobile-menu .nav-link {
  margin: 0.5rem 0;
}

@media (max-width: 1550px) {
  .middle-section {
    flex-direction: column;
    align-items: center;
  }
  .navbar-logo {
    height: 150px;
    margin-bottom: 1rem;
  }
  .navbar,
  .search-and-cart,
  .auth-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
  }
  .nav-link,
  .auth-link,
  .logout-button,
  .welcome-message {
    margin: 0.5rem;
    font-size: 0.875rem;
    text-align: center;
  }
  .user-info {
    gap: 10px;
    margin-bottom: 1rem;
  }
  .cart-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

@media (max-width: 1024px) {
  .navbar-logo {
    height: 150px;
  }
  .navbar,
  .search-and-cart,
  .auth-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
  }
  .nav-link,
  .auth-link,
  .logout-button,
  .welcome-message {
    margin: 0.5rem;
    font-size: 0.875rem;
    text-align: center;
  }
  .user-info {
    gap: 10px;
    margin-bottom: 1rem;
  }
  .cart-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .top-bar,
  .middle-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  .navbar-logo {
    height: 40px;
    margin: 0;
  }

  .hamburger {
    display: block;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    order: -1;
    padding: 0.5rem;
  }

  .navbar {
    display: none;
  }

  .navbar.mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    height: 100vh;
    background-color: white;
    z-index: 1001;
    padding: 1rem;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    margin: 0;
  
  }

  .close-menu {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 2.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    padding: 0.5rem;
    line-height: 1;
  }

  .navbar.mobile-menu .nav-link.active {
    color: #A3BF8F;
    font-weight: bold;
    background-color: rgba(163, 191, 143, 0.1);
  }

  .navbar.mobile-menu.active {
    transform: translateX(0); /* Slide in from the right */
  }

  .mobile-menu .nav-link,
  .mobile-menu .auth-link,
  .mobile-menu .logout-button {
    font-size: 1rem;
    margin: 0.5rem 0;
    padding: 0.5rem;
    text-align: left;
    color: #333;
    text-decoration: none;
    border-bottom: 1px solid #eee;
    width: 100%;
  }

  .navbar.mobile-menu > *:first-child {
    margin-top: 3rem;
  }

  .mobile-menu .nav-link:hover,
  .mobile-menu .auth-link:hover {
    background-color: rgba(163, 191, 143, 0.05);
  }

  .mobile-menu .welcome-message {
    font-size: 1rem;
    margin: 1rem 0;
    padding: 0.5rem 0;
    color: #333;
  }

  .mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .mobile-menu-overlay.active {
    display: block;
  }
  

  .search-and-cart {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
  }

  .cart-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0;
  }

  .cart-icon {
    position: relative;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .cart-icon img {
    width: 24px;
    height: 24px;
  }

  .item-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #1A3819;
    color: white;
    border-radius: 50%;
    padding: 2px;
    font-size: 10px;
    min-width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .auth-links {
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
  }

  .auth-links.desktop-auth {
    display: none;
  }

  .auth-links.mobile-auth {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    width: 100%;
  }

  .user-info {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    margin-bottom: 1rem;
  }

  .welcome-message {
    font-size: 0.875rem;
    margin: 0.5rem 0;
    text-align: left;
  }

  .logout-button {
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: underline;
    padding: 0;
    margin: 0.5rem 0;
  }

  .cart-dropdown,
  .notification-dropdown,
  .coupon-bag-dropdown {
    width: 280px;
    right: -10px;
    top: 35px;
  }

  .cart-dropdown {
    transform: translateX(1.5%);
  }

  .notification-dropdown {
    transform: translateX(33%);
  }

  .coupon-bag-dropdown {
    transform: translateX(17%);
  }

  .nav-link,
  .auth-link {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .navbar-logo {
    height: 120px;
  }
  .navbar,
  .search-and-cart,
  .auth-links {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  .nav-link,
  .auth-link,
  .logout-button,
  .welcome-message {
    font-size: 0.75rem;
    margin: 0.5rem 0;
  }
  .user-info {
    gap: 10px;
  }
  .cart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
}

@media (min-width: 769px) {
  .hamburger, .close-menu {
    display: none;
  }

  .auth-links.mobile-auth {
    display: none;
  }
}
</style>