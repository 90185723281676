<template>
  <div class="step-indicator">
    <div class="step-line">
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="['step', { active: index === currentStep, completed: index < currentStep }]"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepIndicator',
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      steps: ['Details', 'Options', 'Images', 'Preview/Pay'],
    };
  },
};
</script>

<style scoped>
.step-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  width: calc(100% - 320px); /* Adjust width considering the sidebar */
}

.step-line {
  display: flex;
  justify-content: space-between; /* Distribute dots evenly */
  align-items: center;
  width: 100%;
  position: relative;
}

.step {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #6E9F49;
  position: relative;
  z-index: 1;
}

.step.active {
  background-color: #6E9F49;
}

.step.completed {
  background-color: #4C6B30;
}

.step-line::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #6E9F49;
  z-index: 0;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .step-indicator {
    width: calc(100% - 200px); /* Adjust width considering a smaller sidebar */
  }

  .step {
    width: 18px;
    height: 18px;
  }

  .step-line::before {
    height: 1.5px;
  }
}

@media (max-width: 768px) {
  .step-indicator {
    width: calc(100% - 100px); /* Adjust width considering an even smaller sidebar */
  }

  .step {
    width: 16px;
    height: 16px;
  }

  .step-line::before {
    height: 1.2px;
  }
}

@media (max-width: 480px) {
  .step-indicator {
    width: 100%; /* Full width for mobile view */
  }

  .step {
    width: 14px;
    height: 14px;
  }

  .step-line::before {
    height: 1px;
  }
}
</style>
