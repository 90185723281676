<template>
    <div>
      <h1>HOME</h1>
      <ul>
        <li v-for="item in items" :key="item.coupoun_id">{{ item }}</li>
      </ul>
    </div>
  </template>
  
  <script>
  import ApiService from '../../services/Api';
  
  export default {
    data() {
      return {
        items: []
      };
    },
    mounted() {
      ApiService.getData().then(response => {
        this.items = response.data;
      }).catch(error => {
        console.error("There was an error:", error.response);
      });
    }
  }
  </script>

<style>

</style>
