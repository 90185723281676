<template>
  <div class="shopping-bag-page">
    <CategoryFilter 
      :categories="giftCardCategories" 
      @category-selected="filterGiftCards" 
      :default-category="'all'" 
    />
    <div class="coupon-list">
      <CouponCard
        v-for="coupon in filteredCoupons"
        :key="coupon.couponId"
        :couponId="coupon.couponId"
        :dealTitle="coupon.name"
        :couponCode="coupon.couponCode"
        :couponImage="coupon.couponImage"
        :rating="coupon.rating"
        :dealDetails="coupon.dealDetails"
        :dealtype="coupon.dealtype"
        @remove-coupon="handleCouponRemoval"
        ref="couponCards"
      />
    </div>
  </div>
</template>

<script>
import CouponCard from '@/components/Coupon Components/CouponCard.vue';
import CategoryFilter from '@/components/Frontend Nav Components/CategoryFilterBar.vue';

export default {
  name: 'ShoppingBag',
  components: {
    CouponCard,
    CategoryFilter
  },
  data() {
    return {
      displayedCoupons: JSON.parse(localStorage.getItem('shoppingBagItems')) || [],
      giftCardCategories: [
        { id: 'all', name: 'All' },
        { id: 'Restaurants', name: 'Restaurants' },
        { id: 'Beauty-spa', name: 'Beauty & Spas' },
        { id: 'Things-to-do', name: 'Things to do' },
        { id: 'Health-fitness', name: 'Health & Fitness' },
        { id: 'Automotive', name: 'Automotive' },
        { id: 'Home-services', name: 'Home Services' },
        { id: 'Retail', name: 'Retail' },
        { id: 'Technology', name: 'Technology' },
        { id: 'Other', name: 'Other' }
      ],
      selectedCategory: 'all' // track selected category
    };
  },
  computed: {
    filteredCoupons() {
      // If the selected category is 'all', return all coupons
      if (this.selectedCategory === 'all') {
        return this.displayedCoupons;
      }
      // Otherwise, filter coupons based on the selected category
      return this.displayedCoupons.filter(coupon => coupon.category === this.selectedCategory);
    }
  },
  methods: {
    processDiscount(coupon) {
      const { discount, regularprice, spendamount, discounttype } = coupon;
      const isRegularPriceValid = regularprice && regularprice !== 0;
      const isSpendAmountValid = spendamount && spendamount !== 0;

      return {
        discount,
        regularprice: isRegularPriceValid ? regularprice : null,
        spendamount: isSpendAmountValid ? spendamount : null,
        discounttype
      };
    },
    getUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.id : null;
    },
    handleCouponRemoval(couponId) {
      this.displayedCoupons = this.displayedCoupons.filter(coupon => coupon.couponId !== couponId);
      localStorage.setItem('shoppingBagItems', JSON.stringify(this.displayedCoupons));
    },
    filterGiftCards(category) {
      this.selectedCategory = category.id; // Set the selected category based on user selection
    },
  },
  mounted() {
    // Ensure displayedCoupons have the processed discount details
    this.displayedCoupons = this.displayedCoupons.map(coupon => ({
      ...coupon,
      dealDetails: this.processDiscount(coupon)
    }));
  }
};
</script>

<style scoped>
.shopping-bag-page {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.coupon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 20px;
}

@media (max-width: 768px) {
  .coupon-list {
    flex-direction: column;
    align-items: center;
  }

  .coupon-list > * {
    width: 100%;
    max-width: 500px; /* Set a max-width to ensure the items don't stretch too much */
  }
}

@media (max-width: 480px) {
  .shopping-bag-page {
    padding: 10px;
  }

  .coupon-list {
    gap: 10px;
  }

  .coupon-list > * {
    width: 100%;
    max-width: 300px; /* Further reduce max-width for very small screens */
  }
}
</style>
