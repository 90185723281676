import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js'; // Import the router
import axios from 'axios';

const app = createApp(App);

// Set up Axios interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403) {
      // Token expired or not valid
      localStorage.removeItem('user');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('isBusiness');
      localStorage.removeItem('token');
      localStorage.removeItem('shoppingBagItems');
      localStorage.removeItem('checkoutCartItems');
      localStorage.removeItem('billingDetails')
      location.reload();
      router.push('/login'); // Redirect to login page
    }
    return Promise.reject(error);
  }
);

app.use(router).mount('#app'); // Use the router in your app
