<template>
  <div class="step-details">
    <h2>Step 1: Details</h2>
    <div class="form-group">
      <label for="dealTitle">Deal Title</label>
      <input type="text" id="dealTitle" v-model="dealtitle" @input="validateForm" required />
    </div>
    <div class="form-group">
      <label for="category">Category</label>
      <select id="category" v-model="category" @change="validateForm" required>
        <option disabled value="">Choose one</option>
        <option value="Restaurants">Restaurants</option>
        <option value="Beauty-spa">Beauty & Spa</option>
        <option value="Things-to-do">Things to do</option>
        <option value="Health-fitness">Health & Fitness</option>
        <option value="Automotive">Automotive</option>
        <option value="Home-services">Home Services</option>
        <option value="Retail">Retail</option>
        <option value="Technology">Technology</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="form-group">
      <label for="dealDetails">About Deal</label>
      <div id="dealDetails" ref="dealDetailsEditor"></div>
    </div>
    <div class="form-group">
      <label for="dealFinePrint">Fine Print</label>
      <div id="dealFinePrint" ref="dealFinePrintEditor"></div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'StepDetails',
  data() {
    return {
      dealtitle: '',
      category: '',
      dealdetails: '',
      dealfineprint: '',
      quill: null,
      quillFinePrint: null,
    };
  },
  methods: {
    validateForm() {
      const isValid =
        this.dealtitle?.trim().length > 0 &&
        this.category?.trim().length > 0 &&
        this.quill?.root?.innerHTML.trim().length > 0 &&
        this.quillFinePrint?.root?.innerHTML.trim().length > 0;

      this.$emit('formValidation', isValid);
    },
    validateAndSave() {
      this.dealdetails = this.quill.root.innerHTML;
      this.dealfineprint = this.quillFinePrint.root.innerHTML;
      if (this.dealtitle && this.category && this.dealdetails && this.dealfineprint) {
        this.saveDetails();
        this.$emit('nextStep');
      }
    },
    saveDetails() {
      const dealDetails = {
        dealtitle: this.dealtitle,
        category: this.category,
        dealdetails: this.dealdetails,
        dealfineprint: this.dealfineprint,
      };
      localStorage.setItem('dealDetails', JSON.stringify(dealDetails));
    },
    setDetails(details) {
      this.dealtitle = details.dealtitle;
      this.category = details.category;
      this.dealdetails = details.dealdetails;
      this.dealfineprint = details.dealfineprint;
      this.quill.root.innerHTML = this.dealdetails;
      this.quillFinePrint.root.innerHTML = this.dealfineprint;
    },
  },
  mounted() {
    this.quill = new Quill(this.$refs.dealDetailsEditor, {
      theme: 'snow',
      placeholder: 'Describe the deal...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    this.quillFinePrint = new Quill(this.$refs.dealFinePrintEditor, {
      theme: 'snow',
      placeholder: 'Add fine print...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    const savedDetails = JSON.parse(localStorage.getItem('editDetails'));
    if (savedDetails) {
      this.setDetails(savedDetails);
    }

    this.$emit('formValidation', this.dealtitle && this.category && this.dealdetails);

    this.quill.on('text-change', () => {
      this.dealdetails = this.quill.root.innerHTML;
      this.validateForm();
    });

    this.quillFinePrint.on('text-change', () => {
      this.dealfineprint = this.quillFinePrint.root.innerHTML;
      this.validateForm();
    });
  },
  watch: {
    dealtitle() {
      this.validateForm();
    },
    category() {
      this.validateForm();
    },
    dealdetails() {
      this.validateForm();
    },
    dealfineprint() {
      this.validateForm();
    },
  },
};
</script>

<style scoped>
.step-details {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

#dealDetails,
#dealFinePrint {
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .form-group {
    margin-bottom: 15px;
  }

  input,
  select {
    padding: 7px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 180px;
    padding: 7px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 10px;
  }

  input,
  select {
    padding: 6px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 160px;
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .form-group {
    margin-bottom: 5px;
  }

  input,
  select {
    padding: 5px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 140px;
    padding: 5px;
  }
}
</style>
