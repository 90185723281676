<template>
  <div class="step-preview">
    <div class="content-container">
      <!-- Left Side -->
      <div class="left-side">
        <!-- Image Viewer -->
        <div class="image-viewer" v-if="images && images.length > 0">
          <button class="main-arrow left" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
          <div class="main-image">
            <img v-if="images[selectedImageIndex]" :src="images[selectedImageIndex].url" alt="Main Image" />
          </div>
          <button class="main-arrow right" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
        </div>

        <!-- Thumbnails -->
        <div class="thumbnail-slider-container" v-if="images && images.length > 0">
          <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
            <span>&#9664;</span>
          </button>
          <div class="thumbnail-slider">
            <div class="thumbnail-container">
              <div class="image-preview" v-for="(image, index) in visibleThumbnails" :key="index" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
                <img :src="image.url" :alt="'Image ' + (index + 1)" />
              </div>
            </div>
          </div>
          <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
            <span>&#9654;</span>
          </button>
        </div>

        <!-- Options (Mobile Only) -->
        <div class="option-item mobile-only" v-if="option">
          <div class="option-content">
            <input type="radio" id="option-mobile" name="option-mobile" checked />
            <label for="option-mobile" class="option-label">
              <strong>{{ option.dealName }}</strong>
            </label>
          </div>
          <div class="option-pricing">
            <span class="original-price">${{ option.currentPrice }}</span>
            <span class="new-price">${{ calculateCustomerPays(option) }}</span>
            <span class="discount">{{ option.discount }}% Off</span>
          </div>
          <div class="option-stock">
            <p>In Stock: {{ option.numberInStock }}</p>
            <p>Max Purchase Amount: {{ option.maxPerPurchase }}</p>
          </div>
          <div class="option-quantity">
            <input type="number" min="1" :max="option.maxPerPurchase" v-model="purchaseQuantity" />
          </div>
          <div class="button-container">
            <button class="buy-now">Buy Now</button>
            <button class="add-to-cart">Add to Cart</button>
          </div>
        </div>

        <!-- About Deal -->
        <div class="about-deal" v-if="giftCardDetails.dealDetails">
          <h2>About Gift Card</h2>
          <div v-html="giftCardDetails.dealDetails"></div>
        </div>

        <!-- Fine Print -->
        <div class="fine-print" v-if="giftCardDetails.dealFinePrint">
          <h2>Fine Print</h2>
          <div v-html="giftCardDetails.dealFinePrint"></div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="right-side">
        <!-- Options (Desktop Only) -->
        <div class="option-item desktop-only" v-if="option">
          <div class="option-content">
            <input type="radio" id="option-desktop" name="option-desktop" checked />
            <label for="option-desktop" class="option-label">
              <strong>{{ option.dealName }}</strong>
            </label>
          </div>
          <div class="option-pricing">
            <span class="original-price">${{ option.currentPrice }}</span>
            <span class="new-price">${{ calculateCustomerPays(option) }}</span>
            <span class="discount">{{ option.discount }}% Off</span>
          </div>
          <div class="option-stock">
            <p>In Stock: {{ option.numberInStock }}</p>
            <p>Max Purchase Amount: {{ option.maxPerPurchase }}</p>
          </div>
          <div class="option-quantity">
            <input type="number" min="1" :max="option.maxPerPurchase" v-model="purchaseQuantity" />
          </div>
          <div class="button-container">
            <button class="buy-now">Buy Now</button>
            <button class="add-to-cart">Add to Cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepPreview',
  data() {
    return {
      giftCardDetails: {
        dealTitle: '',
        category: '',
        dealDetails: '',
        dealFinePrint: '',
      },
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      thumbnailsPerPage: 6,
      option: null,
      purchaseQuantity: 1,
      showConfirmation: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    visibleThumbnails() {
      if (!this.images) return [];
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
  },
  methods: {
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    calculateCustomerPays(option) {
      return (option.currentPrice * (1 - option.discount / 100)).toFixed(2);
    },
    loadLocalStorageData() {
      const savedDetails = JSON.parse(localStorage.getItem('giftCardDetails'));
      if (savedDetails) {
        this.giftCardDetails = savedDetails;
      }

      const savedImages = JSON.parse(localStorage.getItem('uploadedImagesGC'));
      if (savedImages) {
        this.images = savedImages;
        this.selectedImageIndex = 0;
      } else {
        this.images = [];
      }

      const savedOption = JSON.parse(localStorage.getItem('option'));
      if (savedOption) {
        this.option = {
          ...savedOption,
          boughtCount: Math.floor(Math.random() * 10000 + 1)
        };
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.updateThumbnailsPerPage();
    },
    updateThumbnailsPerPage() {
      if (this.windowWidth <= 480) {
        this.thumbnailsPerPage = 3;
      } else if (this.windowWidth <= 768) {
        this.thumbnailsPerPage = 4;
      } else {
        this.thumbnailsPerPage = 6;
      }
    },
  },
  mounted() {
    this.loadLocalStorageData();
    this.updateThumbnailsPerPage();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.step-preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.left-side,
.right-side {
  width: 48%;
}

.image-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 30px);
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.image-preview {
  width: calc((100% - 50px) / 6);
  height: 0;
  padding-bottom: calc((100% - 50px) / 6);
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.image-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-arrow {
  width: 10px;
  height: calc((100% - 50px) / 6);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 0px;
}

.fine-print {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}

.about-deal {
  width: 100%;
  margin-top: 50px;
}

.option-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.option-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.option-label {
  margin-left: 10px;
}

.option-pricing {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.original-price {
  color: gray;
  margin-right: 10px;
  text-decoration: line-through;
}

.new-price {
  font-size: 18px;
  font-weight: bold;
  color: green;
}

.discount {
  background-color: #d4edda;
  color: green;
  padding: 3px 5px;
  border-radius: 4px;
  margin-left: 5px;
}

.option-stock {
  margin-top: 10px;
  margin-bottom: 10px;
}

.option-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.option-quantity input[type="number"] {
  width: 50px;
  padding: 5px;
  text-align: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buy-now,
.add-to-cart {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
}

.buy-now {
  background-color: #4C6B30;
  color: white;
}

.buy-now:hover {
  background-color: #6E9F49;
  cursor: pointer;
}

.add-to-cart {
  background-color: #6E9F49;
  color:white;
}

.add-to-cart:hover {
  background-color: #A3BF8F;
  cursor: pointer;
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .left-side,
  .right-side {
    width: 100%;
  }

  .image-viewer {
    height: 300px;
  }

  .image-preview {
    width: calc((100% - 30px) / 4);
    padding-bottom: calc((100% - 30px) / 4);
  }

  .thumbnail-arrow {
    height: calc((100% - 30px) / 4);
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .right-side {
    display: none;
  }
}

@media (max-width: 480px) {
  .image-viewer {
    height: 250px;
  }

  .image-preview {
    width: calc((100% - 20px) / 3);
    padding-bottom: calc((100% - 20px) / 3);
  }

  .thumbnail-arrow {
    height: calc((100% - 20px) / 3);
  }

  .option-pricing {
    flex-direction: column;
    align-items: flex-start;
  }

  .discount {
    margin-left: 0;
    margin-top: 5px;
  }
}
</style>