<template>
  <div class="notification-container">
    <div class="header">
      <h2>Send Notification</h2>
    </div>
    <div class="content-container">
      <!-- Choose Notification Type -->
      <div class="form-group">
        <label for="notificationType">Choose a notification type</label>
        <select v-model="notificationType" id="notificationType">
          <option value="notification">Site Notification</option>
          <option value="email">Email</option>
          <option value="text">Text</option>
        </select>
      </div>

      <!-- From (Business Name) -->
      <div class="form-group">
        <label for="from">From</label>
        <input type="text" v-model="from" id="from" readonly>
      </div>

      <!-- Title -->
      <div class="form-group">
        <label for="title">Title</label>
        <input type="text" v-model="title" id="title">
      </div>

      <!-- Message Body (Quill Editor) -->
      <div class="form-group">
        <label for="messageBody">Message body</label>
        <div id="messageEditor"></div>
      </div>

      <!-- Token Cost -->
      <div class="form-group">
        <label for="tokenCost">Token cost</label>
        <input type="number" v-model="tokenCost" id="tokenCost" value="0" readonly>
      </div>

      <!-- Send Button -->
      <div class="send-button">
        <button @click="sendNotification" :disabled="isSending || notificationSent">Send</button>
      </div>
    </div>

    <!-- Loading Overlay -->
    <div v-if="isSending || notificationSent" class="loading-overlay">
      <div v-if="isSending" class="loader"></div>
      <p v-if="isSending">Sending notification... Do not refresh or close this page.</p>
      <p v-if="notificationSent" class="success-message">Notification sent to fans!</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

export default {
  setup() {
    const notificationType = ref('notification');
    const from = ref('');
    const title = ref('');
    const tokenCost = ref(0);
    const isSending = ref(false);
    const notificationSent = ref(false);
    let quill;

    onMounted(async () => {
      quill = new Quill('#messageEditor', {
        theme: 'snow'
      });

      await fetchBusinessName();
    });

    const fetchBusinessName = async () => {
      try {
        const businessId = JSON.parse(localStorage.getItem('user')).business_id;
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/businesses/business-name/${businessId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        from.value = response.data.businessName;
      } catch (error) {
        console.error('Error fetching business name:', error);
        from.value = 'Unknown Business';
      }
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const sendNotification = async () => {
      isSending.value = true;
      const messageContent = quill.root.innerHTML;
      const businessId = JSON.parse(localStorage.getItem('user')).business_id;
      const token = localStorage.getItem('token');

      try {
        // Simulate a 4-second delay
        await delay(4000);

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/notifications/new-notification`, {
          businessId,
          title: title.value,
          messageBody: messageContent,
          tokensCost: tokenCost.value,
          notificationType: notificationType.value
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log('Notification sent successfully:', response.data);
        notificationSent.value = true;
        
        // Show "Notifications sent to fans" for 2 seconds
        await delay(2000);
      } catch (error) {
        console.error('Error sending notification:', error);
        // Add logic to handle error
      } finally {
        isSending.value = false;
        notificationSent.value = false;
        location.reload();
      }
    };

    return {
      notificationType,
      from,
      title,
      tokenCost,
      sendNotification,
      isSending,
      notificationSent
    };
  }
};
</script>

<style scoped>
.notification-container {
  width: 40%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  position: relative;
}

.header {
  text-align: center;
  margin-bottom: 10px;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 6px;
  font-weight: bold;
}

select,
input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

input[readonly] {
  background-color: #f8f8f8;
}

#messageEditor {
  height: 150px;
}

.send-button {
  text-align: center;
}

.send-button button {
  width: 100%;
  padding: 8px 16px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.send-button button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

.send-button button:hover:not(:disabled) {
  background-color: #6E9F49;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #4C6B30;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-message {
  color: #4C6B30;
  font-weight: bold;
  font-size: 18px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .notification-container {
    padding: 10px;
    margin-top: 20px;
  }

  .send-button {
    text-align: center;
  }

  .send-button button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .notification-container {
    padding: 10px;
    margin-top: 20px;
    width: 90%;
  }

  .send-button button {
    padding: 6px 12px;
  }
}
</style>