<template>
    <div class="step-indicator">
      <div class="step-line">
        <div
          v-for="(step, index) in steps"
          :key="index"
          :class="['step', { active: index === giftCardStep, completed: index < giftCardStep }]"
        ></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StepIndicator',
    props: {
      giftCardStep: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        steps: ['Details', 'Options', 'Images', 'Preview/Pay'],
      };
    },
  };
  </script>
  
  <style scoped>
  .step-indicator {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    width: calc(100% - 320px); /* Adjust width considering the sidebar */
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 1024px) {
    .step-indicator {
      width: calc(100% - 200px); /* Adjust width for smaller sidebar */
    }
  }
  
  @media (max-width: 768px) {
    .step-indicator {
      width: calc(100% - 100px); /* Further adjust width for smaller sidebar */
    }
  }
  
  @media (max-width: 480px) {
    .step-indicator {
      width: 100%; /* Full width for very small screens */
    }
  }
  
  .step-line {
    display: flex;
    justify-content: space-between; /* Distribute dots evenly */
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .step {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #6E9F49;
    position: relative;
    z-index: 1;
  }
  
  .step.active {
    background-color: #6E9F49;
  }
  
  .step.completed {
    background-color: #4C6B30;
  }
  
  .step-line::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #6E9F49;
    z-index: 0;
  }
  </style>
  