<template>
  <div>
    <MetaManager
      title="Stark Shopper Home"
      description="Discover unbeatable deals on coupons and gift cards from local businesses at Stark Shopper. Save money while supporting your community with every purchase."
    />
    <HeaderSearch @search-results="handleSearchResults" @location-changed="handleLocationChange" />
    
    <div v-if="searchActive" class="results-container">
      <div v-if="noResults" class="no-results">
        <p>No coupons or gift cards found for the business</p>
      </div>
      <div v-else>
        <BusinessProfile v-if="businessId" :business-id="businessId" />
      </div>
    </div>
    
    <div v-else class="homepage-content">
      <FollowedBusinesses />

      <!-- Trending Today (Gift Cards) Section -->
      <section class="trending-section">
        <h2>Trending Today</h2>
        <div class="trending-list gift-cards">
          <template v-if="loadingGiftCards">
            <GiftCardSkeleton v-for="n in 4" :key="`trending-giftcard-skeleton-${n}`" />
          </template>
          <template v-else>
            <GiftCard
              v-for="giftCard in trendingGiftCards.slice(0, 4)"
              :key="giftCard._id"
              :giftCardId="giftCard._id"
              :dealTitle="giftCard.dealtitle"
              :rating="giftCard.rating"
              :cardImage="getGiftCardImage(giftCard)"
              :originalPrice="giftCard.currentprice"
              :discountPercentage="giftCard.discount"
              :businessId="giftCard.business_id"
            />
          </template>
        </div>
      </section>

      <!-- Trending Coupons Section -->
      <section class="trending-section coupons-section">
        <div class="coupons-container">
          <div class="section-header">
            <h2>Trending Coupons</h2>
            <router-link to="/coupons" class="view-all">See all coupons &gt;</router-link>
          </div>
          <div class="trending-list coupons">
            <template v-if="loadingCoupons">
              <CouponCardSkeleton v-for="n in 4" :key="`trending-coupon-skeleton-${n}`" />
            </template>
            <template v-else>
              <CouponCard
                v-for="coupon in trendingCoupons.slice(0, 4)"
                :key="coupon._id"
                :couponId="coupon._id"
                :dealTitle="coupon.dealtitle"
                :couponCode="coupon.couponcode[0]"
                :couponImage="getCouponImage(coupon)"
                :rating="coupon.rating"
                :dealDetails="processDiscount(coupon)"
                :dealtype="coupon.discounttype[0]"
                :category="coupon.category"
                @save-coupon="addToShoppingCart"
                ref="couponCards"
              />
            </template>
          </div>
        </div>
      </section>

      <!-- Ad Banner -->
      <div class="ad-section">
        <div class="ad-content">
          <a :href="getAdLink(0)">
            <img :src="getAdImage(0)" alt="Advertisement" />
          </a>
        </div>
      </div>

      <!-- Category Filter Bar -->
      <CategoryFilter 
        :categories="giftCardCategories" 
        @category-selected="filterGiftCards" 
        :default-category="'all'" 
      />

      <!-- Gift Card List -->
      <div class="gift-card-list">
        <template v-if="loadingGiftCards">
          <GiftCardSkeleton v-for="n in 14" :key="`giftcard-skeleton-${n}`" />
        </template>
        <template v-else-if="displayedGiftCards.length">
          <GiftCard
            v-for="giftCard in displayedGiftCards"
            :key="giftCard._id"
            :giftCardId="giftCard._id"
            :dealTitle="giftCard.dealtitle"
            :rating="giftCard.rating"
            :cardImage="getGiftCardImage(giftCard)"
            :originalPrice="giftCard.currentprice"
            :discountPercentage="giftCard.discount"
            :businessId="giftCard.business_id"
          />
        </template>
        <div v-else>
          There are no gift cards for this category.
        </div>
      </div>

      <!-- Load More Button -->
      <div class="load-more-container" v-if="!allGiftCardsLoaded && displayedGiftCards.length">
        <button @click="loadMoreGiftCards">Load More</button>
      </div>

            <!-- Ad Banner -->
            <div class="ad-section">
        <div class="ad-content">
          <a :href="getAdLink(1)">
            <img :src="getAdImage(1)" alt="Advertisement" />
          </a>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import CouponCard from '@/components/Coupon Components/CouponCard.vue';
import GiftCard from '@/components/GiftCard Components/GiftCard.vue';
import CouponCardSkeleton from '@/components/Coupon Components/CouponCardSkeleton.vue';
import GiftCardSkeleton from '@/components/GiftCard Components/GiftCardSkeleton.vue';
import CategoryFilter from '@/components/Frontend Nav Components/CategoryFilterBar.vue';
import HeaderSearch from '@/components/Homepage/HomepageHeader.vue';
import BusinessProfile from '@/components/Business Profile/BusinessProfile.vue';
import FollowedBusinesses from '@/components/Homepage/FollowedBusinesses.vue';
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';

export default {
  components: {
    CategoryFilter,
    CouponCard,
    GiftCard,
    CouponCardSkeleton,
    GiftCardSkeleton,
    HeaderSearch,
    BusinessProfile,
    FollowedBusinesses,
    MetaManager
  },
  data() {
    return {
      categories: [
        { id: 'all', name: 'All' },
        { id: 'Restaurants', name: 'Restaurants' },
        { id: 'Beauty-spa', name: 'Beauty & Spas' },
        { id: 'Things-to-do', name: 'Things to do' },
        { id: 'Health-fitness', name: 'Health & Fitness' },
        { id: 'Automotive', name: 'Automotive' },
        { id: 'Home-services', name: 'Home Services' },
        { id: 'Retail', name: 'Retail' },
        { id: 'Technology', name: 'Technology' },
        { id: 'Other', name: 'Other' }
      ],
      giftCardCategories: [
        { id: 'all', name: 'All' },
        { id: 'Restaurants', name: 'Restaurants' },
        { id: 'Beauty-spa', name: 'Beauty & Spas' },
        { id: 'Things-to-do', name: 'Things to do' },
        { id: 'Health-fitness', name: 'Health & Fitness' },
        { id: 'Automotive', name: 'Automotive' },
        { id: 'Home-services', name: 'Home Services' },
        { id: 'Retail', name: 'Retail' },
        { id: 'Technology', name: 'Technology' },
        { id: 'Other', name: 'Other' }
      ],
      coupons: [], // All loaded coupons for the current category
      displayedCoupons: [], // Coupons to be displayed based on pagination and filtering
      selectedCategory: 'all', // Default selected category
      offset: 0, // Offset for pagination
      limit: 8, // Limit for pagination
      allCouponsLoaded: false, // Flag to indicate all coupons are loaded for the selected category
      
      giftCards: [], // All loaded gift cards
      displayedGiftCards: [], // Gift cards to be displayed based on pagination and filtering
      selectedGiftCardCategory: 'all', // Default selected category for gift cards
      giftCardOffset: 0, // Offset for gift cards pagination
      allGiftCardsLoaded: false, // Flag to indicate all gift cards are loaded
      searchActive: false, // Flag to indicate if a search is active
      businessId: null, // Business ID for filtering
      searchQuery: '', // Store the search query for display
      isLoading: false, // Prevents multiple calls while loading
      noResults: false, // Flag to indicate no results found
      selectedLocation: localStorage.getItem('selectedLocation') || 'Stark County, Ohio',
      loadingCoupons: true, // Loading flag for coupons
      loadingGiftCards: true, // Loading flag for gift cards

      trendingCoupons: [], // Trending coupons
      trendingGiftCards: [], // Trending gift cards

      ads: [], // Advertisements
    };
  },
  created() {
    this.loadInitialData();
    this.loadTrendingCoupons();
    this.loadTrendingGiftCards();
    this.loadAdvertisements();
  },
  methods: {
    async loadInitialData() {
      await this.loadCoupons();
      await this.loadGiftCards();
    },
    async loadCoupons(reset = false) {
      if (this.isLoading) return;
      this.isLoading = true;
      this.loadingCoupons = true;
      try {
        if (reset) {
          this.coupons = [];
          this.offset = 0;
          this.allCouponsLoaded = false;
        }
        const params = {
          limit: this.limit,
          offset: this.offset,
          category: this.selectedCategory === 'all' ? '' : this.selectedCategory,
          location: this.selectedLocation,
        };
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/data`, { params });
        const newCoupons = response.data;
        if (reset) {
          this.coupons = newCoupons;
        } else {
          this.coupons = [...this.coupons, ...newCoupons];
        }
        this.allCouponsLoaded = newCoupons.length < this.limit;
        if (newCoupons.length > 0) {
          this.offset += this.limit; // Move the offset forward only if there are new coupons
        }
        this.updateDisplayedCoupons();
      } catch (error) {
        console.error('Error fetching coupons:', error);
      } finally {
        this.isLoading = false;
        this.loadingCoupons = false;
      }
    },
    getCouponImage(coupon) {
      // Check if image_links exists and is an array with at least one item
      if (Array.isArray(coupon.image_links) && coupon.image_links.length > 0) {
        return coupon.image_links[0]; // Return the first image
      }
      // If image_links is a string, return it directly
      if (typeof coupon.image_links === 'string') {
        return coupon.image_links;
      }
      // If no valid image, return an empty string
      return '';
    },
    async loadGiftCards(reset = false) {
      if (this.isLoading) return;
      this.isLoading = true;
      this.loadingGiftCards = true;
      try {
        if (reset) {
          this.giftCards = [];
          this.giftCardOffset = 0;
          this.allGiftCardsLoaded = false;
        }
        const params = {
          limit: this.limit,
          offset: this.giftCardOffset,
          category: this.selectedGiftCardCategory === 'all' ? '' : this.selectedGiftCardCategory,
          location: this.selectedLocation,
        };
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/get-gift-cards`, { params });
        const newGiftCards = response.data;
        if (reset) {
          this.giftCards = newGiftCards;
        } else {
          this.giftCards = [...this.giftCards, ...newGiftCards];
        }
        this.allGiftCardsLoaded = newGiftCards.length < this.limit;
        if (newGiftCards.length > 0) {
          this.giftCardOffset += this.limit;
        }
        this.updateDisplayedGiftCards();
      } catch (error) {
        console.error('Error fetching gift cards:', error);
      } finally {
        this.isLoading = false;
        this.loadingGiftCards = false;
      }
    },
    getGiftCardImage(giftCard) {
      // Check if image_links exists and is an array with at least one item
      if (Array.isArray(giftCard.image_links) && giftCard.image_links.length > 0) {
        return giftCard.image_links[0]; // Return the first image
      }
      // If image_links is a string, return it directly
      if (typeof giftCard.image_links === 'string') {
        return giftCard.image_links;
      }
      // If no valid image, return an empty string
      return '';
    },
    async loadTrendingCoupons() {
      try {
        const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;
        const params = {
          userId: userId,
          location: this.selectedLocation,
          limit: 5 // or any other number you prefer
        };
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/coupons/trending`, { params });
        this.trendingCoupons = response.data;
      } catch (error) {
        console.error('Error fetching trending coupons:', error);
      }
    },
    async loadTrendingGiftCards() {
      try {
        const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;
        const params = {
          userId: userId,
          location: this.selectedLocation,
          limit: 5 // or any other number you prefer
        };
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/get-gift-cards/trending`, { params });
        this.trendingGiftCards = response.data;
      } catch (error) {
        console.error('Error fetching trending gift cards:', error);
      }
    },
    async loadAdvertisements() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/advertisements/homepage`);
        this.ads = response.data;
      } catch (error) {
        console.error('Error fetching advertisements:', error);
        // If there's an error fetching ads, fill the array with default ads
        this.ads = [];
      }
    },
    getAdLink(index) {
      if (this.ads.length > index) {
        return this.ads[index].link_url;
      }
      return '/contact';
    },
    getAdImage(index) {
      if (this.ads.length > index) {
        return this.ads[index].image_url;
      }
      return require('@/assets/StarkShopper Ads.png');
    },
    loadMoreCoupons() {
      this.loadCoupons();
    },
    loadMoreGiftCards() {
      this.loadGiftCards();
    },
    filterCoupons(category) {
      this.selectedCategory = category.id;
      this.loadCoupons(true); // Load new category with reset
    },
    filterGiftCards(category) {
      this.selectedGiftCardCategory = category.id;
      this.loadGiftCards(true); // Load new category with reset
    },
    updateDisplayedCoupons() {
      this.displayedCoupons = this.coupons;
    },
    updateDisplayedGiftCards() {
      this.displayedGiftCards = this.giftCards;
    },
    processDiscount(coupon) {
      const { discount, regularprice, spendamount, discounttype } = coupon;
      const isRegularPriceValid = regularprice[0] !== 0;
      const isSpendAmountValid = spendamount[0] !== 0;

      return {
        discount: discount[0],
        regularprice: isRegularPriceValid ? regularprice : null,
        spendamount: isSpendAmountValid ? spendamount : null,
        discounttype: discounttype[0]
      };
    },
    async addToShoppingCart(couponId) {
      const userId = this.getUserId();
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/${userId}`, { couponId });
        this.$refs.couponCards.forEach(card => {
          if (card.couponId === couponId) {
            card.showMessage('Coupon added to saved coupons');
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$refs.couponCards.forEach(card => {
            if (card.couponId === couponId) {
              card.showMessage('Coupon already saved');
            }
          });
        } else {
          this.$refs.couponCards.forEach(card => {
            if (card.couponId === couponId) {
              card.showMessage('Error adding coupon to shopping cart');
            }
          });
        }
      }
    },
    getUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.id : null;
    },
    async handleSearchResults(searchResults) {
      this.searchQuery = searchResults.businessId ? searchResults.businessId : '';
      if (!searchResults.businessId || searchResults.businessId === null) {
        this.searchActive = true;
        this.businessId = null;
        this.noResults = true;
        this.displayedCoupons = [];
        this.displayedGiftCards = [];
        return;
      }
      this.searchActive = true;
      this.businessId = searchResults.businessId;

      await this.loadCoupons(true);
      await this.loadGiftCards(true);

      // Check if both displayedCoupons and displayedGiftCards are empty
      if (this.coupons.length === 0 && this.giftCards.length === 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }
    },
    async handleLocationChange(newLocation) {
      this.selectedLocation = newLocation;
      localStorage.setItem('selectedLocation', newLocation);
      await this.loadCoupons(true);
      await this.loadGiftCards(true);
    }
  }
};
</script>

<style scoped>
.coupon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.homepage-content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.browse-all-deals,
.browse-all-gift-cards {
  text-align: left;
  margin-left: 7%;
  margin-top: 20px;
  padding-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.trending-section {
  margin-bottom: 30px;
}
.trending-section h2 {
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
}


.trending-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.gift-cards-section {
  margin-bottom: 40px;
}

.trending-list .coupon-card,
.trending-list .gift-card,
.gift-card-list .gift-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.trending-list .coupon-card:hover,
.trending-list .gift-card:hover,
.gift-card-list .gift-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.trending-list.gift-cards {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

/* Coupons Section */
.coupons-section {
  margin-bottom: 40px;
}

.coupons-container {
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 57px;
}

.view-all {
  color: #4C6B30;
  text-decoration: none;
  font-weight: bold;
  padding-right: 65px;
}

.trending-list.coupons {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding-bottom: 10px;
}

/* Styles for smaller coupon cards */
.trending-list.coupons :deep(.coupon-card) {
  flex: 0 0 auto;
  width: 265px;
  font-size: 0.85em;
}

.trending-list.coupons :deep(.dashed-box){
  right: 90px;
}

.trending-list.coupons :deep(.coupon-card img) {
  max-height: 200px;
  object-fit: cover;
}

.gift-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}


.load-more-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ad-section {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.ad-content {
  width: 100%;
  max-width: 728px;
  height: 90px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;

}

.ad-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

button {
  padding: 15px 25px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 17px;
}

button:hover {
  background-color: #6E9F49;
}

.results-container {
  padding: 2rem;
}

.no-results {
  text-align: center;
  margin-top: 2rem;
}

.trending-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.gift-card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.load-more-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
}

.load-more-container button:hover {
  background-color: #6E9F49;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .browse-all-deals,
  .browse-all-gift-cards, .trending-section {
    margin-left: 5%;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .trending-list.gift-cards {
    grid-template-columns: repeat(2, 1fr);  /* Two columns for tablets */
  }

  .trending-list.coupons :deep(.coupon-card) {
    width: 100%; /* Increased width for larger cards */
  }

  .coupons-section {
    margin-left: 2%;
    margin-right: 2%;
  }

  .load-more-container {
    margin-bottom: 10px;
  }

  .ad-section {
    margin: 20px 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .ad-content {
    height: 60px;
  }

  button {
    padding: 15px 25px;
    font-size: 15px;
  }

  .trending-list .coupon-card:hover,
  .trending-list .gift-card:hover,
  .gift-card-list .gift-card:hover {
    transform: translateY(-3px);
  }

  .section-header {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    gap: 10px;
  }

  .view-all {
    padding-right: 0;
  }

}

@media (max-width: 480px) {
  .trending-list.gift-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .trending-list .gift-card {
    width: 100%;
    max-width: 94%;
  }

  .section-header {
    padding-left: 10px;
    overflow: hidden;
  }

  .trending-list.coupons {
    padding-left: 10px;
  }

  .trending-list.coupons :deep(.coupon-card) {
    width: 95%; /* Even larger for small mobile screens */
  }

  .trending-list.coupons :deep(.dashed-box){
    right: 130px;
  }

  .coupons-section {
    margin-left: 0;
    margin-right: 0;
  }
  .ad-content {
    height: 50px;
  }

  button {
    padding: 15px 25px;
    font-size: 15px;
  }

  .trending-list .coupon-card:hover,
  .trending-list .gift-card:hover,
  .gift-card-list .gift-card:hover {
    transform: translateY(-2px);
  }
}
</style>
