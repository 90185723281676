<template>
    <div class="dashboard-content">
      <h1>Administration</h1>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DashboardAdministration',
  };
  </script>
  
  <style scoped>
  .dashboard-content {
    padding: 20px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .dashboard-content {
      padding: 15px; /* Adjust padding for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .dashboard-content {
      padding: 10px; /* Adjust padding for small screens */
      margin-top: 50px;
    }
  }
  
  @media (max-width: 480px) {
    .dashboard-content {
      padding: 5px; /* Adjust padding for extra small screens */
    }
  }
  </style>