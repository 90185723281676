<template>
    <div class="skeleton-card">
      <div class="skeleton-image"></div>
      <div class="skeleton-title"></div>
      <div class="skeleton-rating"></div>
      <div class="skeleton-price"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CouponCardSkeleton',
  };
  </script>
  
  <style scoped>
  .skeleton-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
  }
  
  .skeleton-image {
    width: 100%;
    height: 120px;
    background-color: #e0e0e0;
    border-radius: 8px;
  }
  
  .skeleton-title {
    width: 80%;
    height: 20px;
    background-color: #e0e0e0;
    margin-top: 10px;
    border-radius: 4px;
  }
  
  .skeleton-rating {
    width: 60%;
    height: 20px;
    background-color: #e0e0e0;
    margin-top: 10px;
    border-radius: 4px;
  }
  
  .skeleton-price {
    width: 40%;
    height: 20px;
    background-color: #e0e0e0;
    margin-top: 10px;
    border-radius: 4px;
  }
  </style>
  