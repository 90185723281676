<template>
  <div class="step-options">
    <h2>Step 2: Options</h2>
    <div class="form-container">
      <div class="option-form">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="dealName">Gift Card Name</label>
            <input type="text" id="dealName" v-model="currentOption.dealName" maxlength="120" @input="validateForm" required />
            <small>{{ 120 - currentOption.dealName.length }} Characters remaining</small>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="releaseAmount">Release Amount</label>
              <input type="number" id="releaseAmount" v-model.number="currentOption.releaseAmount" @input="validateForm" required />
            </div>
            <div class="form-group">
              <label for="releaseFrequency">Release Frequency</label>
              <select id="releaseFrequency" v-model="currentOption.releaseFrequency" @input="validateForm" required>
                <option value="" disabled>Select Frequency</option>
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="exactDate">Exact Date</option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="currentOption.releaseFrequency === 'exactDate'">
            <label for="exactDate">Exact Date</label>
            <input type="date" id="exactDate" v-model="currentOption.exactDate" @input="validateForm" required />
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="currentPrice">Card Amount ($)</label>
              <input type="number" id="currentPrice" v-model="currentOption.currentPrice" min="1" max="999" step="0.01" @input="validateForm" required />
              <small>Card Amount should be between $1 and $999</small>
            </div>
            <div class="form-group">
              <label for="numberInStock">Number in stock</label>
              <input type="number" id="numberInStock" v-model.number="currentOption.numberInStock" @input="validateForm" required />
            </div>
          </div>
          <div class="form-group">
            <label for="maxPerPurchase">Max Purchase Amount (per customer)</label>
            <input type="number" id="maxPerPurchase" v-model.number="currentOption.maxPerPurchase" @input="validateForm" required />
          </div>
          <div class="form-group">
            <label for="giftCardCode">Gift Card Code</label>
            <div class="coupon-code-container">
              <input type="text" id="giftCardCode" v-model="currentOption.giftCardCode" maxlength="50" @input="validateForm" readonly />
              <button type="button" @click="generateCodes">Generate Codes</button>
              <button type="button" v-if="codesGenerated" @click="toggleShowGiftCards">{{ showGiftCards ? 'Hide Gift Cards' : 'Show Gift Cards' }}</button>
            </div>
            <div v-if="showGiftCards" class="gift-cards-table-container">
              <h3>Gift Card Codes</h3>
              <table class="gift-cards-table">
                <thead>
                  <tr>
                    <th>Gift Card Code</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(code, index) in generatedCodes" :key="index">
                    <td>{{ formatCode(code) }}</td>
                    <td>
                      <button @click="editCode(index)">Edit</button>
                      <button @click="deleteCode(index)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group">
            <label>Choose your discount (approx.)</label>
            <div class="slider-container">
              <input
                type="range"
                v-model.number="currentOption.discount"
                min="0"
                max="100"
                step="1"
                class="slider"
                @input="validateForm"
              />
              <div class="slider-track">
                <div class="track-section red" :style="{ width: '10%' }"></div>
                <div class="track-section yellow" :style="{ width: '20%' }"></div>
                <div class="track-section green" :style="{ width: '40%' }"></div>
                <div class="track-section yellow" :style="{ width: '20%' }"></div>
                <div class="track-section red" :style="{ width: '10%' }"></div>
              </div>
              <div class="slider-labels">
                <span class="slider-label">{{ currentOption.discount }}%</span>
                <span v-if="isRecommendedDiscount(currentOption.discount)" class="slider-label recommended">Recommended discount</span>
              </div>
            </div>
            <small>Stay within the green for optimal performance</small>
          </div>
          <div class="price-calculations">
            <div class="calculation">
              <div class="label">Customer Pays</div>
              <div class="value">${{ calculateCustomerPays(currentOption) }}</div>
            </div>
            <div class="calculation">
              <div class="label">Business Gets</div>
              <div class="value">${{ calculateYouGet(currentOption) }}</div>
            </div>
            <div class="calculation">
              <div class="label">Stark Shopper Gets</div>
              <div class="value">${{ calculateShopSharkGets(currentOption) }}</div>
            </div>
          </div>
          <div class="button-group">
            <button type="submit">Save Option</button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="showDeleteConfirm" class="delete-confirmation">
      <p>Are you sure you want to delete this option?</p>
      <button @click="deleteOption">Yes</button>
      <button @click="cancelDelete">No</button>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
  name: 'StepOptions',
  props: {
    currentStep: Number,
  },
  setup(props, { emit }) {
    const currentOption = ref({
      dealName: '',
      currentPrice: 0,
      numberInStock: 0,
      discount: 0,
      giftCardCode: '',
      maxPerPurchase: 0,
      releaseAmount: 0,
      releaseFrequency: '',
      exactDate: ''
    });
    const isFormValid = ref(false);
    const showDeleteConfirm = ref(false);
    const codesGenerated = ref(false);
    const showGiftCards = ref(false);
    const generatedCodes = ref([]);

    const validateForm = () => {
      const option = currentOption.value;
      const isValid =
        option.dealName &&
        option.currentPrice > 0 &&
        option.currentPrice <= 999 &&
        option.numberInStock > 0 &&
        option.discount >= 0 &&
        option.discount <= 100 &&
        option.maxPerPurchase > 0 &&
        option.releaseAmount > 0 &&
        option.releaseFrequency &&
        (option.releaseFrequency !== 'exactDate' || option.exactDate);

      isFormValid.value = isValid;
      emit('formValidation', isFormValid.value);
    };

    const saveOption = () => {
      localStorage.setItem('option', JSON.stringify(currentOption.value));
      localStorage.setItem('codesGenerated', JSON.stringify(codesGenerated.value));
      localStorage.setItem('generatedCodes', JSON.stringify(generatedCodes.value));
    };

    const clearCurrentOption = () => {
      currentOption.value = {
        dealName: '',
        currentPrice: 0,
        numberInStock: 0,
        discount: 0,
        giftCardCode: '',
        maxPerPurchase: 0,
        releaseAmount: 0,
        releaseFrequency: '',
        exactDate: ''
      };
      validateForm();
    };

    const deleteOption = () => {
      localStorage.removeItem('option');
      localStorage.removeItem('codesGenerated');
      localStorage.removeItem('generatedCodes');
      clearCurrentOption();
      showDeleteConfirm.value = false;
    };

    const cancelDelete = () => {
      showDeleteConfirm.value = false;
    };

    const handleSubmit = () => {
      saveOption();
    };

    const calculateCustomerPays = (option) => {
      return (option.currentPrice * (1 - option.discount / 100)).toFixed(2);
    };

    const calculateYouGet = (option) => {
      return (option.currentPrice * (1 - option.discount / 100) * 0.8).toFixed(2);
    };

    const calculateShopSharkGets = (option) => {
      return (option.currentPrice * (1 - option.discount / 100) * 0.2).toFixed(2);
    };

    const isRecommendedDiscount = (discount) => {
      return discount >= 30 && discount <= 70;
    };

    const generateCodes = () => {
      const chars = '0123456789';
      let giftCardCode = '';
      for (let i = 0; i < 12; i++) {
        if (i > 0 && i % 4 === 0) {
          giftCardCode += '-';
        }
        giftCardCode += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      currentOption.value.giftCardCode = giftCardCode;

      const baseCode = giftCardCode.replace(/-/g, '');
      const numberInStock = currentOption.value.numberInStock;
      generatedCodes.value = [];

      for (let i = 0; i < numberInStock; i++) {
        let suffix = '';
        for (let j = 0; j < 4; j++) {
          suffix += Math.floor(Math.random() * 10);
        }
        const code = `${baseCode}${suffix}`;
        generatedCodes.value.push(code);
      }

      localStorage.setItem('generatedCodes', JSON.stringify(generatedCodes.value));
      codesGenerated.value = true;
      showGiftCards.value = true;
      saveOption(); // Save the state to local storage
    };

    const toggleShowGiftCards = () => {
      showGiftCards.value = !showGiftCards.value;
    };

    const formatCode = (code) => {
      return code.match(/.{1,4}/g).join('-');
    };

    const editCode = (index) => {
      const newCode = prompt('Edit Gift Card Code:', formatCode(generatedCodes.value[index]));
      if (newCode) {
        generatedCodes.value[index] = newCode.replace(/-/g, '');
        localStorage.setItem('generatedCodes', JSON.stringify(generatedCodes.value));
      }
    };

    const deleteCode = (index) => {
      generatedCodes.value.splice(index, 1);
      localStorage.setItem('generatedCodes', JSON.stringify(generatedCodes.value));
    };

    onMounted(() => {
      const savedOption = localStorage.getItem('option');
      if (savedOption) {
        currentOption.value = JSON.parse(savedOption);
      }
      const savedCodesGenerated = localStorage.getItem('codesGenerated');
      if (savedCodesGenerated) {
        codesGenerated.value = JSON.parse(savedCodesGenerated);
        if (codesGenerated.value) {
          const savedGeneratedCodes = localStorage.getItem('generatedCodes');
          if (savedGeneratedCodes) {
            generatedCodes.value = JSON.parse(savedGeneratedCodes);
          }
        }
      }
      validateForm();
    });

    watch(currentOption, validateForm, { deep: true });

    return {
      currentOption,
      isFormValid,
      showDeleteConfirm,
      codesGenerated,
      showGiftCards,
      generatedCodes,
      saveOption,
      clearCurrentOption,
      deleteOption,
      cancelDelete,
      handleSubmit,
      calculateCustomerPays,
      calculateYouGet,
      calculateShopSharkGets,
      isRecommendedDiscount,
      validateForm,
      generateCodes,
      toggleShowGiftCards,
      formatCode,
      editCode,
      deleteCode,
    };
  },
};
</script>

<style scoped>
.step-options {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  justify-content: space-between;
}

.option-form {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.form-group {
  margin-bottom: 15px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.form-row .form-group {
  flex: 1 1 calc(50% - 20px);
  margin: 0 10px 15px;
  min-width: 200px;
}

.coupon-code-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

small {
  display: block;
  margin-top: 5px;
  color: #888;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.slider-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  background: transparent;
  outline: none;
  z-index: 1;
  position: relative;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid green;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid green;
  border-radius: 50%;
  cursor: pointer;
}

.slider-track {
  display: flex;
  width: 100%;
  height: 6px;
  background: #ddd;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 0;
}

.track-section {
  height: 100%;
}

.track-section.red {
  background: red;
}

.track-section.yellow {
  background: yellow;
}

.track-section.green {
  background: green;
}

.slider-labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.slider-label {
  color: green;
}

.recommended {
  font-size: 0.8em;
}

.price-calculations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.calculation {
  text-align: center;
  flex: 1 1 30%;
  margin-bottom: 15px;
}

.calculation .label {
  font-size: 1em;
  font-weight: bold;
}

.calculation .value {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-confirmation p {
  margin-bottom: 10px;
}

.delete-confirmation button {
  margin-right: 10px;
}

.gift-cards-table-container {
  overflow-x: auto;
}

.gift-cards-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.gift-cards-table th,
.gift-cards-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.gift-cards-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.gift-cards-table td {
  text-align: center;
}

.gift-cards-table button {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .form-row .form-group {
    flex: 1 1 100%;
  }

  .coupon-code-container {
    flex-direction: column;
  }

  .coupon-code-container button {
    width: 100%;
  }

  .price-calculations {
    flex-direction: column;
  }

  .calculation {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .step-options {
    padding: 0 10px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea {
    padding: 8px;
  }

  .slider::-webkit-slider-thumb,
  .slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
  }

  .calculation .value {
    font-size: 1.2em;
  }
}
</style>
