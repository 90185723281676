<template>
    <div class="container">
      <div class="card">
        <h2 v-if="verified">Email Verified</h2>
        <h2 v-else>Verification Failed</h2>
        <div class="icon">
          <img :src="verified ? successIcon : failureIcon" alt="Verification Status" />
        </div>
        <p v-if="verified">
          Your email has been verified successfully. You can now log in to start getting discounts.
        </p>
        <p v-else>
          Your email verification failed. Please try again or contact support.
        </p>
        <p>{{ email }}</p>
        <button @click="verified ? redirectToLogin() : redirectToRegister()" class="action-button">
          {{ verified ? 'Go to Login' : 'Register Again' }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import successIcon from '@/assets/check.png'; // Update with your actual success icon path
  import failureIcon from '@/assets/circle-with-x.png'; // Update with your actual failure icon path
  
  export default {
    data() {
      return {
        verified: false,
        email: this.$route.query.email || '',
        successIcon,
        failureIcon,
      };
    },
    async created() {
      const token = this.$route.query.token;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/register/verify-email`, { params: { token } });
        if (response.data.is_verified) {
          this.verified = true;
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        this.verified = false;
      }
    },
    methods: {
      redirectToLogin() {
        this.$router.push('/login');
      },
      redirectToRegister() {
        this.$router.push('/register');
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 50px 0;
  }
  
  .card {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  p {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #666;
  }
  
  .icon {
    margin: 20px 0;
  }
  
  .icon img {
    width: 50px;
    height: 50px;
  }
  
  .action-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  </style>
  