<template>
  <div class="intermediate-page">
    <h2>Proceed to Checkout</h2>
    <p>To complete your purchase, please choose one of the options below:</p>
    <div class="options">
      <button @click="goToLogin">Login</button>
      <button @click="goToRegister">Register</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntermediatePage',
  methods: {
    goToLogin() {
      // Store the intended action and redirect to login
      localStorage.setItem('redirectTo', 'checkout');
      this.$router.push('/login');
    },
    goToRegister() {
      // Store the intended action and redirect to register
      localStorage.setItem('redirectTo', 'checkout');
      this.$router.push('/register');
    },
  }
}
</script>

<style scoped>
.intermediate-page {
  text-align: center;
  padding: 3rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 3rem auto;
}

h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #333;
}

p {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.options {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

button {
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #4C6B30;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgb(110, 159, 73);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgb(110, 159, 73);;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .intermediate-page {
    padding: 2rem;
    margin: 2rem auto;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .intermediate-page {
    padding: 1rem;
    margin: 3.5rem auto;
    width: 300px;
  }

  h2 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9rem;
  }

  button {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
}
</style>
