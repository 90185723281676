<template>
  <div class="step-images">
    <h2>Step 3: Images</h2>

    <!-- Main image upload box -->
    <div v-if="images.length === 0" class="upload-box" @click="triggerFileUpload" @drop.prevent="handleDrop" @dragover.prevent>
      <p>Upload or drag and drop here</p>
      <input type="file" @change="handleFileUpload" ref="fileInput" multiple hidden />
    </div>

    <!-- Display the selected image with navigation arrows -->
    <div v-else class="main-image-container">
      <button class="main-arrow left" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
      <div class="main-image">
        <img :src="images[selectedImageIndex].url" alt="Main Image" />
      </div>
      <button class="main-arrow right" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
    </div>

    <!-- Thumbnails of uploaded images with navigation arrows -->
    <div class="thumbnail-slider-container">
      <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
        <span>&#9664;</span>
      </button>
      <div class="thumbnail-slider">
        <div class="thumbnail-container">
          <div class="thumbnail-item" v-for="(image, index) in visibleThumbnails" :key="index">
            <div class="image-preview" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
              <img :src="image.url" :alt="'Image ' + (index + 1)" />
            </div>
            <button class="delete-button" @click="confirmDelete(index + currentBatch * thumbnailsPerPage)">X</button>
          </div>
        </div>
      </div>
      <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
        <span>&#9654;</span>
      </button>
    </div>

    <!-- Smaller upload box for more images -->
    <div class="upload-more-box" v-if="images.length > 0" @click="triggerMoreFileUpload" @drop.prevent="handleDrop" @dragover.prevent>
      <p>Upload more or drag here</p>
      <input type="file" @change="handleFileUpload" ref="moreFileInput" multiple hidden />
    </div>

    <!-- Delete confirmation dialog -->
    <div v-if="showDeleteConfirm" class="delete-confirmation">
      <p>Are you sure you want to delete this image?</p>
      <button @click="deleteImage">Yes</button>
      <button @click="cancelDelete">No</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepImages',
  data() {
    return {
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      showDeleteConfirm: false,
      deleteIndex: null,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    thumbnailsPerPage() {
      return this.windowWidth <= 480 ? 3 : 6;
    },
    visibleThumbnails() {
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
  },
  methods: {
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    triggerMoreFileUpload() {
      this.$refs.moreFileInput.click();
    },
    handleFileUpload(event) {
      const files = event.target.files;
      this.processFiles(files);
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      this.processFiles(files);
    },
    processFiles(files) {
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.compressImage(e.target.result, 0.8, (compressedImage) => {
            this.images.push({ url: compressedImage, name: file.name });
            if (this.images.length === 1) {
              this.selectedImageIndex = 0;
            }
            this.saveImagesToLocalStorage();
          });
        };
        reader.readAsDataURL(file);
      });
    },
    compressImage(dataURL, quality, callback) {
      const img = new Image();
      img.src = dataURL;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        callback(canvas.toDataURL('image/jpeg', quality));
      };
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    saveImagesToLocalStorage() {
      const imagesToSave = this.images.map((image, index) => ({
        url: image.url,
        name: image.name,
        index
      }));
      try {
        localStorage.setItem('uploadedImagesGC', JSON.stringify(imagesToSave));
      } catch (e) {
        console.error('Failed to save images to local storage:', e);
      }
    },
    loadImagesFromLocalStorage() {
      try {
        const savedImages = JSON.parse(localStorage.getItem('uploadedImages'));
        if (savedImages) {
          this.images = savedImages;
          if (this.images.length > 0) {
            this.selectedImageIndex = 0;
          }
        }
      } catch (e) {
        console.error('Failed to load images from local storage:', e);
      }
    },
    prevStep() {
      this.$emit('prevStep');
    },
    nextStep() {
      this.saveImagesToLocalStorage();
      this.$emit('nextStep');
    },
    confirmDelete(index) {
      this.showDeleteConfirm = true;
      this.deleteIndex = index;
    },
    deleteImage() {
      if (this.deleteIndex !== null) {
        this.images.splice(this.deleteIndex, 1);
        this.saveImagesToLocalStorage();
        this.showDeleteConfirm = false;
        this.deleteIndex = null;
        if (this.selectedImageIndex >= this.images.length) {
          this.selectedImageIndex = this.images.length - 1;
        }
      }
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
      this.deleteIndex = null;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.loadImagesFromLocalStorage();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped>
.step-images {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-box {
  width: 800px;
  height: 300px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.upload-more-box {
  width: 800px;
  height: 100px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.upload-box p,
.upload-more-box p {
  margin: 0;
  text-align: center;
}

.main-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 825px;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 730px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100%);
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.thumbnail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-preview {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 20px;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.delete-button {
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.thumbnail-arrow {
  width: 10px;
  height: 100px;
  background-color: #4C6B30;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 5px;
}

.main-arrow:disabled,
.thumbnail-arrow:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-confirmation p {
  margin-bottom: 10px;
}

.delete-confirmation button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6E9F49;
}

@media (max-width: 1024px) {
  .upload-box,
  .upload-more-box {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .upload-box,
  .upload-more-box {
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .upload-box,
  .upload-more-box {
    max-width: 100%;
    height: 300px;
  }

  .main-image-container {
    width: 100%;
  }

  .thumbnail-slider-container {
    width: 100%;
  }
}
</style>