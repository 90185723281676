<template>
    <div class="container">
      <div class="card">
        <h2>Verify Your Email</h2>
        <p>Please check your email for a link to verify your email address.</p>
        <p>Once verified, you'll be able to get discounts and gift cards.</p>
        <div class="icon">
          <img src="@/assets/email-with-check.png" alt="Email Verification" />
        </div>
        <p>We've sent a verification email to <strong>{{ email }}</strong>.</p>
        <div class="buttons">
          <button @click="goHome" class="home-button">Home</button>
          <button @click="redirectToLogin" class="login-button">Login</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      email() {
        return this.$route.query.email;
      }
    },
    methods: {
      goHome() {
        this.$router.push('/');
      },
      redirectToLogin() {
        this.$router.push('/login');
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 50px 0;
  }
  
  .card {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 450px;
    width: 100%;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  p {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #666;
  }
  
  .icon {
    margin: 20px 0;
  }
  
  .icon img {
    width: 50px;
    height: 50px;
  }
  
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .home-button, .login-button {
    background-color: #4C6B30;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    width: 45%;
  }
  
  .home-button:hover, .login-button:hover {
    background-color: #6E9F49;
  }
  </style>
  