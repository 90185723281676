<template>
    <div class="customer-reviews">
      <h2>Customer Reviews</h2>
      <hr class="text-separator" />
      <div v-if="isLoggedIn && !hasSubmittedReview" class="submit-review">
        <h3>Submit Your Review</h3>
        <div class="star-rating">
          <span v-for="star in 5" :key="star" class="star"
                :class="{ 'hovered': star <= hoverRating, 'filled': star <= newReview.rating }"
                @mouseover="hoverRating = star"
                @mouseleave="hoverRating = newReview.rating"
                @click="setRating(star)">
            &#9733;
          </span>
        </div>
        <textarea v-model="newReview.comment" placeholder="Write your review..."></textarea>
        <button @click="submitReview" :disabled="!isValidReview">Submit</button>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </div>
      <div class="review-header">
        <div class="review-rating">
          <span class="rating">{{ averageRating }}</span>
          <span class="total-reviews">({{ reviews.length }} Reviews)</span>
          <span class="stars">
            <span v-for="star in 5" :key="star" class="star" :class="{ 'filled': star <= averageRating }">&#9733;</span>
          </span>
        </div>
        <div class="sort-dropdown">
          <label for="sort-reviews">Sort by:</label>
          <select id="sort-reviews" v-model="sortOption">
            <option value="highest-rated">Highest Rated</option>
            <option value="most-recent">Most Recent</option>
            <option value="lowest-rated">Lowest Rated</option>
          </select>
        </div>
      </div>
      <div class="no-reviews" v-if="reviews.length === 0">This gift card has no reviews.</div>
      <div class="reviews-list">
        <div v-for="(review, index) in sortedReviews" :key="index" class="review-item">
          <div class="review-header">
            <div class="review-rating">
              <strong>{{ review.fullName }}</strong>
              <span class="stars">
                <span v-for="star in 5" :key="star" class="star" :class="{ 'filled': star <= review.rating }">&#9733;</span>
              </span>
            </div>
            <span class="review-date">{{ formatDate(review.date) }}</span>
          </div>
          <p class="review-comment">{{ review.comment }}</p>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
import axios from 'axios';

export default {
  name: 'CustomerReviews',
  props: {
    reviews: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      newReview: {
        rating: 0,
        comment: ''
      },
      sortOption: 'highest-rated',
      isLoggedIn: false,
      fullName: '',
      errorMessage: '',
      hoverRating: 0 // Add this for handling hover effect
    };
  },
  computed: {
    isValidReview() {
      return this.newReview.rating > 0 && this.newReview.comment.trim().length > 0;
    },
    averageRating() {
      if (this.reviews.length === 0) return '0.0';
      const sum = this.reviews.reduce((acc, review) => acc + review.rating, 0);
      return (sum / this.reviews.length).toFixed(1);
    },
    sortedReviews() {
      switch (this.sortOption) {
        case 'highest-rated':
          return [...this.reviews].sort((a, b) => b.rating - a.rating);
        case 'lowest-rated':
          return [...this.reviews].sort((a, b) => a.rating - b.rating);
        case 'most-recent':
          return [...this.reviews].sort((a, b) => new Date(b.date) - new Date(a.date));
        default:
          return this.reviews;
      }
    }
  },
  methods: {
    async submitReview() {
      const token = localStorage.getItem('token');
      const userId = JSON.parse(localStorage.getItem('user')).id;
      const fullName = JSON.parse(localStorage.getItem('user')).fullName;
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/reviews/submit-giftcard-review`, {
          giftCardId: this.giftCardId,
          rating: this.newReview.rating,
          comment: this.newReview.comment,
          userId,
          fullName
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log("Review submitted: ", response.data);

        this.$emit('review-added', response.data);
        this.newReview.rating = 0;
        this.newReview.comment = '';
        this.errorMessage = '';
      } catch (error) {
        console.error('Error submitting review:', error);
        if (error.response && error.response.status === 400) {
          this.errorMessage = 'You have already submitted a review for this gift card';
        } else {
          this.errorMessage = 'Error submitting review. Please try again later.';
        }
      }
    },
    setRating(rating) {
      this.newReview.rating = rating;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  },
  mounted() {
    this.isLoggedIn = !!localStorage.getItem('user');
    if (this.isLoggedIn) {
      this.fullName = JSON.parse(localStorage.getItem('user')).fullName;
    }
    this.giftCardId = this.$route.params.id;
  }
};
</script>

  
<style scoped>
.customer-reviews {
  width: 100%;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.review-rating {
  display: flex;
  align-items: center;
}

.review-rating .rating {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.review-rating .stars {
  display: flex;
}

.review-rating .stars .star {
  font-size: 1.5em;
  color: #ddd;
}

.review-rating .stars .star.filled {
  color: #ffc107;
}

.review-rating .total-reviews {
  font-size: 1em;
  color: #666;
}

.review-author {
  font-size: 1em;
  color: #333;
  margin-right: 10px;    
}

.sort-dropdown {
  display: flex;
  align-items: center;
}

.sort-dropdown label {
  margin-right: 5px;
  font-size: 1em;
  color: #333;
}

.sort-dropdown select {
  font-size: 1em;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.no-reviews {
  font-size: 1em;
  color: #666;
  margin-top: 20px;
}

.reviews-list {
  margin-top: 20px;
}

.review-item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.review-item .review-header {
  display: flex;
  justify-content: space-between;
}

.review-item .review-rating {
  display: flex;
  align-items: center;
}

.text-separator{
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
}

.review-item .review-date {
  font-size: 0.875em;
  color: #999;
}

.review-item .review-comment {
  font-size: 1em;
  color: #333;
  margin-top: 10px;
  text-align: left;
}

.submit-review {
  margin-top: 20px;
}

.star-rating {
  display: flex;
  align-items: center;
}

.star {
  font-size: 2em;
  color: #ddd;
  cursor: pointer;
}

.star.hovered,
.star.filled {
  color: #ffc107;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #4C6B30;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}

.error {
  color: red;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .review-rating .rating,
  .review-rating .stars .star {
    font-size: 1.2em;
  }

  .review-header,
  .review-item .review-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .review-author,
  .review-rating .total-reviews {
    font-size: 0.9em;
  }

  .sort-dropdown label,
  .sort-dropdown select {
    font-size: 0.9em;
  }

  .review-item .review-comment {
    font-size: 0.95em;
  }

  textarea{
    width: 99%;
  }

  .submit-review {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .review-rating .rating,
  .review-rating .stars .star {
    font-size: 1em;
  }

  .review-author,
  .review-rating .total-reviews {
    font-size: 0.8em;
  }

  .review-header,
  .review-item .review-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .sort-dropdown label,
  .sort-dropdown select {
    font-size: 0.8em;
  }

  .review-item .review-comment {
    font-size: 0.9em;
  }

  .submit-review {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .review-rating .rating,
  .review-rating .stars .star {
    font-size: 25px;
  }

  .review-author,
  .review-rating .total-reviews {
    font-size: 0.75em;
  }

  .sort-dropdown label,
  .sort-dropdown select {
    font-size: 0.75em;
  }

  .review-item .review-comment {
    font-size: 0.85em;
  }

  .submit-review {
    margin-top: 10px;
  }

  .no-reviews {
    text-align: center;
  }

  textarea{
    width: 95%
  }
}
</style>
  