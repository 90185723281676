<template>
  <header class="header">
    <div class="blob-container">
      <svg class="blob blob-left" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path fill="#E8F5E9" d="M41.3,-65.7C54.9,-56.3,68,-47.3,76.5,-34.6C85,-21.9,88.9,-5.5,85.6,9.2C82.2,24,71.6,37.1,60.3,49.4C49,61.7,37,73.3,22.5,78.3C8,83.3,-9,81.8,-24.8,76.9C-40.6,72,-55.3,63.7,-65.4,51.5C-75.5,39.2,-81,23,-82.9,6.5C-84.8,-10,-83,-26.8,-75.9,-40.8C-68.8,-54.8,-56.4,-66,-42.5,-74.5C-28.5,-83,-14.3,-88.8,-0.2,-88.5C13.9,-88.2,27.7,-81.8,41.3,-65.7Z" transform="translate(100 100)" />
      </svg>
      <svg class="blob blob-right" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path fill="#E8F5E9" d="M34.2,-47.1C45.9,-40.9,58,-34.2,65.4,-23.5C72.8,-12.8,75.5,1.9,73.1,16.2C70.7,30.5,63.3,44.4,52.1,53.6C41,62.8,26.1,67.3,10.7,69.5C-4.7,71.6,-20.7,71.4,-34.3,65.7C-47.9,60,-59.1,48.8,-65.1,35.6C-71.1,22.4,-71.9,7.1,-68.8,-6.5C-65.6,-20.1,-58.5,-32,-48.8,-39.2C-39.1,-46.5,-26.8,-49,-15.7,-51.8C-4.6,-54.5,5.4,-57.5,15.8,-56.6C26.2,-55.7,37,-55,34.2,-47.1Z" transform="translate(100 100)" />
      </svg>
    </div>
    <div class="header-content">
      <div class="left-content">
        <div class="slogan">
          <h2>SHOP.</h2>
          <h2>SAVE.</h2>
          <h2>REPEAT.</h2>
        </div>
        <p class="subtext">GET GREAT DEALS TO SMALL & LOCALLY OWNED BUSINESSES.<br>START GETTING GREAT DEALS BY SEARCHING FOR A LOCAL BUSINESS</p>
        <div class="info-buttons mobile-only">
          <button class="info-button">No Membership Fees</button>
          <button class="info-button">Great Deals Daily</button>
          <button class="info-button">Support a Local Business</button>
        </div>
        <div class="location-search">
          <div class="location-dropdown">
            <div class="location-select" @click="toggleDropdown">
              <span class="location-text">{{ selectedLocation }}</span>
              <span class="arrow" :class="{ 'arrow-up': isDropdownOpen, 'arrow-down': !isDropdownOpen }"></span>
            </div>
            <ul v-if="isDropdownOpen" class="dropdown-menu">
              <li v-for="location in locations" :key="location" @click="selectLocation(location)">
                {{ location }}
              </li>
            </ul>
          </div>
          <div class="search-container">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search a Local Business..."
              @input="fetchSuggestions"
            />
            <div v-if="suggestions.length" class="suggestions-dropdown">
              <ul>
                <li
                  v-for="business in suggestions"
                  :key="business._id"
                  @click="selectBusiness(business)"
                  class="suggestion-item"
                >
                  {{ business.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <img src="@/assets/logo.png" alt="Stark Shopper Logo" class="logo-img">
        <div class="info-buttons desktop-only">
          <button class="info-button">No Membership Fees</button>
          <button class="info-button">Great Deals Daily</button>
          <button class="info-button">Support a Local Business</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HeaderComponent',
  data() {
    return {
      searchQuery: '',
      suggestions: [],
      selectedLocation: '',
      isDropdownOpen: false,
      locations: ['Stark County, Ohio', 'Summit County, Ohio'],
    };
  },
  created() {
    this.initializeLocation();
  },
  methods: {
    initializeLocation() {
      const hostname = window.location.hostname;
      if (hostname.includes('starkshopper.com')) {
        this.selectedLocation = 'Stark County, Ohio';
      } else if (hostname.includes('summitshopper.com')) {
        this.selectedLocation = 'Summit County, Ohio';
      } else {
        this.selectedLocation = localStorage.getItem('selectedLocation') || 'Stark County, Ohio';
      }
      localStorage.setItem('selectedLocation', this.selectedLocation);
      this.$emit('location-changed', this.selectedLocation);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectLocation(location) {
      this.selectedLocation = location;
      this.isDropdownOpen = false;
      localStorage.setItem('selectedLocation', location);
      this.$emit('location-changed', location);
      window.location.reload();
    },
    async fetchSuggestions() {
      if (this.searchQuery.trim().length < 2) {
        this.suggestions = [];
        return;
      }

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/search`, {
          params: { query: this.searchQuery },
        });
        this.suggestions = response.data.businesses;
      } catch (error) {
        console.error('Error fetching business suggestions:', error);
        this.suggestions = [];
      }
    },
    selectBusiness(business) {
      this.$emit('search-results', { businessId: business._id });
      this.suggestions = [];
      this.$router.push(`/business/${business._id}`);
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #ffffff;
  padding: 2rem;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: visible; /* Allow content to overflow */
  min-height: 400px;
}

.blob-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Contain the blobs */
  z-index: 0;
}

.blob {
  position: absolute;
  width: 400px;
  height: 400px;
  opacity: 0.7;
}

.blob-left {
  top: -200px;
  left: -200px;
  transform: rotate(45deg);
}

.blob-right {
  bottom: -200px;
  right: -200px;
  transform: rotate(-45deg);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 1;
}

.left-content {
  max-width: 60%;
}

.slogan h2 {
  color: #4CAF50;
  font-size: 2.5rem;
  margin: 0;
  line-height: 1.2;
}

.subtext {
  font-size: 0.9rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.location-search {
  margin-bottom: 1rem;
  position: relative;
}

.location-dropdown {
  position: relative;
  margin-bottom: 0.5rem;
}

.location-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
}

.location-text {
  font-size: 1.5rem;
  color: #4CAF50;
  font-weight: bold;
}

.location-select:hover .location-text {
  color: #45a049;
}

.arrow {
  border: solid #4CAF50;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.arrow-down {
  transform: rotate(45deg);
}

.arrow-up {
  transform: rotate(-135deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-menu li {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
  background-color: #E8F5E9;
}

.search-container {
  position: relative;
}

.search-container input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.search-container input:focus {
  outline: none;
  border-color: #4CAF50;
}

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 11;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.suggestions-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestion-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestion-item:hover {
  background-color: #E8F5E9;
}

.right-content {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -2.5rem;
}

.logo-img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.info-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.info-button {
  background-color: #E8F5E9;
  color: #4CAF50;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}

.info-button:hover {
  background-color: #C8E6C9;
}

.desktop-only {
  display: flex;
}

.mobile-only {
  display: none;
}

@media (max-width: 1024px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .left-content {
    max-width: 100%;
    order: 2;
  }

  .right-content {
    order: 1;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .info-buttons {
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  .header {
    overflow: visible;
  }

  .blob-container {
    display: none;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 20px;
  }

  .info-button {
    width: 100%;
  }

  .logo-img {
    display: none; /* Hide logo on mobile */
  }

  .slogan h2 {
    font-size: 2rem;
  }

  .location-text {
    font-size: 1rem;
  }
}
</style>
