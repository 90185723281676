import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to include the token for specific endpoints
apiClient.interceptors.request.use(config => {
  if (!config.url.includes('/data')) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default {
  getData() {
    return apiClient.get('/data');
  },
  getSalesAmount(id) {
    return apiClient.get(`/business-sales/salesAmount/${id}`);
  },
  getOrders(id) {
    return apiClient.get(`/business-sales/orders/${id}`);
  },
  submitCoupon(data) {
    return apiClient.post('/create-coupon', data);
  }
};
