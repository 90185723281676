<template>
  <div class="step-preview">
    <div class="content-container">
      <!-- Left Side -->
      <div class="left-side">
        <!-- Image Viewer -->
        <div class="image-viewer">
          <button class="main-arrow left" v-if="images.length > 0" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
          <div class="main-image">
            <img v-if="images.length > 0" :src="getImageSource(images[selectedImageIndex])" alt="Main Image" />
            <img v-else src="./default-image.png" alt="Default Image"/>
          </div>
          <button class="main-arrow right" v-if="images.length > 0" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
        </div>

        <!-- Thumbnails -->
        <div class="thumbnail-slider-container" v-if="images.length > 0">
          <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
            <span>&#9664;</span>
          </button>
          <div class="thumbnail-slider">
            <div class="thumbnail-container">
              <div class="image-preview" v-for="(image, index) in visibleThumbnails" :key="index" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
                <img :src="getImageSource(image)" :alt="'Image ' + (index + 1)" />
              </div>
            </div>
          </div>
          <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
            <span>&#9654;</span>
          </button>
        </div>

        <!-- Mobile Options -->
        <div class="options-list mobile-only" v-if="options.length > 0">
          <ul>
            <li v-for="(option, index) in options" :key="index" class="option-item">
              <div class="option-content">
                <input type="radio" :id="'option-mobile' + index" name="option-mobile" v-model="selectedOptionId" :value="option._id" />
                <label :for="'option-mobile' + index" class="option-label">
                  <div class="option-header">
                    <strong>{{ option.dealName }}</strong>
                  </div>
                  <div v-if="option.discountType === 'percentageAmount'" class="option-details">
                    <div class="option-detail"><strong>{{ option.discount }}% off</strong> if you spend <strong>${{ option.spendAmount }}</strong></div>
                  </div>
                  <div v-else-if="option.discountType === 'amountOff'" class="option-details">
                    <div class="option-detail">Discount: <strong>${{ option.discount }}</strong> Off</div>
                  </div>
                  <div v-else-if="option.discountType === 'buyOneGetOnePercentage'" class="option-details">
                    <div class="option-detail"><strong>{{ option.discount }}% Off</strong></div>
                  </div>
                  <div v-else-if="option.discountType === 'buyOneGetOneAmount'" class="option-details">
                    <div class="option-detail"><strong>${{ option.discount }} Off</strong></div>
                  </div>
                  <div v-else-if="option.discountType === 'buyOneGetOneFree'" class="option-details">
                    <div class="option-detail"><strong>Buy 1 Get 1</strong></div>
                  </div>
                  <div class="coupon-code">Coupon Code: <span>{{ option.couponCode }}</span></div>
                </label>
              </div>
              <hr class="separator" />
            </li>
          </ul>
        </div>

        <!-- Button Container (Mobile Only) -->
        <div class="button-container mobile-only">
          <button class="shopping-bag">Add to Coupon Bag</button>
          <button class="print">Print</button>
          <button class="subscribe-button">Become a Fan</button>
        </div>

        <!-- About Deal -->
        <div class="about-deal" v-if="couponDetails.dealdetails">
          <h2>About this Coupon</h2>
          <div v-html="couponDetails.dealdetails"></div>
        </div>

        <!-- Fine Print -->
        <div class="fine-print" v-if="couponDetails.dealfineprint">
          <h2>Fine Print</h2>
          <div v-html="couponDetails.dealfineprint"></div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="right-side">
        <!-- Options (Desktop Only) -->
        <div class="options-list desktop-only" v-if="options.length > 0">
          <ul>
            <li v-for="(option, index) in options" :key="index" class="option-item">
              <div class="option-content">
                <input type="radio" :id="'option-desktop' + index" name="option-desktop" v-model="selectedOptionId" :value="option._id" />
                <label :for="'option-desktop' + index" class="option-label">
                  <div class="option-header">
                    <strong>{{ option.dealName }}</strong>
                  </div>
                  <div v-if="option.discountType === 'percentageAmount'" class="option-details">
                    <div class="option-detail"><strong>{{ option.discount }}% off</strong> if you spend <strong>${{ option.spendAmount }}</strong></div>
                  </div>
                  <div v-else-if="option.discountType === 'amountOff'" class="option-details">
                    <div class="option-detail">Discount: <strong>${{ option.discount }}</strong> Off</div>
                  </div>
                  <div v-else-if="option.discountType === 'buyOneGetOnePercentage'" class="option-details">
                    <div class="option-detail"><strong>{{ option.discount }}% Off</strong></div>
                  </div>
                  <div v-else-if="option.discountType === 'buyOneGetOneAmount'" class="option-details">
                    <div class="option-detail"><strong>${{ option.discount }} Off</strong></div>
                  </div>
                  <div v-else-if="option.discountType === 'buyOneGetOneFree'" class="option-details">
                    <div class="option-detail"><strong>Buy 1 Get 1</strong></div>
                  </div>
                  <div class="coupon-code">Coupon Code: <span>{{ option.couponCode }}</span></div>
                </label>
              </div>
              <hr class="separator" />
            </li>
          </ul>
        </div>
        <!-- Button Container (Desktop Only) -->
        <div class="button-container desktop-only">
          <button class="shopping-bag">Add to Coupon Bag</button>
          <button class="print">Print</button>
          <button class="subscribe-button">Become a Fan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepPreview',
  data() {
    return {
      couponDetails: {
        dealTitle: '',
        category: '',
        dealdetails: '',
        dealfineprint: '',
      },
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      thumbnailsPerPage: 6,
      options: [],
      selectedOptionId: null,
      showConfirmation: false,
    };
  },
  computed: {
    visibleThumbnails() {
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
  },
  methods: {
    getImageSource(image) {
      if (typeof image === 'string') {
        return image; // It's a URL
      } else if (image && image.url) {
        return image.url; // It's an object with a URL property
      } else if (image && image.base64) {
        return image.base64; // It's an object with a base64 property
      }
      return ''; // Return empty string or a placeholder image URL
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    calculateCustomerPays(option) {
      return (option.regularPrice * (1 - option.discount / 100)).toFixed(2);
    },
    loadLocalStorageData() {
      const savedDetails = JSON.parse(localStorage.getItem('dealDetails'));
      if (savedDetails) {
        this.couponDetails = savedDetails;
      }

      const savedImages = JSON.parse(localStorage.getItem('uploadedImages'));
      if (savedImages) {
        this.images = savedImages.map((image, index) => {
          if (typeof image === 'string') {
            return { url: image, name: `Image ${index + 1}` };
          } else if (image.url) {
            return { url: image.url, name: image.name || `Image ${index + 1}` };
          } else if (image.base64) {
            return { base64: image.base64, name: image.name || `Image ${index + 1}` };
          }
          return { url: '', name: `Image ${index + 1}` }; // Fallback
        });
        this.selectedImageIndex = 0;
      }

      const savedOptions = JSON.parse(localStorage.getItem('options'));
      if (savedOptions) {
        this.options = savedOptions.map(option => ({
          ...option,
          boughtCount: Math.floor(Math.random() * 10000 + 1) // Random bought count for demo purposes
        }));
      }
    },
    addToShoppingBag() {
      // Add to shopping bag logic here
    },
    printCoupon() {
      // Print coupon logic here
    },
    addToEWallet() {
      // Add to e-wallet logic here
    }
  },
  mounted() {
    this.loadLocalStorageData();
  },
};
</script>

<style scoped>
.step-preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.left-side,
.right-side {
  width: 48%;
}

.coupon-header h3 {
  margin: 0;
  margin-bottom: 20px;
}

.image-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.title-header {
  font-size: 14px;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 30px);
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.image-preview {
  width: calc((100% - 50px) / 6);
  height: 0;
  padding-bottom: calc((100% - 50px) / 6);
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.image-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-arrow {
  width: 10px;
  height: calc((100% - 50px) / 6);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 5px;
}

.fine-print {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}

.about-deal {
  width: 100%;
  margin-top: 50px;
}

.options-list {
  width: 100%;
  margin-bottom: 20px;
}

.option-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.option-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.option-label {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.option-header {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.option-details {
  margin-bottom: 8px;
}

.option-detail {
  font-size: 16px;
  color: #333;
}

.coupon-code {
  font-size: 14px;
  color: #555;
  margin-top: 8px;
}

.coupon-code span {
  background: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
}

.separator {
  width: 50%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.button-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.shopping-bag,
.print,
.subscribe-button{
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
}

.shopping-bag {
  background-color: #4C6B30;
  color: white;
}

.print {
  background-color: #6E9F49;
  color: white;
}

.subscribe-button {
  background-color: #8DB36D;
  color: white;
}

.mobile-only {
  display: none;
}

@media (max-width: 1024px) {
  .left-side,
  .right-side {
    width: 100%;
  }

  .image-viewer {
    height: 350px;
  }

  .image-preview {
    width: calc((100% - 40px) / 5);
    padding-bottom: calc((100% - 40px) / 5);
  }

  .thumbnail-arrow {
    height: calc((100% - 40px) / 5);
  }
}

@media (max-width: 768px) {
  .image-viewer {
    height: 300px;
  }

  .image-preview {
    width: calc((100% - 30px) / 4);
    padding-bottom: calc((100% - 30px) / 4);
  }

  .thumbnail-arrow {
    height: calc((100% - 30px) / 4);
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .right-side {
    order: -1;
  }

  .button-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .shopping-bag,
  .print,
  .subscribe-button {
    width: 32%;
  }
}

@media (max-width: 480px) {
  .image-viewer {
    height: 250px;
  }

  .image-preview {
    width: calc((100% - 20px) / 3);
    padding-bottom: calc((100% - 20px) / 3);
  }

  .thumbnail-arrow {
    height: calc((100% - 20px) / 3);
  }

  .button-container {
    flex-direction: column;
  }

  .shopping-bag,
  .print,
  .subscribe-button {
    width: 100%;
  }
}
</style>